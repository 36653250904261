<template>
    <div :class="isCommonSection ? 'settings-section' : undefined">
        <div>
            <transition name="alert-fade" mode="out-in">
                <HAlert
                    v-if="alert.status"
                    class="settings-alert"
                    :message="alert.message"
                    :status="alert.status"
                    @dismiss="closeAlert"
                />
            </transition>
        </div>
        <div v-if="isCommonSection" class="settings-section__return settings-mobile" @click="handleReturn">
            Return to Settings
        </div>
        <div v-if="isCommonSection" class="settings-section__header">
            <h2>Mobile Number</h2>
            <p>Update your mobile number</p>
        </div>
        <div class="settings-email">
            <NotificationToggle v-if="withNotificationToggle" label="SMS Notifications" notification-key="sms" />
            <div class="settings-number">
                <div class="settings-number__top">
                    <div class="settings-number__label">
                        Mobile Number
                    </div>
                    <div class="settings-badge" @click="handleSidebar">
                        <h-status-badge
                            :label="badgeStatusLabel"
                            :status="badgeStatusClass"
                            :variant="badgeStatusVariant"
                            :content="badgeStatusContent"
                        />
                    </div>
                    <transition name="sidebar" mode="out-in">
                        <h-sidebar
                            v-if="openSidebar"
                            :open="openSidebar"
                            badge_label="Mobile Verification"
                            @handleClose="closeSidebar"
                        >
                            <MobileVerification @submit-success="handleVerifySuccessful" />
                        </h-sidebar>
                    </transition>
                </div>
                <div class="settings-verify__input-wrapper">
                    <input
                        ref="phoneNumber"
                        v-model="userData.mobileNumber"
                        v-mask="'(###) ###-####'"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        class="settings-verify__input"
                        :disabled="!newNumber && !!user.mobile_number"
                        @input="$v.userData.mobileNumber.$touch"
                    />
                    <div class="settings-verify__inputBtnWrapper">
                        <button v-if="newNumber" @click="cancelNewNumber">
                            Cancel
                        </button>
                        <button
                            v-if="!newNumber && !!user.mobile_number"
                            @click="enterNewNumber"
                        >
                            New Number
                        </button>
                    </div>
                </div>
                <div class="settings-number__description">
                    Mobile number will be used to receive important account and treatment updates. Please read our
                    <div class="link" role="button" tabIndex="0" @click="toggleSmsPolicy">
                        SMS Policy
                    </div>
                    for more information.
                </div>
            </div>
            <h-button
                variant="secondary"
                :loading="submitting"
                class="settings-section__btn"
                :disabled="isSubmitDisabled"
                @click="updateProfileInfo"
            >
                Submit
            </h-button>
        </div>
        <transition name="sidebar" mode="out-in">
            <SmsPolicySidePanel v-if="smsPolicyVisible" :open="smsPolicyVisible" @handleClose="toggleSmsPolicy" />
        </transition>
    </div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';
import { mapActions, mapState } from 'vuex';

import MobileVerification from '@/components/Confirmation/LabSuccess/SubComponents/Results/components/MobileVerification.vue';
import HAlert from '@/components/Shared/HAlert.vue';
import HButton from '@/components/Shared/HButtonV2.vue';
import HSidebar from '@/components/Shared/HSidebar';
import HStatusBadge from '@/components/Shared/HStatusBadge';
import SmsPolicySidePanel from '@/components/Shared/SmsPolicySidePanel';
import Alert from '@/helpers/alert';
import { phoneValidation } from '@/helpers/validations';

export default {
    name: 'MobileNumber',
    props: {
        isCommonSection: {
            type: Boolean,
            default: true,
        },
        withNotificationToggle: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        SmsPolicySidePanel,
        HAlert,
        HStatusBadge,
        HSidebar,
        MobileVerification,
        HButton,
        NotificationToggle: () => import('./Subcomponents/NotificationToggle'),
    },
    data() {
        return {
            alert: new Alert(),
            verified: false,
            openSidebar: false,
            userData: {
                mobileNumber: '',
            },
            newNumber: false,
            submitting: false,
            smsPolicyVisible: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
        }),
        badgeStatusLabel() {
            return this.verified ? 'Number Verified' : 'Verify Your Number';
        },
        badgeStatusClass() {
            return this.verified ? 'success' : 'warning';
        },
        badgeStatusVariant() {
            return this.verified ? 'info' : 'action';
        },
        badgeStatusContent() {
            return this.verified
                ? "Your number is verified and you'll receive real-time updates from the support and clinical team."
                : 'Verify your number to receive real-time updates from the support and clinical team.';
        },
        isSubmitDisabled() {
            const hasMobileNumber = !!this.user.mobile_number;
            const isNewNumber = this.newNumber;
            const isMobileNumberInvalid = this.$v.userData.mobileNumber.$invalid;

            return (
                (hasMobileNumber && !isNewNumber) || (!hasMobileNumber && isNewNumber) || isMobileNumberInvalid
            );
        },
    },
    validations: {
        userData: {
            mobileNumber: phoneValidation,
        },
    },
    watch: {
        'user.mobile_verified'(newVal) {
            this.verified = newVal;
        },
    },
    created() {
        if (this.user.mobile_number) {
            this.formatNumber(this.user.mobile_number);
        }

        this.verified = this.user.mobile_verified;
    },
    methods: {
        ...mapActions({
            updateUser: 'user/updateUser',
        }),
        closeAlert() {
            this.alert.dismiss();
        },
        handleReturn() {
            this.$router.push(`/dashboard/${this.user._id}/settings`);
        },
        handleSidebar() {
            if (!this.verified) {
                this.openSidebar = !this.openSidebar;
            }
        },
        formatNumber(number) {
            const formattedNumber = parsePhoneNumber(number, 'US');

            this.userData.mobileNumber = formattedNumber.nationalNumber;
        },
        closeSidebar() {
            this.openSidebar = false;
            this.alert.showWithTimeout(
                'Verify your number to receive real time updates',
                'warning',
            );
        },
        handleVerifySuccessful() {
            this.openSidebar = false;
            this.alert.showWithTimeout(
                'Successfully verified your mobile number',
                'success',
            );
        },
        enterNewNumber() {
            this.newNumber = true;
            this.userData.mobileNumber = '';
            this.$nextTick(() => {
                this.$refs.phoneNumber.focus();
            });
        },
        cancelNewNumber() {
            this.newNumber = false;
            this.formatNumber(this.user.mobile_number);
        },
        async updateProfileInfo() {
            const formattedNumber = parsePhoneNumber(
                this.userData.mobileNumber,
                'US',
            );

            if (this.user.mobile_number !== formattedNumber.number
                || (this.user.mobile_number === formattedNumber.number && !this.user.mobile_verified)
            ) {
                this.submitting = true;

                try {
                    await this.updateUser({
                        _id: this.user._id,
                        mobile_number: formattedNumber.number,
                    });
                    this.openSidebar = true;
                    this.newNumber = false;
                } catch (error) {
                    this.alert.show(error.message, 'critical');
                } finally {
                    this.submitting = false;
                }
            }
        },
        toggleSmsPolicy() {
            this.smsPolicyVisible = !this.smsPolicyVisible;
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.link {
    @include caption;
    display: inline-block;
    color: $powder-blue-200;
    cursor: pointer;
}
</style>
