<template>
    <div>
        <div class="hmodal" :style="{'top': topPosition}">
            <slot />     
        </div>
        <div class="hmodal-backdrop" :class="{'hmodal-backdrop-dark': dark}" />
    </div>
</template>

<script>
export default {
    name: 'HModal',
    props: {
        open: {
            type: Boolean,
        },
        handleClose: {
            type: Function,
            default: () => {},
        },
        dark: {
            type: Boolean,
            default: false,
        },
        topPosition: {
            type: String,
            default: '50%',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/utility.scss';

.hmodal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 80vh;
    background-color: transparent;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    padding: 0 1.5rem 2.5rem;
    z-index: $z-modal;
    overflow-y: scroll;

    @media (min-width: 1280px) {
        bottom: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70vw;
        max-height: 70vh;
        border-radius: 1rem;
        padding: 2.5rem;
    }

}

.hmodal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    z-index: $z-overlay;
}

.hmodal-backdrop-dark {
    background: rgba(0, 0, 0, 0.85);
}

</style>
