import axios from 'axios'
import Vue from "vue";
import { analytics, analyticsActions } from '../../helpers/analytics';
import * as Sentry from "@sentry/vue";
import { set } from 'vue-gtag';

const baseUrl = process.env.VUE_APP_BASE_END;

export const user = {
    namespaced: true,
    state: {
        user: {},
        duplicate_email: false,
        alert_item_modal: false,
        verify_number: true,
        submit_ssn: {
            loading: false,
            error: false
        },
        patient_state: {},
        subscriber: {},
        subscriber_tags: {},
        updatedProfileImage: false,
    },
    getters: {
        alerts(state) {
            let user = state.user || {};
            let return_object = {};
            return_object['alert'] = false;
            return_object['list'] = [];
            if (user.image.requires_user_attention) {

                //set the alert boolean flag to true
                return_object['alert'] = true;

                //create an image alert object
                let image_alert = {};

                image_alert['reason'] = 'ID/Profile Image Mismatch';
                image_alert['requires_user_attention_notification_date'] = user.image.requires_user_attention_notification_date

                return_object['list'].push(image_alert);
            }
            return return_object;
        },
        isCompletedUserProfile({ user }) {
            if (!user) return false;

            return !['first_name', 'last_name', 'email', 'birthday', 'height', 'weight', 'sex'].some(key => !user[key]);
        }
    },
    mutations: {
        initializeUserStore(state) {
            if (localStorage.getItem('hs_user')) {
                state.user = JSON.parse(localStorage.getItem('hs_user')) || {};

                analytics.identify(state.user._id, {
                    birthday: state.user.birthday,
                    weight: state.user.weight,
                    height: state.user.height,
                    email: state.user.email,
                    sex: state.user.sex,
                    organization: state.user.organization,
                    complete_intake: state.user.complete_intake,
                    first_name: state.user.first_name,
                    last_name: state.user.last_name,
                    mobile_verified: state.user.mobile_verified,
                    requires_intake: state.user.requires_intake,
                    verified: state.user.verified,
                });

                set('user_data', {
                    email: state.user.email,
                });

                Sentry.configureScope((scope) => {
                    scope.setUser({
                        id: state.user._id,
                        email: state.user.email,
                        username: `${state.user.first_name || ''} ${
                            state.user.last_name || ''
                        }`.trim(),
                        verified: state.user.verified,
                        mobile_verified: state.user.mobile_verified,
                        stripe_id: state.user.stripe_id,
                        organization: state.user.organization,
                        created_at: state.user.created_at,
                        updated_at: state.user.updated_at,
                        has_card_source: state.user.has_card_source,
                    });
                });
            }

            if (localStorage.getItem('hs_token')) {
                state.token = localStorage.getItem('hs_token');
            }
        },
        deleteIncompleteIntake(state) {
            let incomplete_intakes = state.user.incomplete_intakes || [];
            if (incomplete_intakes.length > 0) {
                delete state.user.incomplete_intakes[0];
            }
        },
        //set hs_user and set the user state
        setUser(state, new_user) {
            localStorage.setItem('hs_user', JSON.stringify(new_user));

            state.user = new_user;

            analytics.identify(state.user._id, {
                birthday: state.user.birthday,
                weight: state.user.weight,
                height: state.user.height,
                email: state.user.email,
                sex: state.user.sex,
                organization: state.user.organization,
                complete_intake: state.user.complete_intake,
                first_name: state.user.first_name,
                last_name: state.user.last_name,
                mobile_verified: state.user.mobile_verified,
                requires_intake: state.user.requires_intake,
                verified: state.user.verified,
            });

            set('user_data', {
                email: state.user.email,
            });

            Sentry.configureScope((scope) => {
                scope.setUser({
                    id: state.user._id,
                    email: state.user.email,
                    username: `${state.user.first_name || ''} ${
                        state.user.last_name || ''
                    }`.trim(),
                    verified: state.user.verified,
                    mobile_verified: state.user.mobile_verified,
                    stripe_id: state.user.stripe_id,
                    organization: state.user.organization,
                    created_at: state.user.created_at,
                    updated_at: state.user.updated_at,
                    has_card_source: state.user.has_card_source,
                });
            });
        },
        setVerifyNumber(state, payload) {
            state.verify_number = payload
        },
        setSsnLoading(state, loading) {
            state.submit_ssn.loading = loading;
        },
        setSsnError(state, error) {
            state.submit_ssn.error = error;
        },
        setState(state, payload) {
            Vue.set(state.patient_state, payload.id, payload.value);
        },
        setSubscriber(state, payload) {
            Vue.set(state.subscriber, payload.id, payload.value);
        },
        setSubscriberTags(state, payload) {
            Vue.set(state.subscriber_tags, payload.id, payload.value);
        },
        updateUserImage(state, payload) {
            state.updatedProfileImage = payload;
        },
    },
    actions: {

        //retrieve user
        retrieveUser({commit, getters, state, rootState}, payload) {

            let orgId = rootState.orgId.data;

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/users/' + orgId + '/user/' + payload.user + '/patient',
                    method: 'GET'
                }).then((response) => {
                    commit('setUser', response.data);
                    commit('setVerifyNumber', response.data.mobile_verified);
                    resolve(response.data);

                    analytics.identify(response.data._id, {
                        birthday: response.data.birthday,
                        weight: response.data.weight,
                        height: response.data.height,
                        email: response.data.email,
                        sex: response.data.sex,
                        organization: response.data.organization,
                        complete_intake: response.data.complete_intake,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        mobile_verified: response.data.mobile_verified,
                        requires_intake: response.data.requires_intake,
                        verified: response.data.verified,
                    });

                    set('user_data', {
                        email: state.user.email,
                    });
                }).catch((err) => {
                    console.log('failed to retrieve user: ', err);
                    reject(err);
                })
            })

        },

        async retrieveUserImage({commit, getters, state, rootState}, user) {
            let orgId = rootState.orgId.data;
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/users/' + orgId + '/user/' + user + '/patient/images',
                    method: 'GET'
                }).then((response) => {
                    resolve(response.data);
                }).catch((err) => {
                    console.log('failed to retrieve user: ', err);
                    reject(err);
                })
            })
        },

        //a call to update the user table
        updateUser({commit, getters, state, rootState}, user) {
            let id = user._id;
            let orgId = rootState.orgId.data;

            return new Promise((resolve, reject) => {
                axios({url: baseUrl + '/users/' + orgId + '/' + id, data: user, method: 'PUT'}).then(response => {

                    commit('setUser', response.data);

                    analytics.track(analyticsActions.user_updated);

                    analytics.identify(response.data._id, {
                        birthday: response.data.birthday,
                        weight: response.data.weight,
                        height: response.data.height,
                        email: response.data.email,
                        sex: response.data.sex,
                        organization: response.data.organization,
                        complete_intake: response.data.complete_intake,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        mobile_verified: response.data.mobile_verified,
                        requires_intake: response.data.requires_intake,
                        verified: response.data.verified,
                    });

                    //resolve the response object
                    resolve(response);
                }).catch(err => {

                    console.log(err);

                    if (err.response) {
                        if (err.response.status === 401) {
                            commit('authenticate/badCredentials');
                        }

                        if (err.response.status === 400) {
                            state.duplicate_email = true;
                        }

                        reject(err.response.data);
                    } else {
                        reject(err);
                    }
                })
            })
        },
        resetPassword({commit, getters, state, rootState}, payload) {
            //grab the user id
            let id = state.user._id;
            let orgId = rootState.orgId.data;

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/users/' + orgId + '/' + id + '/reset-password',
                    data: payload,
                    method: 'PUT'
                }).then(response => {

                    //resolve the promise
                    resolve(response);

                }).catch(err => {

                    //resolve the err
                    reject(err);
                })
            })
        },


        //a call to update the user table --> in the signup funnel
        updateShipping({commit, getters, state, rootState}, user) {
            let id = user._id;
            let orgId = rootState.orgId.data;

            return new Promise((resolve, reject) => {
                axios({url: baseUrl + '/users/' + orgId + '/' + id, data: user, method: 'PUT'}).then(response => {


                    //resolve the response object
                    resolve(response);

                }).catch(err => {
                    reject(err);
                })
            })
        },

        //update the shipping from inside the dashboard. user already has
        updateShippingDashboard({commit, getters, state, rootState}, payload) {
            let id = payload.user_id;
            let orgId = rootState.orgId.data;

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/users/' + orgId + '/' + id + '/update-shipping',
                    data: payload,
                    method: 'PUT'
                }).then(response => {

                    commit('setUser', response.data);

                    resolve(response);
                }).catch(err => {
                    if (err.response.status === 401) {
                        commit('authenticate/badCredentials');
                    }
                    reject(err);
                })
            })

        },

        //cancel subscription
        cancelSubscription({commit, getters, state, rootState}, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/users/' + payload.org_id + '/' + payload.user_id + '/cancel-plan/' + payload.plan_id,
                    data: payload,
                    method: 'PUT'
                }).then(response => {
                    resolve(response.data);
                }).catch((err) => {
                    reject(err);
                })
            })

        },
        retrieveSubscriptions({commit, getters, state, rootState}, payload) {
            let id = payload.user_id;
            let orgId = rootState.orgId.data;

            return new Promise((resolve, reject) => {

                axios({url: baseUrl + '/users/' + orgId + '/' + id + '/plan', method: 'GET'}).then(response => {
                    resolve(response);
                }).catch(err => {
                    console.error(err);
                    reject(err);
                })
            })
        },
        createBillingProfile({commit, getters, state}, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/users/' + payload.organization + '/' + payload.user_id + '/billing/profile/create',
                    data: payload,
                    method: 'POST'
                }).then(response => {
                    resolve(response.data);
                }).catch(err => {
                    console.error('failed to create a stripe profile: ', err);
                    reject(err);
                })
            })

        },
        sendVerificationCode({commit, getters, state, rootState}, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/users/' + rootState.orgId.data + '/user/' + state.user._id + '/send-mobile-verification',
                    method: 'POST',
                    data: payload
                }).then(response => {
                    resolve(response.data)
                }).catch(err => {
                    reject(err);
                })
            })
        },
        verifyMobile({commit, getters, state, rootState}, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/users/' + rootState.orgId.data + '/user/' + state.user._id + '/mobile-verify',
                    method: 'POST',
                    data: payload,
                }).then(response => {
                    commit('setVerifyNumber', true);
                    resolve(response.data);
                }).catch(err => {
                    reject(err);
                });
            })
        },

        async saveSSN({commit, getters, state, rootState}, payload) {
            const {patient, data} = payload;
            commit('setSsnLoading', true);
            try {
                let user = await axios({
                    url: `${baseUrl}/users/${rootState.orgId.data}/user/${patient}/security`,
                    method: 'POST',
                    data: data
                });
                if (user) {
                    commit('setSsnLoading', false);
                    return user.data.data
                }
            } catch (err) {
                commit('setSsnLoading', false);
                commit('setSsnError', true);
                throw new Error(err);
            }
        },

        async getPatientState({commit, rootState}, payload) {
            const {patient} = payload;
            commit('setState', {id: 'loading', value: true});
            commit('setState', {id: 'error', value: false});

            try {
                const response = await axios.get(`${baseUrl}/users/${rootState.orgId.data}/user/${patient}/state`);
                if ('data' in response) {
                    commit('setState', {id: 'loading', value: false});
                    commit('setState', {id: 'data', value: response.data});
                    return response.data;
                }
            } catch (error) {
                commit('setState', {id: 'loading', value: false});
                commit('setState', {id: 'error', value: true});
                commit('setState', {id: 'data', value: null});

                if (axios.isAxiosError(error)) {
                    commit('setState', { id: 'error', value: error.response.data });
                    throw error.response.data;
                } else throw error;
            }
        },
        async addSubscriber({commit, rootState}, payload) {
            const { patient } = payload;
            commit('setSubscriber', { id: 'error', value: false });
            commit('setSubscriber', { id: 'loading', value: true });

            try {
                const response = await axios.post(`${baseUrl}/users/${rootState.orgId.data}/patient/${patient}/subscriber`);
                if ('data' in response) {
                    commit('setSubscriber', { id: 'loading', value: false });
                    commit('setSubscriber', { id: 'data', value: response.data });
                    return response.data;
                }
            } catch (error) {
                commit('setSubscriber', { id: 'loading', value: false });
                commit('setSubscriber', { id: 'error', value: true });
                commit('setSubscriber', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setSubscriber', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }

        },

        async addSubscriberTags({commit, rootState}, payload) {
            const { patient, tag } = payload;
            commit('setSubscriberTags', { id: 'error', value: false });
            commit('setSubscriberTags', { id: 'loading', value: true });

            try {
                const response = await axios.post(`${baseUrl}/users/${rootState.orgId.data}/patient/${patient}/subscriber/tag`, tag);
                if ('data' in response) {
                    commit('setSubscriberTags', { id: 'loading', value: false });
                    commit('setSubscriberTags', { id: 'data', value: response.data });
                    return response.data;
                }
            } catch (error) {
                commit('setSubscriberTags', { id: 'loading', value: false });
                commit('setSubscriberTags', { id: 'error', value: true });
                commit('setSubscriberTags', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setSubscriberTags', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },
        async updateUserInfo ({ commit }, user) {
            const id = user._id;
            const response = await axios.patch(`${baseUrl}/v2/users/${id}`, user);

            commit('setUser', response.data);

            return response;
        },
    },
};
