<template>
    <div class="shipping" :class="{ 'scroll-disabled': scrollDisabled }">
        <h-text-input-secondary
            id="first_name"
            v-model="shipping_info.first_name"
            name="first_name"
            label="First Name"
            :vuelidate="$v.shipping_info.first_name"
            :errors="[$v.shipping_info.first_name.$invalid && $v.shipping_info.first_name.$dirty && 'Enter your first name.']"
        />
        <h-text-input-secondary
            id="last_name"
            v-model="shipping_info.last_name"
            name="last_name"
            label="Last Name"
            :vuelidate="$v.shipping_info.last_name"
            :errors="[$v.shipping_info.last_name.$invalid && $v.shipping_info.last_name.$dirty && 'Enter your last name.']"
        />

        <h-text-input-secondary
            id="autocomplete"
            ref="autocompleteInput"
            v-model="shipping_info.street_address"
            name="street_address"
            label="Street Address"
            :vuelidate="$v.shipping_info.street_address"
            @keydown.enter="handlePlaceSelection();"
            @focus="onInputFocus"
            @blur="onInputBlur"
            :errors="[
                !$v.shipping_info.street_address.required && $v.shipping_info.street_address.$dirty && 'Please enter your street address.',
                !$v.shipping_info.street_address.maxLength && $v.shipping_info.street_address.$dirty && 'Street address must be less than 35 characters long.',
            ]"
        />

        <h-text-input-secondary
            id="apt_suite"
            v-model="shipping_info.apt_suite"
            name="apt_suite"
            label="Apt, Suite"
            :vuelidate="$v.shipping_info.apt_suite"
            :errors="[!$v.shipping_info.apt_suite.maxLength && $v.shipping_info.apt_suite.$dirty && 'Apt/Suite must be less than 35 characters long.']"
        />
        <h-text-input-secondary
            id="city"
            v-model="shipping_info.city"
            name="city"
            label="City"
            :vuelidate="$v.shipping_info.city"
            :errors="[
                !$v.shipping_info.city.required && $v.shipping_info.city.$dirty && 'Please enter your city.',
                !$v.shipping_info.city.maxLength && $v.shipping_info.city.$dirty && 'City must be less than 35 characters long.',
            ]"
        />
        <div class="input-style-block">
            <HDropDown
                v-model="shipping_info.state"
                label="State"
                :options="new_states"
                placeholder="Select State"
                :selected="shipping_info.state"
                :vuelidate="$v.shipping_info.state"
                :errors="[!$v.shipping_info.state.required && $v.shipping_info.state.$dirty && 'Please enter your state.']"
            />
        </div>
        <h-text-input-secondary
            id="zip_code"
            v-model="shipping_info.zip_code"
            name="zip_code"
            label="Zip Code"
            :vuelidate="$v.shipping_info.zip_code"
            :errors="[$v.shipping_info.zip_code.$invalid && $v.shipping_info.zip_code.$dirty && 'Please enter a valid zip code.']"
        />
        <div class="shipping__button">
            <button :disabled="$v.$invalid" class="dashboard-button black-bg" data-test="save-shipping" type="submit" @click="emitShippingData">
                {{ button_label }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
import { loadGoogleMaps, initMap } from '@/helpers/googlemaps';
import HTextInputSecondary from '@/components/Shared/HTextInputSecondary';
import HDropDown from '@/components/Shared/HDropdown';
import HButton from '@/components/Shared/HButton';

const isZipcode = helpers.regex('isZipcode', /^\d{5}(-\d{4})?$/);

export default {
    name: 'ProfileAutoComplete',
    props: {
        shipping: {
            type: Object,
            default: () => ({}),
        },
        button_label: {
            type: String,
            default: 'Save Changes',
        },
    },
    components: {
        HTextInputSecondary,
        HDropDown,
        HButton,
    },
    data() {
        return {
            shipping_info: {
                first_name: '',
                last_name: '',
                street_address: '',
                apt_suite: '',
                city: '',
                state: '',
                zip_code: '',
                country: 'us',
                phone_number: '',
            },
            new_states: [],
            api_key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
            scrollDisabled: false,
        };
    },
    validations: {
        shipping_info: {
            first_name: { required },
            last_name: { required },
            street_address: { required, maxLength: maxLength(35) },
            apt_suite: { maxLength: maxLength(35) },
            city: { required, maxLength: maxLength(35) },
            state: { required },
            zip_code: { required, minLength: minLength(5), isZipcode },
            country: { required },
            phone_number: { required, minLength: minLength(14) },
        },
    },
    watch: {
        shipping_info: {
            handler() {
                this.onShippingChange();
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        this.shipping_info = this.shipping;
        this.onShippingChange();
    },
    mounted() {
        const googleMapsCallback = () => {
            initMap(this.$refs.autocompleteInput, this.onInputFocus, this);
            this.populateStates();
        };

        loadGoogleMaps(this.api_key, googleMapsCallback);
    },
    computed: {
        ...mapState({
            states: (state) => state.states.states,
        }),
    },
    methods: {
        async populateStates() {
            await this.retrieveStates();
            this.new_states = this.states.map((item) => {
                return { ...item, value: item.abbreviation };
            });
        },
        onInputFocus() {
            this.scrollDisabled = true;
        },
        onInputBlur() {
            this.scrollDisabled = false;
        },
        emitShippingData() {
            this.$emit('shipping-data', this.shipping_info);
        },
        onShippingChange() {
            this.$emit('shipping-change', {
                data: this.shipping_info,
                validations: this.$v.shipping_info,
            });
        },
        ...mapActions({
            retrieveStates: 'states/retrieveStates',
        }),
    },
};
</script>

<style lang="scss">
.shipping {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin: 0 auto;

    &__button {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 2rem;
    }
}

.pac-container {
    position: absolute !important;
    top: 0;
}

.pac-item {
    font-size: 0.875rem;
    padding: 0.5rem 0.875rem;
    cursor: pointer;
}

.scroll-disabled {
    overflow: hidden;
}
</style>
