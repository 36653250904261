exports.getHeight = (inches) => {

    return {
        ft: Math.round(inches / 12),
        in: inches % 12,
    };
};

exports.convertToInches = (feet, inches) => {
    feet = parseInt(feet);
    inches = parseInt(inches);

    return (feet * 12) + inches;
};

exports.weightToDosage = (weight, maxQuantity) => {
    const dosage = (weight / 2.2) * .075;
    const rounded = Math.max(1, Math.floor(dosage));

    if (maxQuantity) {
        const weeklyQuantity = (maxQuantity / 4);

        return Math.min(rounded, weeklyQuantity);
    } else {
        return rounded;
    }
};
