<template>
    <div class="shipping-item">
        <div class="shipping-item__label">{{ label }}</div>
        <div class="shipping-item__value" :data-test="id ? id : null">{{ value }}</div>
    </div>
</template>

<script>
export default {
    name: 'ShippingItem',
    props: {
        label: {
            type: String,
        },
        value: {
            type: String,
        },
        id: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.shipping-item {
    font-family: 'Sofia Regular';
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    text-align: left;

    &__label {
        color: rgba(0, 0, 0, 0.44);
        flex: 1;
    }

    &__value {
        color: rgba(0, 0, 0, 0.88);
        font-weight: 400;
        -webkit-box-flex: 1;
        flex: 2;
    }

    @media (min-width: 760px) {
        flex-direction: row;

        &__value {
            margin-left: 0.5rem;
        }
    }
}
</style>
