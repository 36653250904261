<template>
  <div style="width:100%;height:100%;overflow:scroll" id="my-cal-inline"></div>
</template>

<script>
export default {
  props: {
    calcomConfig: {
      type: Object,
      required: true,
    },
    calcomLink: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    intake_id: {
      type: String,
      required: true
    },
  },
  destroyed() {
    if (typeof window !== 'undefined' && window.Cal && window.Cal.instance && window.Cal.instance.inlineEl) {
      delete window.Cal.instance.inlineEl
    }
  },
  mounted() {
    window.calcom = this.calcomConfig || {};
    (function (C, A, L) {
      let p = function (a, ar) { a.q.push(ar); };
      let d = C.document;
      C.Cal = C.Cal || function () {
        let cal = C.Cal;
        let ar = arguments;
        if (!cal.loaded) {
          cal.ns = {}; cal.q = cal.q || [];
          d.head.appendChild(d.createElement("script")).src = A;
          cal.loaded = true;
        }
        if (ar[0] === L) {
          const api = function () { p(api, arguments); };
          const namespace = ar[1];
          api.q = api.q || [];
          typeof namespace === "string" ? (cal.ns[namespace] = api) && p(api, ar) : p(cal, ar);
          return;
        }
        p(cal, ar);
      };
    })(window, "https://app.cal.com/embed/embed.js", "init");

    window.Cal("init", {origin:"https://cal.com"});

    window.Cal("inline", {
        elementOrSelector: "#my-cal-inline",
        calLink: this.calcomLink,
        layout: "month_view",
        config: {
            email: this.email,
            name: this.name,
            'zp-patient-id': this.id,
            'zp-intake-id': this.intake_id,
            ...(this.calcomConfig || {}),
        }
    });

    window.Cal("on", {
      action: 'bookingSuccessful',
      callback: (e)=>{
        const {data} = e.detail;
        this.$emit('booking-successful', data);
      }
    })
    window.Cal("ui", {"styles":{"branding":{"brandColor":"#000000"}},"hideEventTypeDetails":false,"layout":"month_view"});
  }
}
</script>
