<template>
<div class="pharmacy-verification__container">
    <Alert
        actionText="Add Information"
        layout="single-line"
        message="In order to approve your medication, you will need to provide some more information"
        type="error"
        @onAction="onAddInformation"
    ></Alert>
    <modal classes="pharmacy-verification__modal" height="365" name="pharmacy-verification-modal">
        <div class="pharmacy-verification__modal-container">
            <h4>Pharmacy Verification</h4>
            <p>For the protection of you and our pharmacy, for any controlled substances we require identity
                verification with a social security number prior to gaining access to your medication. Your social
                security number is saved with end-to-end encryption. </p>
            <form class="pharmacy-verification__form">
                <div class="pharmacy-verification__form-item mb-3">
                    <label class="zp-label zp-label--stone">Social Security Number</label>
                    <input v-model="ssn" v-mask="'###-##-####'" class="zp-input zp-input--stone" placeholder="Social Security Number"
                           type="password"/>
                    <span v-if="error" class="zp-form-helper zp-form-helper--error">There was an error, please try again.</span>
                </div>
                <button class="zp-btn zp-btn--cool zp-btn--small" type="submit" @click="submitVerification()">
                    {{ submit }}
                </button>
            </form>
        </div>
    </modal>
</div>
</template>
<script>
import Alert from '@/components/Components/Alert.vue';
import {mapActions, mapState} from "vuex";

export default {
    name: 'PharmacyVerificationAlert',
    components: {
        Alert: Alert
    },
    data() {
        return {
            ssn: null,
            error: false,
            submit: 'Submit'
        }
    },
    methods: {
        ...mapActions({
            getOrganization: 'getOrganization',
            saveSSN: 'user/saveSSN',
            getUser: 'user/retrieveUser',
        }),
        onAddInformation() {
            this.$modal.show('pharmacy-verification-modal');
            this.$forceUpdate();
        },
        async submitVerification() {
            this.error = false;
            if (this.ssn) {
                this.submit = 'Submitting...';
                try {
                    let saved = await this.saveSSN({
                        patient: this.user._id,
                        data: {security_number: this.ssn}
                    });
                    if (saved) {
                        let user = await this.getUser({user: this.user._id});
                        if (user) {
                            this.$modal.hide('pharmacy-verification-modal');
                            this.submit = 'Submit';
                        }
                    }
                } catch (err) {
                    this.error = true;
                }
            }
        }
    },
    computed: {
        ...mapState({
            organization: state => state.organization.data,
            user: state => state.user.user
        })
    },
}
</script>
<style lang="scss">
.pharmacy-verification {
    &__container {
        width: 100%;
    }

    &__modal-container {
        padding: 2rem;
    }

}
</style>d
