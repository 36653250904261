<template>
    <div class="protocol-image">
        <div class="protocol-image__container protocol-image__backdrop">
            <img :src="image" alt="">
        </div>
        <div class="protocol-image__testimonial" v-if="testimonial">
            <h-testimonials :full_name="testimonial.full_name" :profile_image="testimonial.profile_image" :testimonial="testimonial.testimonial" />
        </div>
    </div>
</template>
<script>
import HTestimonials from '@/components/Shared/HTestimonials.vue';

export default {
    name: 'TreatmentProductImage',
    components: {HTestimonials},
    props: {
        image: {
            type: String,
        },
        width: {
            type: String,
        },
        testimonial: {
            type: Object,
            default: null,
        },
    },  
}
</script>
<style lang="scss">
@import "@/assets/scss/_colors.scss";

.protocol-image {
    position: relative;

    &__container {
        display: flex;
        justify-content: center;
        background-color: $bg-image;
    
        img {
            width: 100%;
            height: 416px;
            object-fit: cover;
            margin: 0 auto;
        }
    }

    &__testimonial {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 1rem;
        z-index: 2;
    }
    
    &__backdrop {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            background: rgb(33,41,49);
            background: linear-gradient(0deg, rgba(33,41,49,1) 0%, rgba(33,41,49,0) 100%, rgba(33,41,49,0) 100%);
            z-index: 1;
        }
    }


    @media (min-width: 480px) {
        img {
            height: 608px;
        }
    }

    @media (min-width: 640px) {
        &__container { 
            border-radius: 0.75rem;
            overflow: hidden;

            img {
                height: 467px;
            }
        }

        &__testimonial {
            padding: 2rem;
        }

        &__backdrop {
            &::after {
                border-radius: 0.75rem;
            }
        }
    }

    @media (min-width: 1024px) {
        &__container {
            img {
                height: 674px;
                object-position: right top;
            }
        }
    }
}


</style>
