<template>
    <div>
        <Nav stage="2"/>
        <protocol-skeleton v-if="product_loading"/>
        <div v-show="!product_loading" class="protocol">
            <div class="protocol__side">
                <treatment-product-image :width="width" :image="treatment_preference_image" :testimonial="testimonial"></treatment-product-image>
                <div class="protocol__side-benefits" v-if="features"><Benefits :features="features"/></div>
            </div>
            <div class="protocol__preferences">
                <treatment-durations
                    v-if="treatment_durations && intake_template.data"
                    :products="products"
                    :intake="intake_template.data"
                    @onPlanSelected="on_duration_selected"></treatment-durations>
                <treatment-selection
                    :ref="product._id"
                    v-for="product in products"
                    :product_id="product._id"
                    :product_data="product"
                    @onConfiguredPlan="on_plan_update"
                    :intake_template="intake_template.data"
                    :main_duration="main_duration"
                    @custom-frequency="handleCustomFrequencyUpdate"
                >
                </treatment-selection>
            </div>
        </div>
        <transition name="protocol-transform" mode="out-in">
            <div class="protocol-summary__mobile" v-if="show_summary">
                <treatment-total :intake="intake_template.data" @onContinue="on_continue" :submitting="is_submitting" :custom_frequency="custom_frequency"></treatment-total>
            </div>
        </transition>
        <div class="protocol-summary__desktop">
            <treatment-total :intake="intake_template.data" @onContinue="on_continue" :submitting="is_submitting" :custom_frequency="custom_frequency"></treatment-total>
        </div>
    </div>
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import Nav from '@/components/Consultation/Nav.vue'
import { CHECKOUT_BREAKPOINTS } from '@/constants/checkout';
import TreatmentPreferencesSelection from '@/components/TreatmentPreferences/TreatmentSelectionCheckout.vue'
import TreatmentProductImage from '@/components/TreatmentPreferences/Components/TreatmentProductImage.vue';
import { generateTreatmentTag, tags } from '@/constants/mailchimpTags';
import product_service from '@/services/Product';
import TreatmentTotal from "@/components/TreatmentPreferences/Components/TreatmentTotal";
import TreatmentMedicationType from "@/components/TreatmentPreferences/Components/TreatmentMedicationType";
import TreatmentPlanDurations from "@/components/TreatmentPreferences/Components/TreatmentPlanDurations.vue";
import { analytics, analyticsActions } from '@/helpers/analytics'
import Benefits from '../Auth/Benefits.vue';
import ProtocolSkeleton from '../Shared/Skeletons/ProtocolSkeleton.vue';
import { hasImage } from '../../domains/user/lib/checkUserVerification';

export default {
    name: 'TreatmentPreferences',
    components: {
        Nav: Nav,
        TreatmentSelection: TreatmentPreferencesSelection,
        TreatmentDurations: TreatmentPlanDurations,
        TreatmentProductImage: TreatmentProductImage,
        TreatmentTotal: TreatmentTotal,
        TreatmentMedicationType: TreatmentMedicationType,
        Benefits,
        ProtocolSkeleton
    },
    watch: {
        'product_loading': {
            async handler(e) {
                setTimeout(() => {
                    this.resize();
                }, 1000)

            },
            deep: true,
            immediate: true
        },
        'treatment_settings': {
            async handler(settings) {
                this.has_review(settings)
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            treatment_preference_image: null,
            products: [],
            product_templates: [],
            selected_products: [],
            is_submitting: false,
            total_prices: null,
            main_duration: null,
            total_loading: false,
            product_loading: false,
            width: '100%',
            product_configurations: [],
            product_config_map: {},
            loaded_templates: [],
            duration_price_map: {},
            main: null,
            has_review_request: false,
            show_summary: false,
            testimonial: null,
            custom_frequency: false,
        }
    },
    methods: {
        ...mapActions({
            get_product_templates: 'productTemplates/getManyProductTemplatesByIds',
            get_intake_template: 'intake/getIntakeTemplate',
            get_intake: 'intake/retrieveIntake',
            add_subscriber_tags: 'user/addSubscriberTags',
            get_products: 'products/getProducts',
            get_membership_prices: 'memberships/getMembershipPrices',
            updateIntakeProducts: 'intake/updateIntakeProducts',
        }),
        ...mapMutations({
            set_active_duration: 'treatment/setActiveDuration',
            set_treatment_products: 'treatment/setTreatmentProducts',
            set_treatment_durations: 'treatment/setTreatmentDurations',
            set_treatment_product_properties: 'treatment/setTreatmentProductProperties',
            set_treatment_item: 'treatment/setTreatmentItem'
        }),
        handleCustomFrequencyUpdate(custom_frequency) {
            this.custom_frequency = custom_frequency;
        },
        handleResize() {
            if (window.innerWidth < 1024) {
                const yOffset = window.scrollY;
                const windowHeight = window.innerHeight;
                const documentHeight = document.documentElement.scrollHeight;

                const currentWidth = CHECKOUT_BREAKPOINTS.find((breakpoint) => window.innerWidth < breakpoint.width);
                this.show_summary = yOffset > currentWidth.yOffset || yOffset + windowHeight >= documentHeight;
            }
        },
        setLayout() {
            this.handleResize();
            window.addEventListener("resize", this.handleResize);
            window.addEventListener("scroll", this.handleResize);
        },
        resize() {
            this.visible = false;
            const element = document.getElementsByClassName('checkout-display-section')[0];
            if (element) {
                this.width = element.getBoundingClientRect().width + 'px';
                this.visible = true;
                this.$forceUpdate();
            }

        },
        on_duration_selected(duration) {
            analytics.track(analyticsActions.treatment_preferences_duration, {
                months: duration.months,
            })
            this.set_active_duration(duration);
        },
        has_review(settings) {
            this.has_review_request = product_service.hasReview(settings);
        },
        configure_treatment_image(intake, product_templates) {
            this.treatment_preference_image = product_templates[0].settings.alternate_image || product_templates[0].product_images[0];
            if (product_templates[0].testimonials.length) this.testimonial = product_templates[0].testimonials[0]
            if (!intake.bundle_support) {
                this.treatment_preference_image = product_templates[0].settings.alternate_image || product_templates[0].product_images[0];
            } else {
                this.treatment_preference_image = intake.bundle_settings.image;
            }

            this.$forceUpdate();
        },
        configure_product_templates() {
            this.products = this.product_templates.map((item, index) => {
                item['main'] = index === 0;
                return item;
            });
            this.main_duration = this.products.find(p => p.main);
            this.$forceUpdate();
        },
        async configure_durations() {
            let product_durations = {}
            let products = this.loaded_templates.map(async product => {
                let durations = product.shipping_frequencies.map(async duration => {
                    let product_items = await this.get_products({
                        product_template: product._id
                    });
                    this.set_treatment_product_properties({
                        product_id: product._id,
                        product_properties: product_items
                    });
                    let membership_price;
                    if ('membership' in product) {
                        let membership_prices = await this.get_membership_prices({
                            membership: product.membership
                        })
                        membership_price = membership_prices.find(m =>
                            m.recurring.interval_count === duration.months
                        );
                    }
                    const frequency = product.dosage_frequencies[0];
                    const current_product = product_items.find(p =>
                        p.pricing.interval_count === duration.months &&
                        p.plan_properties.dosage_frequency.label === frequency.frequency.label &&
                        p.plan_properties.dosage_frequency.count === frequency.frequency.count
                    );
                    const units = frequency.has_custom_quantity ? frequency.custom_quantity.default : frequency.monthly_units;
                    duration.prices = product_service.generatePrice(current_product, frequency, membership_price, units);
                    return duration;

                });
                return product;
            });
            let product_new = await Promise.all(products);
            product_new.map(m => {
                product_durations[m._id] = m.shipping_frequencies;
            })
            this.set_treatment_durations(product_durations);
        },
        async generate_pricing(products) {
            let product_items = products.map(async product => {
                let durations = product.shipping_frequencies.map(async duration => {
                    let product_items = await this.get_products({
                        product_template: product._id
                    });
                    let membership_price;


                    const current_product = product_items.find(p => p.pricing.interval_count === duration.months );
                    const frequency = product.dosage_frequencies[0];
                    const units = frequency.has_custom_quantity ? frequency.custom_quantity.default : frequency.monthly_units;
                    duration.prices = product_service.generatePrice(current_product, frequency, membership_price, units);
                    return duration;

                })
                return product
            });
            return await Promise.all(product_items)
        },
        async init_product_items() {
            let product_ids = [...this.intake_template.data.product_defaults];
            this.loaded_templates = await this.get_product_templates({
                ids: product_ids
            });
            this.product_templates = this.loaded_templates;

            await this.configure_treatment_image(this.intake_template.data, this.product_templates);
            this.configure_product_templates();
            await this.configure_durations();
            this.set_treatment_products(this.product_templates);
            this.$forceUpdate();
            await this.$nextTick();
        },

        async mailchimp_intake_complete() {
            const intake_complete = JSON.parse(window.localStorage.getItem('intake_completed'));
            if (intake_complete) {
                try {
                    if (this.mailchimp_integration && this.user.mailchimp_id) {
                        await this.add_subscriber_tags({
                            tag: {
                                name: tags.status.intake.complete,
                                status: 'active'
                            },
                            patient: this.user._id
                        });
                        await this.add_subscriber_tags({
                            tag: {
                                name: tags.status.intake.incomplete,
                                status: 'inactive'
                            },
                            patient: this.user._id
                        });
                        window.localStorage.removeItem('intake_completed');
                    }
                } catch (e) {
                    console.log('AddSubscriberTagsError', e);
                }
            }
        },
        async mailchimp_treatment_products(product_template) {
            try {
                if (this.mailchimp_integration) {
                    await this.add_subscriber_tags({
                        tag: {
                            name: generateTreatmentTag(this.productTemplate.data.name),
                            status: 'active'
                        },
                        patient: this.user._id
                    })
                }

            } catch (e) {
                console.log('There was an error', e);
            }
        },
        async on_plan_update(configuration) {
            this.total_loading = true;
            try {
                this.product_config_map[configuration._id] = configuration;
                this.saveProducts(this.product_config_map);
                this.total_loading = false;
            } catch (e) {
                this.total_loading = false;
                console.log('Error on_plan_update', e);

            }
        },
        goToAddOns() {
            const treatmentParams = {
                section: 'treatment',
                intakeId: this.$route.params.intakeId
            }
            this.$router.push({name: 'treatment-selection', params: treatmentParams}).catch(err => console.log(err));
        },
        goToCheckout() {
            let params = this.$router.currentRoute.params;
            const photoType = 'verify_account';

            const defaultParams = {
                section: hasImage(this.user) ? 'checkout' : 'identification-step',
                intakeId: params.intakeId,
                photoType,
            };

            this.$router.push({
                name: 'identification-step-alt',
                params: defaultParams,
            });
        },
        saveProducts(products) {
            const product_objects = Object.values(products);

            let main = product_objects.find(p => p.main);
            window.localStorage.setItem('selected_products', JSON.stringify(product_objects));

            if (main) {
                window.localStorage.setItem('default_item', JSON.stringify(main));
            } else {
                window.localStorage.setItem('default_item', JSON.stringify(product_objects[0]));
            }

        },

        async on_continue() {
            this.is_submitting = true;
            let signupConfig = JSON.parse(window.localStorage.getItem('signup-config'));

            let treatment_products = Object.entries(this.product_config_map).flatMap(([key, value], index) => {
                return value;
            });

            try {
                const [product] = treatment_products;

                analytics.track(analyticsActions.treatment_preferences_continue, {
                    dosage_type: product.dosage_type,
                    intake_template: this.intake_template.data._id,
                    organization: product.organization,
                    product_id: product._id,
                    name: product.name,
                    product_name: product.product_name,
                })
            } catch (error) {
                console.log('Error while logging treatment_preferences_continue');
                console.log(error)
            }

            // Update intake
            await this.updateIntakeProducts({
                patient_id: this.user._id,
                selectedProducts: treatment_products
            })
            this.is_submitting = false;
            if (signupConfig.product.addon_products.length) {
                this.goToAddOns();
            } else {
                this.goToCheckout();
            }
        }
    },
    async mounted() {
      await this.$nextTick();
      this.resize();
      this.setLayout();
    },
    async created() {

        this.product_loading = true;
        this.product_configurations = [];

        let intake = await this.get_intake({
            _id: this.$route.params.intakeId,
            user: this.user._id
        })

        try {
            await this.get_intake_template({
                templateId: intake.intake_template._id
            });

            await this.mailchimp_intake_complete();
            await this.init_product_items();
            this.product_loading = false;
            this.$forceUpdate();
        } catch (e) {
            this.product_loading = false;
            console.log('Error created()', e);
        }
        this.resize();
        window.addEventListener("DOMContentLoaded", this.resize);
        window.addEventListener("resize", this.resize);

    },
    computed: {
        ...mapState({
            intake_template: state => state.intake.selectedIntakeTemplate,
            user: state => state.user.user,
            mailchimp_integration: state => state.integrations.mailchimp_exists.data,
            treatment_items: state => state.treatment.treatment_items,
            treatment_durations: state => state.treatment.treatment_durations,
            treatment_settings: state => state.treatment.treatment_preferences,
            organization_settings: state => state.organizationSettings,
        }),
        features() {
            return this.organization_settings.data && this.organization_settings.data.signup && this.organization_settings.data.signup.features
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("scroll", this.handleResize);
    }
}
</script>
<style lang="scss">
</style>
