<template>
<div>
    <div :id="intake_instance_response.data._id" class="intake-question">
        <div class="intake-question__header">
            <h2>{{ intake_instance_response.data.question.label }}</h2>
            <p class="intake-question__small text-dim-60">Select all that apply</p>
            <p v-if="intake_instance_response.data.question.description_text" class="intake-question__small">>
                {{ intake_instance_response.data.question.description_text }}</p>
        </div>

        <div class="intake-question__options">
            <div v-for="(option, index) in intake_instance_response.data.question.options" v-bind:key="index" class="intake-question__option">
                <input :id="option._id" v-model="responses" :value="option._id" class="hidden-radio" type="checkbox"/>
                <label :for="option._id" class="intake-question__checkmark" :class="{'not-chosen': isDisabled(option._id)}" @click="removeNone()">
                    <div>{{ option.label }}</div>
                    <div v-if="responses.includes(option._id)">
                        <img src="../../../assets/images/icons/checkmark.svg" alt="">
                    </div>
                </label>
            </div>

            <div v-if="intake_instance_response.data.question.include_none" class="intake-question__single-option">
                <input id="none-option" v-model="responses" class="hidden-radio" type="checkbox" :value="noneDatabaseValue"/>
                <label for="none-option" @click="nullify()">
                    None
                </label>
            </div>
        </div>

        <div v-if="selectedResponseConditions && loaded" >
            <div v-for="(response_condition) in selectedResponseConditions" v-bind:key="response_condition._id" class="intake-question__textarea">
                <label class="intake-question__small">{{ response_condition.question.label }}</label>
                <textarea v-model="response_condition.free_format_response"></textarea>
            </div>
        </div>

        <div class="intake-button">
                <h-button
                    label="Continue"
                    :loading="button_submitting"
                    variant="primary"
                    full_width 
                    large 
                    :handle-click="checkForm" 
                    data-test="continue-button"
                >
                </h-button>
        </div>

    </div>
    <div class="intake-certification">
        <h-certification/>
    </div>

</div>
</template>

<script>
import {mapGetters, mapMutations, mapActions, mapState} from 'vuex'
import async from 'async';
import {removeItem, childResponseMapper} from '@/services/parser.js';
import _ from 'lodash';
import HButton from '../../Shared/HButton.vue';
import HCertification from '@/components/Shared/HCertification.vue';

export default {
    name: 'Checkbox',
    data() {
        return {
            submitted: false,
            submitting: false,
            button_text: 'Next',
            noneDatabaseValue: '615b91a94abb4d9d103efd41',  //TODO: should be fetched from server-side
            button_submitting: false,
            responses: [],
        }
    },
    props: ['step', 'loaded'],
    components: {
        HButton,
        HCertification
    },
    methods: {
        noneSelected() {
            return this.responses.includes(this.noneDatabaseValue)
        },
        isDisabled(optionId) {
            return !this.responses.includes(optionId)
        },
        removeNone() {
            let newArray = removeItem(this.responses, this.noneDatabaseValue);
            this.responses = newArray;
        },
        nullify() {
            this.responses.splice(0)
            this.$set(this.responses, this.noneDatabaseValue, 0);
            setTimeout(() => {
                this.checkForm()
            }, 150)
        },
        checkForm(e) {
            const vm = this;
            vm.submitted = true;
            if (vm.responses.length === 0) {
                this.responses.push(this.noneDatabaseValue);
            }
            vm.button_submitting = true;
            const patient_response = {responses: vm.responses}

            async.waterfall([
                function updateParentIntakeResult(callback) {

                    let payload = {
                        response_id: vm.intake_instance_response.data._id,
                        user_id: vm.user._id,
                        intake_id: vm.$route.params.intakeId,
                        response: patient_response
                    }

                    vm.updateIntakeResponse(payload).then((saved_response) => {
                        callback(null, {})
                    }).catch((err) => {
                        console.error(err);
                        vm.button_submitting = false;
                    })
                },
                function updateChildResponses(last, callback) {
                    //check for response conditions
                    if (vm.selectedResponseConditions.length === 0) {
                        return callback(null, {})
                    }

                    let payload = {
                        parent_response_id: vm.intake_instance_response.data._id,
                        user_id: vm.user._id,
                        organization: vm.user.organization,
                        intake_id: vm.intake_id,
                    }

                    payload['responses'] = childResponseMapper(vm.selectedResponseConditions);
                    vm.setChildResponseByQuestionId(payload).then((response) => {
                        callback(null, response);
                    }).catch((err) => {
                        console.error(err);
                        vm.button_submitting = false;
                    })
                },
                function nextStep(last, callback) {
                    setTimeout(() => {
                        // todo: FIXME
                        vm.routerPush(vm.step);
                    }, 150)
                }

            ])

        },
        ...mapActions({
            updateIntakeResponse: 'intake/updateIntakeResponse',
            setChildResponseByQuestionId: 'intake/setChildResponseByQuestionId'
        }),
        ...mapMutations({
            routerPush: 'intake/routerPush',
            returnChildResponses: 'intake/returnChildResponses'
        })
    },
    computed: {
        ...mapGetters({
            totalSteps: 'intake/totalSteps'
        }),
        ...mapState({
            intake_id: state => state.intake.intake_id,
            states: state => state.states.states,
            user: state => state.user.user,
            signupIntakeTemplateId: state => state.intake.signupIntakeTemplateId,
            selectedIntakeTemplate: state => state.intake.selectedIntakeTemplate,
            currentIntakeInstance: state => state.intake.currentIntakeInstance,
            intake_instance_response: state => state.intake.intake_instance_response
        }),
        checkTrue() {
            return (this.responses.length > 0);
        },
        //check if options are matched against branched responsed conditions array
        selectedResponseConditions: {
            get() {
                const vm = this;
                let response_conditions = vm.intake_instance_response.data.question.response_conditions || [];
                let patient_responses = vm.responses || [];
                let matched_response_condition = response_conditions.filter((response_condition) => {
                    return patient_responses.includes(response_condition.selected_option);
                })
                return matched_response_condition || null;
            }

        }

    },
    created() {
        const vm = this;
        let responses = this.intake_instance_response.data.responses || [];
        this.responses = _.map(responses, '_id');

        let response_conditions = vm.intake_instance_response.data.question.response_conditions || [];
        let child_responses = vm.intake_instance_response.data.child_responses || [];
        response_conditions.forEach((response_condition) => {
            child_responses.forEach((child_response) => {
                if (child_response.question === response_condition.question._id) {
                    response_condition.free_format_response = child_response.free_format_response;
                }
            })
        })

    }

}
</script>
