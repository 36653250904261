<template>
    <div v-if="treatment_product_template">
        <form>
            <div class="protocol-selection__header">
                <h1 v-if="treatment_product_template.main && selectedIntake.data.bundle_support">{{
                    selectedIntake.data.bundle_support ? selectedIntake.data.bundle_settings.title : productTemplate.data.product_name
                }}</h1>
                <h1 v-if="!selectedIntake.data.bundle_support">{{
                    treatment_product_template.product_name
                }}</h1>
                <p v-if="!selectedIntake.data.bundle_support" id="checkout-desc" class="protocol-body810">
                    {{ treatment_product_template.description }}</p>
            </div>

            <treatment-membership v-if="treatment_product_template.membership"
                                    :membership="treatment_product_template.membership"></treatment-membership>
            <treatment-description :intake="selectedIntake.data"
                                    :product="treatment_product_template"></treatment-description>
            <protocol-features v-if="product_features && product_features.length > 0" :features="product_features"/>

            <div v-if="!product_settings.preferences.recommended" class="protocol-selection__preferences">
                <p class="protocol-body810 strong n-400">Customize your treatment</p>
                <h2 class="protocol-header">Protocol Preferences</h2>
                <div class="protocol-caption n-400">Upon submitting your visit, a provider
                    will review your information and determine the best treatment plan for you. You can
                    change the dosage strength and quantity here to indicate a treatment preference for
                    your doctor to review.
                </div>
            </div>
            <div class="treatment-selection__type"
                    v-if="!selectedIntake.data.bundle_support && selectedIntake.data.product_defaults.length > 1">
                <h3 class="headline-3 mb-1">Medication Type</h3>
                <div class="treatment-checkout__configure-review">Select your medication type</div>
                <treatment-medication-type
                    :products="selectedIntake.data.product_defaults"
                    :intake_template="selectedIntake.data"
                    @onSelectedType="selectedMedicationType"
                ></treatment-medication-type>
            </div>
            <div v-if="product_settings.preferences.recommended && treatment_product_template">
                <div class="protocol-selection__preferences">
                    <p class="protocol-body810 strong n-400">Customize your treatment</p>
                    <h2 class="protocol-header">Protocol Preferences</h2>
                    <div class="protocol-caption n-400">Upon submitting your visit, a provider
                        will review your information and determine the best treatment plan for you. You can
                        change the dosage strength and quantity here to indicate a treatment preference for
                        your doctor to review.
                    </div>
                </div>
                <treatment-recommended-options
                    :dose_selection_text="treatment_product_template.dose_selection_text"
                    :preferences_instructions="product_settings.preferences.instructions"
                    :recommendation_request="treatment_product_template.settings.recommendation_request"
                    @onRecommendedSelected="select_preference_item"
                ></treatment-recommended-options>
            </div>
            <div v-if="product_settings.preferences.custom" class="protocol-selection__preferences-custom">
                <div v-if="product_settings.dosage_strengths.length > 1" class="question-container">
                    <treatment-dose-selection
                        :dosage_strengths="product_settings.dosage_strengths"
                        :default="product_settings.dosage_strength"
                        @onChange="update_dosage"
                    ></treatment-dose-selection>
                </div>

                <div
                    v-if="product_settings.dosage_frequency && product_settings.dosage_frequency.has_custom_quantity"
                    class="question-container">
                    <treatment-quantity-selection
                        :quantity_info="product_settings.dosage_frequency.custom_quantity"
                        :medication_type="treatment_product_template.medicationType"
                        :custom_quantities="product_settings.quantities"
                        :units="product_settings.units"
                        :selected_duration="product_settings.duration"
                        :dosage_strength="product_settings.dosage_strength"
                        @onQuantityChange="select_quantity"></treatment-quantity-selection>

                </div>
                <treatment-frequency
                    v-if="treatment_product_template.dosage_frequencies && product_settings.dosage_frequency"
                    :dosage_frequency="product_settings.dosage_frequency"
                    :dosage_frequencies="treatment_product_template.dosage_frequencies"
                    :dosage_strength="product_settings.dosage_strength"
                    :product_units="product_settings.units"
                    :dose_quantity="treatment_product_template.dose_quantity"
                    :product="product_data.product_name"
                    @onFrequencyChange="select_frequency"
                    @onCustomSelect="select_custom"
                    v-model="custom_comment"
                    @input="handleCustomCommentUpdate"
                ></treatment-frequency>
            </div>
        </form>
    </div>
</template>

<script>

import IntakeButton from '@/components/Consultation/SubmitButton.vue';
import {mapActions, mapMutations, mapState} from 'vuex';
import { weightToDosage } from '@/helpers/conversions';
import TreatmentPlanDurations from "@/components/TreatmentPreferences/Components/TreatmentPlanDurations";
import product_service from '@/services/Product';
import TreatmentDescription from "@/components/TreatmentPreferences/Components/TreatmentDescription";
import TreatmentMembership from "@/components/TreatmentPreferences/Components/TreatmentMembership";
import TreatmentRecommendedOptions from "@/components/TreatmentPreferences/Components/TreatmentRecommendedOptions.vue";
import TreatmentDoseSelection from "@/components/TreatmentPreferences/Components/TreatmentDoseSelection.vue";
import TreatmentQuantitySelection from "@/components/TreatmentPreferences/Components/TreatmentQuantitySelection.vue";
import TreatmentFrequency from "@/components/TreatmentPreferences/Components/TreatmentFrequency.vue";
import TreatmentMedicationType from "@/components/TreatmentPreferences/Components/TreatmentMedicationType.vue";
import { analytics, analyticsActions } from '@/helpers/analytics';
import ProtocolFeatures from './Components/ProtocolFeatures.vue';

export default {
    name: 'TreatmentPreferences',
    data() {
        return {
            treatment_product_template: null,
            product_settings: {
                quantities: [],
                dosage: null,
                frequency: null,
                quantity: null,
                duration: null,
                membership: null,
                pricing: null,
                products: null,
                shipping_frequencies: [],
                preferences: {
                    recommended: false,
                    custom: true
                },
                dosage_strengths: []
            },
            checkoutButtonText: 'Continue',
            submitting: false,
            custom_comment: null,
        }
    },
    components: {
        IntakeButton: IntakeButton,
        TreatmentPlanDurations: TreatmentPlanDurations,
        TreatmentDescription: TreatmentDescription,
        TreatmentMembership: TreatmentMembership,
        TreatmentRecommendedOptions: TreatmentRecommendedOptions,
        TreatmentDoseSelection: TreatmentDoseSelection,
        TreatmentQuantitySelection: TreatmentQuantitySelection,
        TreatmentFrequency: TreatmentFrequency,
        TreatmentMedicationType: TreatmentMedicationType,
        ProtocolFeatures
    },
    watch: {
        'active_duration': {
            async handler(duration) {
                if (duration && this.product_settings.products) {
                    this.product_settings.duration = duration;
                    this.get_updated_pricing();
                    this.$forceUpdate();
                }
            },
            immediate: true,
            deep: true
        }
    },
    created() {
        analytics.track(analyticsActions.treatment_preferences_opened);
        this.select_product_id = this.selectedIntake.data.bundle_support ? this.product_id : this.$route.query.product;
        this.$nextTick(async () => {
            this.membershipConfig = JSON.parse(window.localStorage.getItem('membership'));
            await this.setup_treatment(this.select_product_id);
        })
    },
    props: ['product_id', 'product_data', 'main_duration'],
    methods: {
        select_custom(customFrequency) {
            this.select_preference_item('custom-request', customFrequency);
            this.$emit('custom-frequency', customFrequency);
        },
        handleCustomCommentUpdate() {
            this.get_updated_pricing();
        },
        setup_frequency() {
            this.product_settings.dosage_frequency = this.treatment_product_template.dosage_frequencies[0];
        },
        setup_main() {
            this.product_settings.main = this.product_data.main;
        },
        setup_shipping_frequencies() {
            this.product_settings.shipping_frequencies = this.treatment_product_template.shipping_frequencies;
        },
        setup_dosage() {
            this.treatment_product_template.dosage_strengths.forEach(d => {
                d['dose'] = parseFloat(d.strength);
                d['suffix'] = d.strength.replace(/[0-9]/g, '').replace(/[^a-zA-Z ]/g, "");
            });
            this.product_settings.dosage_strengths = this.treatment_product_template.dosage_strengths;
        },
        setup_active_dosage(dosage) {
            this.product_settings.dosage_strength = dosage;
        },
        setup_quantity(frequency) {
            this.product_settings.quantities = [];
            const quantities = frequency.custom_quantity;
            for (let i = quantities.min; i <= quantities.max; i++) {
                this.product_settings.quantities.push(i);
            }
        },
        setup_units(product, frequency, user, type) {
            this.product_settings.preference_option = 'custom';
            if (frequency.has_custom_quantity) {
                this.setup_quantity(frequency);

                if ('dose_recommendation' in product) {
                    this.product_settings.preference_option = type;
                    this.product_settings.recommendation_requested = false;

                    switch (type) {
                    case 'recommended':
                        this.product_settings.units = weightToDosage(user.weight, this.treatment_product_template.settings.max_quantity_recommendation) * 4;
                        break;
                    case 'request':
                        this.product_settings.units = frequency.custom_quantity.default;
                        this.product_settings.recommendation_requested = true;
                        break;
                    default:
                        this.product_settings.units = frequency.custom_quantity.default;
                        break;
                    }
                } else {
                    this.product_settings.units = this.product_settings.quantities.find(i => i === frequency.custom_quantity.default);
                }
            } else {
                switch (type) {
                    case 'request':
                        this.product_settings.units = frequency.monthly_units;
                        this.product_settings.recommendation_requested = true;
                    default:
                    this.product_settings.units = frequency.monthly_units;
                        break;
                }
            }
        },
        async setup_membership_prices() {
            this.product_settings.membership_prices = null;
            if ('membership' in this.treatment_product_template) {
                this.product_settings.membership_prices = await this.getMembershipPrices({
                    membership: this.treatment_product_template.membership._id
                })
            }
        },
        setup_pricing(active_product, frequency, membership, units) {
            this.product_settings.pricing = product_service.generatePrice(active_product, frequency, membership, units);
        },
        async setup_products() {
            this.product_settings.products = await this.get_products({
                product_template: this.treatment_product_template._id
            });
        },
        setup_active_product(products, filter) {
            // bring frequency check back, if they don't match, look only based on product duration
            this.product_settings.active_product = product_service.selectProduct(products, filter);
        },
        setup_active_membership(membership_prices, duration) {
            this.product_settings.membership = null;
            if (membership_prices) {
                this.product_settings.membership = membership_prices.find(membership => membership.recurring && membership.recurring.interval_count === duration.months);
            }
        },
        setup_dose_recommendation(product, frequency, user) {
            if (this.treatment_product_template && this.treatment_product_template.dose_recommendation) {
                this.product_settings.preferences = {
                    recommended: false,
                    custom: true
                }
                if ('dose_recommendation' in product) {
                    this.product_settings.preferences = {
                        recommended: true,
                        custom: false
                    }
                    this.product_settings.preferences.instructions = {
                        starter: this.parse_recommended_instructions(frequency, frequency.custom_quantity.default / 4),
                        recommended: this.parse_recommended_instructions(frequency, weightToDosage(user.weight, this.treatment_product_template.settings.max_quantity_recommendation)),
                    }
                }
            }

        },
        set_active_duration(duration) {
            const active_duration = this.product_settings.shipping_frequencies.find(d => d.months === duration.months );
            const selected = this.product_settings.shipping_frequencies.indexOf(active_duration);
            this.product_settings.shipping_frequencies.forEach((sf, index) => {
                sf.active = selected === index;
            });
            this.$forceUpdate();
        },
        set_active_membership(active_membership) {
            this.product_settings.active_membership_price = active_membership;
        },
        setup_durations(product_template, products, membership_prices) {
            this.product_settings.shipping_frequencies = product_template.shipping_frequencies.map((duration) => {
                let active_membership;
                let product = product_service.selectProduct(products, {
                    duration: duration.months,
                    frequency: this.product_settings.dosage_frequency
                })
                if (!product) {
                    product = product_service.selectProduct(products, {
                        duration: duration.months,
                        frequency: product_template.dosage_frequencies[0]
                    })
                }

                if (membership_prices) {
                    active_membership = membership_prices.find(membership => membership.recurring && membership.recurring.interval_count === duration.months);
                }
                this.set_active_membership(active_membership);
                const price = product_service.generatePrice(product, active_membership)
                duration.savings = (price.product_savings + price.membership_savings)
                return duration;
            });
        },
        setup_duration(product) {

            let main_product = this.main_duration;


            const shipping_product = main_product ? main_product : product;
            const defaultShipping = shipping_product.shipping_frequencies.findIndex(s => s.default);
            let shipping = defaultShipping !== -1 ? product.shipping_frequencies[defaultShipping] : product.shipping_frequencies[0];
            shipping.active = true;
            this.product_settings.duration = shipping;
        },
        save_treatment_configuration(product_id, product_settings) {
            this.save_treatment_settings({product: product_id, value: product_settings});
        },
        get_updated_pricing() {
            this.setup_active_product(this.product_settings.products, {
                duration: this.product_settings.duration.months,
                frequency: this.product_settings.dosage_frequency
            });
            this.setup_durations(this.treatment_product_template, this.product_settings.products, this.product_settings.membership_prices);
            this.setup_active_membership(this.product_settings.membership_prices, this.product_settings.duration);
            this.setup_pricing(this.product_settings.active_product, this.product_settings.dosage_frequency, this.product_settings.membership, this.product_settings.units);
            this.save_treatment_configuration(this.treatment_product_template._id, this.product_settings);
            this.save_product_configuration({
                units: this.product_settings.units,
                dosage_frequency: this.product_settings.dosage_frequency,
                shipping_frequencies: this.product_settings.shipping_frequencies,
                duration: this.product_settings.duration,
                dosage_strength: this.product_settings.dosage_strength,
                preference_option: this.product_settings.preference_option,
                main: this.product_settings.main,

            });
            let product = this.treatment_product_template;
            product['selected_configuration'] = {
                dosage: this.product_settings.dosage_strength,
                frequency: this.product_settings.dosage_frequency,
                id: product._id,
                membership: this.product_settings.membership,
                pricing: this.product_settings.pricing,
                quantity: this.product_settings.units,
                shipping: this.product_settings.duration,
                main: this.product_settings.main,
                treatment_properties: {
                    recommendation_requested: this.product_settings.recommendation_requested,
                    duration: this.product_settings.duration.months,
                    has_custom_quantity: this.product_settings.dosage_frequency.has_custom_quantity,
                    membership_price: this.product_settings.membership ? this.product_settings.membership._id : null,
                    product: this.product_settings.active_product._id,
                    product_template: this.treatment_product_template._id,
                    quantity: this.product_settings.units * this.product_settings.duration.months,
                    stripe_plan_id: this.product_settings.active_product.stripe_price_id,
                    comment: this.custom_comment || '',
                }
            }
            this.$emit('onConfiguredPlan', product);
            this.$forceUpdate();

        },
        async setup_default_treatment_configuration(product) {
            await this.setup_membership_prices()
            await this.setup_products();
            this.setup_main();
            this.setup_frequency();
            this.setup_durations(this.treatment_product_template, this.product_settings.products, this.product_settings.membership_prices);
            this.setup_duration(product);
            this.setup_dosage();
            this.setup_active_dosage(this.product_settings.dosage_strengths[0]);
            this.setup_shipping_frequencies();
            this.setup_dose_recommendation(product, this.product_settings.dosage_frequency, this.user);
            this.setup_units(product, this.product_settings.dosage_frequency, this.user, 'recommended');
            this.get_updated_pricing();
            this.$forceUpdate();
        },

        async setup_treatment(product_template_id) {
            this.treatment_product_template = await this.getProductTemplate({product_template_id: product_template_id});
            await this.setup_default_treatment_configuration(this.treatment_product_template);
            this.$forceUpdate();
        },
        update_durations(frequency) {
            let durations = this.treatment_durations[this.treatment_product_template._id];
            let product_items = this.treatment_product_properties[this.treatment_product_template._id];
            const units = frequency.has_custom_quantity ? frequency.custom_quantity.default : frequency.monthly_units;
            let updated_durations = durations.map(m => {
                let product = product_items.find(p =>
                    p.pricing.interval_count === m.months &&
                    p.plan_properties.dosage_frequency.label === frequency.frequency.label &&
                    p.plan_properties.dosage_frequency.count === frequency.frequency.count
                )
                let membership_price = null;

                if (this.product_settings.membership_prices) {
                    membership_price = this.product_settings.membership_prices.find(m.recurring.interval_count === m.months);
                }
                m.prices = product_service.generatePrice(product, frequency, membership_price, units);
                return m;
            });
            this.update_treatment_durations({
                product_id: this.treatment_product_template._id,
                data: updated_durations
            });
        },
        select_frequency(frequency) {
            analytics.track(analyticsActions.treatment_preferences_frequency, {
                frequency,
                intake_template: this.treatment_product_template.intake_template,
                medication_type: this.treatment_product_template.medication_type,
            });

            this.product_settings.dosage_frequency = frequency;
            this.setup_units(this.treatment_product_template, this.product_settings.dosage_frequency, this.user);
            this.update_durations(frequency);
            this.get_updated_pricing();
        },
        select_preference_item(option, isCustom) {
            analytics.track(analyticsActions.treatment_preferences_item, {
                preference_option: option,
                intake_template: this.treatment_product_template.intake_template,
                medication_type: this.treatment_product_template.medication_type,
            });
            let selected_option = option === 'custom-request' ? 'request' : option;
            this.product_settings.preference_option = selected_option;
            this.setup_units(this.treatment_product_template, this.product_settings.dosage_frequency, this.user, selected_option);
            if (option !== 'custom-request') {
                this.product_settings.preferences.custom = selected_option === 'custom';
            }

            if (isCustom !== undefined) {
                this.product_settings.recommendation_requested = isCustom;
            }

            this.get_updated_pricing();
        },
        select_quantity(units) {
            analytics.track(analyticsActions.treatment_preferences_quantity, {
                units,
                intake_template: this.treatment_product_template.intake_template,
                medication_type: this.treatment_product_template.medication_type,
            });

            this.product_settings.units = units;
            this.get_updated_pricing();
        },

        async selectedMedicationType(product) {
            this.treatment_product_template = await this.getProductTemplate({product_template_id: product._id});
            await this.setup_membership_prices()
            await this.setup_products();
            this.setup_shipping_frequencies();
            this.set_active_duration(this.product_settings.duration);
            this.get_updated_pricing();
            this.$forceUpdate();

            try {
                await this.$router.push({path: this.$route.fullPath, query: {product: product._id} });
            } catch (error) {
                if (error.name !== "NavigationDuplicated") {
                    throw error;
                }
            }
        },
        update_dosage(dosage_strength) {
            this.product_settings.dosage_strength = dosage_strength;
            this.get_updated_pricing();
            this.$forceUpdate();
        },
        parse_recommended_instructions(frequency, quantity) {
            const instructions = frequency.instructions;
            if (instructions.includes('${dosageWeekly}')) {
                return instructions.replace('${dosageWeekly}', quantity);
            } else {
                return instructions;
            }
        },
        get_preferences(product_id) {
            return product_id in this.treatment_preferences ? this.treatment_preferences[product_id] : false;
        },


        ...mapMutations({
            save_treatment_settings: 'treatment/saveTreatmentSettings',
            save_treatment_setting: 'treatment/setTreatmentProductSetting',
            save_product_configuration: 'treatment/saveConfigurationSettings',
            update_treatment_durations: 'treatment/updateTreatmentDuration'
        }),
        ...mapActions({
            getProductTemplate: 'products/getProductTemplate',
            saveCustomQuantity: 'products/saveCustomQuantity',
            getMembershipPrices: 'memberships/getMembershipPrices',
            get_products: 'products/getProducts',
            add_subscriber_tags: 'user/addSubscriberTags',
        })
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            intake_id: state => state.intake.intake_id,
            productTemplate: state => state.products.productTemplate,
            selectedIntake: state => state.intake.selectedIntakeTemplate,
            products: state => state.products.products,
            treatment_preferences: state => state.treatment.treatment_preferences,
            mailchimp_integration: state => state.integrations.mailchimp_exists.data,
            active_duration: state => state.treatment.active_treatment_duration,
            treatment_durations: state => state.treatment.treatment_durations,
            treatment_product_properties: state => state.treatment.treatment_product_properties
        }),

        product_features() {
            return this.product_data.product_features;
        }
    }
}

</script>

<style lang="scss">

#checkout-desc {
    height: inherit;
}

.checkout-title {
    font-family: 'ZP-UI', sans-serif;
    font-size: 1.5rem;
    text-transform: capitalize;

    @media (min-width: 760px) {
        font-size: 38px;
        margin-bottom: 0;
    }
}

.text-input-container-transparent {
    background-color: #FFFFFF;
    -webkit-box-shadow: rgb(0 0 0 / 5%) 0px 1px 4px;
    box-shadow: rgb(0 0 0 / 5%) 0px 1px 4px;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: #c3c7cb;

    .textfield-checkout {
        border: none;
        box-shadow: none;
        background-color: transparent;
        max-width: 100%;
    }
}


$color1: #f4f4f4;
$color2: $neutralBlack;

.hs-radio {
    margin: 0.5rem;

    input[type="radio"] {
        position: absolute;
        opacity: 0;

        + .hs-radio-label {
            &:before {
                content: '';
                background: $color1;
                border-radius: 100%;
                border: 1px solid darken($color1, 25%);
                display: inline-block;
                width: 1.4em;
                height: 1.4em;
                position: relative;
                top: 1px;
                margin-right: 1em;
                vertical-align: top;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
            }
        }

        &:checked {
            + .hs-radio-label {
                &:before {
                    background-color: $color2;
                    box-shadow: inset 0 0 0 4px $color1;
                }
            }
        }

        &:focus {
            + .hs-radio-label {
                &:before {
                    outline: none;
                    border-color: $color2;
                }
            }
        }

        &:disabled {
            + .hs-radio-label {
                &:before {
                    box-shadow: inset 0 0 0 4px $color1;
                    border-color: darken($color1, 25%);
                    background: darken($color1, 25%);
                }
            }
        }

        + .hs-radio-label {
            &:empty {
                &:before {
                    margin-right: 0;
                }
            }
        }
    }
}


</style>
