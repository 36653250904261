<template>
<div>
    <form @submit.prevent="continueExisting()" class="verification">
        <div class="identity__header">
            <h1>Identity Verification</h1>
            <p class="h-caption text-dim-60">*all documents are destroyed after account verification</p>
                <slot name="why" />
        </div>
            <VerifyCard
                type="id"
                :withAction="true"
                :user_img="images.id_url"
                title="Take or upload a valid ID"
                description="Used to check your personal information is correct"
                button_label="Select"
                @click.native="handleNext('id_image')"
                class="pointer"
                data-cypress="id-image"     
            >
            </VerifyCard>
            <VerifyCard
                type="selfie"
                :withAction="true"
                :user_img="images.profile_url"
                title="Take or upload a selfie"
                description="To match your face to your photo"
                button_label="Select"
                @click.native="handleNext('profile_image')"
                class="pointer"
                data-cypress="profile-image"
            >
            </VerifyCard>
        <h-button v-if="hasIdentity()" label="Continue" variant="primary" full_width large :loading="is_submitting" :disabled="!hasIdentity()" :handleClick="continueExisting"/>
    </form>

</div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import IntakeButton from '@/components/Consultation/SubmitButton.vue';
import VerifyCard from '@/components/Identification/SubComponents/VerifyCard.vue';
import HButton from '@/components/Shared/HButton.vue';

export default {
    name: "ExistingIdentity",
    props : {
        handleNext: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            submit_button_text: 'Continue',
            is_submitting: false,
            images: {
                id_url: null,
                profile_url: null
            }
        }
    },
    components: {
        IntakeButton: IntakeButton,
        VerifyCard,
        HButton
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        intake() {
            return this.$route.params && this.$route.params.intakeId;
        }
    },
    async created() {
        await this.$nextTick();
        this.images = await this.retrieveUserImages(this.user._id);       
    },
    methods: {
        ...mapActions({
            updateIntake: 'intake/updateIntake',
            retrieveUserImages: 'user/retrieveUserImage'
        }),
        hasIdentity() {
            return this.images.id_url && this.images.profile_url
        },
        async continueExisting() {
            this.is_submitting = true;     

            if ( this.$route.name === 'verification') {
                this.$router.push({ name: 'dashboard', params: { userId: this.user._id, page: 'profile' } })
                return;
            }
              
            try {
                if (this.intake && this.hasIdentity()) {
                    const update_fields = {
                        image_verification: {
                            profile_image_url: this.images.profile_url,
                            id_image_url: this.images.id_url
                        },
                    };
                    await this.updateIntake({
                        user_id: this.user._id,
                        intake_id: this.$route.params.intakeId,
                        update_fields: update_fields
                    })
                    this.handleCheckout();
                } 
            } catch (error) {
                console.log('ContinueWithExistingError', error);
                this.is_submitting = false;
            }
        },
        handleCheckout() {
            let params = this.$router.currentRoute.params;
            const defaultParams = {
                section: 'checkout',
                intakeId: params.intakeId
            };
            this.$router.push({
                name: 'intake-default-alt',
                params: defaultParams
            })
        },   
        retakeImage(photoType) {
            let params = this.$router.currentRoute.params;
            const identityParams = {
                section: 'identification-step',
                intakeId: params.intakeId,
                photoType: photoType
            }
            this.$router.push({
                name: 'identification-step-alt',
                params: identityParams
            }).catch(err => console.log(err));
        }     
    }
}
</script>
<style lang="scss">
</style>
