<template>
    <div class="profile__container">
        <div v-if="organization_data && organization_data.verification && organization_data.verification.has_mobile_verification && !user.mobile_verified" class="profile__alert">
            <mobile-verify-alert />
        </div>
    <div class="profile__alert" v-if="!incomplete_intakes.loading && incomplete_intakes.data && incomplete_intakes.data.length">
        <complete-visits :incomplete_visits="incomplete_intakes.data"></complete-visits>
    </div>
    <pending-visits></pending-visits>

    <div class="flex flex-row">
        <h4 class="headline-2 profile-spacing">Profile</h4>
    </div>
    <div class="flex dashboard-row-grid">
        <pharmacy-verification-alert v-if="hasControlledSubstances() && noSSN()"></pharmacy-verification-alert>
    </div>

    <div class="flex dashboard-row-grid">
        <profile-settings></profile-settings>
        <payment-settings></payment-settings>
    </div>

    <div class="flex dashboard-row-grid">
        <password-reset></password-reset>
        <shipping-address></shipping-address>
    </div>


</div>
</template>

<script>
import ProfileSettings from '@/components/Dashboard/ProfileSettings.vue'
import PaymentSettings from '@/components/Dashboard/PaymentSettings.vue';
import PasswordReset from '@/components/Dashboard/PasswordReset.vue';
import ShippingAddress from '@/components/Dashboard/ShippingAddress.vue';
import MobileVerifyAlert from "@/components/Dashboard/Subcomponents/MobileVerifyAlert";
import PharmacyVerificationAlert from '@/components/Dashboard/Subcomponents/PharmacyVerificationAlert.vue'
import {mapActions, mapMutations, mapState} from "vuex";
import CompleteVisits from "@/components/Visits/CompleteVisits";
import PendingVisits from "@/components/Visits/PendingVisits";

export default {
    data() {
        return {
            organization_data: null,
            show_verification: false
        }
    },
    components: {
        MobileVerifyAlert,
        PharmacyVerificationAlert,
        ProfileSettings: ProfileSettings,
        PaymentSettings: PaymentSettings,
        PasswordReset: PasswordReset,
        ShippingAddress: ShippingAddress,
        CompleteVisits: CompleteVisits,
        PendingVisits: PendingVisits,
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            mobileVerify: state => state.user.verify_number,
            alerts: state => state.alerts.alerts,
            organization: state => state.organization.data,
            incomplete_intakes: state => state.intake.incomplete_intakes,
        }),

    },
    async created() {
        this.set_incomplete_intake({id: 'data', value: null});
        try {
            await this.getOrganizationSettings();
            this.organization_data = await this.getOrganization();
        } catch (e) {
            console.log('Created error', e);
        }


        try {

            await this.getIncompleteIntakes({
                user: this.user._id
            });
            await this.getIntakes({
                user: this.user._id,
            });
        } catch (e) {
            console.log('Incomplete Intakes: error', e);
        }

    },
    methods: {
        ...mapActions({
            getOrganizationSettings: 'getOrganizationSettings',
            getOrganization: 'getOrganization',
            getIncompleteIntakes: 'intake/retrieveIncompleteIntakes',
            getIntakes: 'intake/retrievePatientIntakes',
        }),
        ...mapMutations({
            'set_incomplete_intake': 'intake/setDashboardIncompleteIntakes'
        }),
        hasControlledSubstances() {
            return this.organization && this.organization.verification && this.organization.verification.has_controlled_substances;
        },
        noSSN() {
            return this.user.user && !this.user.user.SSN;
        }
    }
}

</script>

<style lang="scss">

.profile-spacing {
    margin-left: 10px;
    @media(max-width: 760px) {
        margin-left: 0;
    }
}

.profile {
    &__alert {
        margin-bottom: 3rem;
    }

    @media (min-width: 1440px) {
        &__container {
            max-width: 1440px;
            margin: 0 auto;
        }
    }
}

</style>
