<template>
    <nav class="hsidenav" :class="{'hsidenav-new': newNav, 'hsidenav-alert': alertBar }">
        <div v-if="organizationSettings?.data" class="hsidenav-header">
            <div class="hsidenav-header__logo">
                <img v-if="organizationSettings.data.logo" :src="organizationSettings.data.logo" alt="logo">
                <img v-else-if="healthSpanLogo" :src="healthSpanLogo" alt="logo">
                <div v-else class="zp-company-no-logo">
                    {{ organizationSettings.data.name }}
                </div>
            </div>
        </div>

        <ul class="hsidenav-list">
            <li 
                v-for="nav in navData" 
                :key="nav.name" 
                class="hsidenav-list__li" 
            >
                <router-link :to="getLink(nav.name)" class="hsidenav-list__item" :class="{'hsidenav-list__active': isPageActive(nav.pages)}" :data-cypress="nav.name === 'Messages' && 'sidenav_messages'">
                    <component :is="iconMap[nav.name]" :color="getColor(nav.pages)" />
                    <div v-if="nav.name === 'Messages' && display_unread_notification" class="hsidenav-list__alert" />
                </router-link>
                <div class="hsidenav-list__tooltip">
                    {{ nav.name }}
                </div>
            </li>
            <li 
                class="hsidenav-list__item" 
                @mouseover="handleMoreTooltip('show')"
                @mouseleave="handleMoreTooltip('hide')"
            >
                <MoreIcon />
                <MoreMenu :open="moreOpen" :user-id="user._id" :links="organizationSettings?.data?.dropdown_links || []" />
            </li>
        </ul>
        <user-profile v-if="user" :user="user" />
    </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import ChevronBack from '@/assets/icons/ChevronBack.vue';
import ChevronRight from '@/assets/icons/ChevronRight.vue';
import AppointmentsIcon from '@/assets/icons/nav/Appointments.vue';
import HomeIcon from '@/assets/icons/nav/Home.vue';
import LabsIcon from '@/assets/icons/nav/Labs.vue';
import MessagesIcon from '@/assets/icons/nav/Messages.vue';
import MoreIcon from '@/assets/icons/nav/More.vue';
import OrdersIcon from '@/assets/icons/nav/Orders.vue';
import ShopIcon from '@/assets/icons/nav/Shop.vue';
import SubscriptionsIcon from '@/assets/icons/nav/Subscriptions.vue';
import healthSpanLogo from '@/assets/images/logo/healthspan_logo.png';

import MoreMenu from './Subcomponents/Sidenav/MoreMenu.vue';
import UserProfile from './Subcomponents/Sidenav/UserProfile.vue';

export default {
    name: 'SideNav',
    components: {
        ChevronBack,
        ChevronRight,
        HomeIcon,
        LabsIcon,
        OrdersIcon,
        SubscriptionsIcon,
        MessagesIcon,
        AppointmentsIcon,
        ShopIcon,
        MoreIcon,
        UserProfile,
        MoreMenu,
    },
    props: {
        page: {
            type: String,
            deefault: '',
        },
        user_id: {
            type: String,
            required: true,
        },
        display_unread_notification: {
            type: Boolean,
        },
        newNav: {
            type: Boolean,
        },
        alertBar: {
            type: Boolean,
        },
    },
    data() {
        return {
            active: true,
            moreOpen: false,
            tooltipVisible: null,
            navData: [
                {
                    name: 'Home',
                    pages: ['profile'],
                    route: '/',
                },
                {
                    name: 'Labs',
                    pages: ['labs', 'lab-analysis'],
                    route: '/',
                },
                {
                    name: 'Orders',
                    pages: ['orders'],
                    route: '/',
                },
                {
                    name: 'Subscriptions',
                    pages: ['subscriptions'],
                    route: '/',
                },
                {
                    name: 'Messages',
                    pages: ['messages'],
                    route: '/',
                },
                {
                    name: 'Appointments',
                    pages: ['appointments'],
                    route: '/',
                },
                {
                    name: 'Shop',
                    pages: ['shop'],
                    route: '/',
                },
            ],
            iconMap: {
                Home: HomeIcon,
                Labs: LabsIcon,
                Orders: OrdersIcon,
                Subscriptions: SubscriptionsIcon,
                Messages: MessagesIcon,
                Appointments: AppointmentsIcon,
                Shop: ShopIcon,
            },
            healthSpanLogo,
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            organizationSettings: state => state.organizationSettings,
        }),
    },
    mounted() {
        if (!this.organizationSettings?.data) {
            this.getOrganizationSettings();
        }
    },
    methods: {
        ...mapActions({
            getOrganizationSettings: 'getOrganizationSettings',
        }),
        isPageActive(pages) {
            return pages?.includes(this.page);
        },
        getLink(name) {
            const path = name === 'Home' ? 'profile' : name.toLowerCase();
            const link = name === 'Shop' ? '/shop' : `/dashboard/${this.user_id}/${path}`;

            return link;
        },
        getColor(name) {
            const active = name.includes(this.page);

            return active ? '#fff' : '#212931';
        },
        handleMoreTooltip(action) {
            this.moreOpen = action === 'show';
        },
    },
};
</script>

