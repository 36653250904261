<template>
    <div class="settings">
        <div v-if="isProfileSection" class="typography-header settings-header settings-mobile">
            <h1>Settings</h1>
            <p>Manage your account settings and preferences.</p>
        </div>
        <div class="typography-header settings-header settings-desktop">
            <h1>Settings</h1>
            <p>Manage your account settings and preferences.</p>
        </div>
        <div class="settings-main">
            <h-tabs :menu="tabs" :active="activeSection" class="settings-desktop" @click="goToSection" />
            <div class="settings-mobile">
                <template v-if="isProfileSection">
                    <div v-for="component in components" :key="component.name" class="settings-menu" @click="goToSection(component.name)">
                        <div class="settings-menu__header">
                            <h2>{{ component.title }}</h2>
                            <p>{{ component.description }}</p>
                        </div>
                        <ChevronRightIcon />
                    </div>
                </template>
                <template v-else>
                    <component :is="currentSection" />
                </template>
            </div>
            <div v-if="isProfileSection" class="settings-desktop">
                <component :is="item.component" v-for="item in profiles" :key="item.name" />
            </div>
            <shipping v-if="section === 'shipping'" />
            <billing v-if="section === 'billing'" />
            <Notifications v-if="section === 'notifications'" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import ChevronRightIcon from '@/assets/icons/ChevronRight.vue';
import HTabs from '@/components/Shared/HTabs.vue';

import PersonalInfo from './Settings/PersonalInfo.vue';
import HomeState from './Settings/HomeState.vue';
import Password from './Settings/Password.vue';
import ProfileImage from './Settings/ProfileImage.vue';
import Billing from './Settings/Billing.vue';
import EmailAddress from './Settings/EmailAddress.vue';
import Shipping from './Settings/Shipping.vue';
import MobileNumber from './Settings/MobileNumber.vue';
import Notifications from './Settings/Notifications';

export default {
    name: 'SettingsPage',
    components: {
        HTabs,
        ChevronRightIcon,
        PersonalInfo,
        HomeState,
        Password,
        ProfileImage,
        Billing,
        EmailAddress,
        Shipping,
        Notifications,
        MobileNumber,
    },
    data() {
        return {
            //TODO: Add more sections
            components: [
                { page: 'profile', name: 'info', title: 'Personal Information', description: 'Update your information', component: 'PersonalInfo', active: true },
                { page: 'profile', name: 'email', title: 'Email Address', description: 'Update your email address', component: 'EmailAddress', active: false },
                { page: 'profile', name: 'password', title: 'Password', description: 'Manage your security preferences', component: 'Password', active: false },
                { page: 'profile', name: 'mobile', title: 'Mobile Number', description: 'Update your mobile number', component: 'MobileNumber', active: false },
                { page: 'profile', name: 'state', title: 'Home State', description: 'The state you currently reside in or on your drivers license or valid ID', component: 'HomeState', active: false },
                { page: 'profile', name: 'image', title: 'Profile Image', description: 'An image of your face', component: 'ProfileImage', active: false },
                { page: 'shipping', name: 'shipping', title: 'Shipping Address', description: 'Manage your shipping address used to ship your treatments.', component: 'ShippingAddress', active: false },
                { page: 'billing', name: 'billing', title: 'Payment Information', description: 'Manage and update your payment information used for purchases on your account.', component: 'Billing', active: false },
                { page: 'notifications', name: 'notifications', title: 'Manage', description: 'Configure where you would like to receive notifications.', component: 'Notifications', active: false },
            ],
            tabs: [
                { name: 'Profile', section: 'profile' },
                { name: 'Shipping', section: 'shipping' },
                { name: 'Billing', section: 'billing' },
                { name: 'Notifications', section: 'notifications' },
            ],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
        }),
        section() {
            return this.$route.params.id;
        },
        currentSection() {
            const component = this.profiles.find(c => c.name === this.section);

            return component ? component.component : null;
        },
        activeSection() {
            return this.section || 'profile';
        },
        profiles() {
            return this.components.filter(component => component.page === 'profile');
        },
        isProfileSection() {
            return this.section === 'profile' || !this.section;
        },
    },
    methods: {
        goToSection(sectionName) {
            this.$router.push(`/dashboard/${this.user._id}/settings/${sectionName}`);
        },
    },
};
</script>
