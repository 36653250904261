import Vue from "vue";
import router from '@/router.js'
import axios from 'axios'

const baseUrl = process.env.VUE_APP_BASE_END;

export const intake = {
    // This makes your getters, mutations, and actions accessed by, eg: 'myModule/myModularizedNumber' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,
    state: {
        intake_object: {},
        intake_id: null,
        product_category: null,
        selected_plan_name: null,
        selected_plan: {},
        selected_service_plan: null,
        intake_instance_response: {
            loading: false,
            data: null
        },
        open_intakes: [],
        patient_intakes: {
            data: null,
            loading: false,
            error: false
        },
        productIntake: {
            loading: false,
            data: null
        },
        globalIntake: {
            loading: false,
            data: null
        },
        selectedIntakeTemplate: {
            loading: false,
            data: null
        },
        currentIntakeInstance: {
            loading: false,
            data: null
        },
        signupIntakeTemplateId: null,
        completedIntake: {
            saved: false,
            loading: false
        },
        intakeExpiration: {
            loading: false,
            saved: false
        },
        incomplete_intakes: {
            loading: false,
            data: null,
            error: {
                response: null,
                is_err: false
            }
        },
        intake_products: {},
        product_intake: {}
    },
    getters: {
        getServicePlanId: state => {
            let service_plan_id = state.selected_service_plan._id || null;
            return service_plan_id;
        },
    },
    mutations: {
        setIntakeInstanceResponse(state, response) {
            state.intake_instance_response.data = response;
        },
        setIntakeInstanceResponseLoading(state, bool_val) {
            state.intake_instance_response.loading = bool_val
        },
        setIntakeMedicalReviewPayment(state) {
            state.currentIntakeInstance.data.medical_review_payment = true;
            localStorage.setItem('z_intake', JSON.stringify(state.currentIntakeInstance.data));
        },
        setProductCategory(state, product_category) {
            state.product_category = product_category;
            localStorage.setItem('hs_selected_product_category', JSON.stringify(product_category));
        },
        setPlanObject(state, plan_object) {
            state.selected_plan = plan_object;
            localStorage.setItem('hs_selected_plan', JSON.stringify(plan_object))
        },
        setServicePlanObject(state, service_plan_obj) {
            state.selected_service_plan = service_plan_obj;
            localStorage.setItem('hs_selected_service_obj', JSON.stringify(service_plan_obj));
        },
        setIntakeId(state, intake_id) {
            localStorage.setItem('hs_intake_id', JSON.stringify(intake_id));
            state.intake_id = intake_id;
        },
        setSelectedPlanName(state, selected_plan) {
            state.selected_plan_name = selected_plan;
        },
        returnChildResponses(state, selected_response_conditions) {
            if (selected_response_conditions) {
                let child_responses = selected_response_conditions.map((response_condition) => {
                    let new_response = {
                        is_free_format: true,
                        free_format_response: response_condition.free_format_response,
                        question: response_condition.question._id
                    }
                    return new_response;
                })
                return child_responses;
            } else {
                return []
            }
        },
        initializeIntakeId(state) {
            if (localStorage.getItem('z_intake')) {
                state.currentIntakeInstance.data = JSON.parse(localStorage.getItem('z_intake'));
            }
            if (localStorage.getItem('hs_intake_id')) {
                state.intake_id = JSON.parse(localStorage.getItem('hs_intake_id'))
            }
            if (localStorage.getItem('hs_selected_plan')) {
                state.selected_plan = JSON.parse(localStorage.getItem('hs_selected_plan'));
            }
            if (localStorage.getItem('hs_selected_service_obj')) {
                state.selected_service_plan = JSON.parse(localStorage.getItem('hs_selected_service_obj'));
            }
            if (localStorage.getItem('hs_selected_product_category')) {
                state.product_category = JSON.parse(localStorage.getItem('hs_selected_product_category'));
            }
        },
        setStep(state, id) {
            const arrayIndex = state.currentIntakeInstance.data.intake_responses.findIndex((obj) => {
                return obj._id === id;
            });

            // FIXME(NavigationDuplicated): fix routing for steps
            router.push({ query: { step: arrayIndex } }).catch(error => {
                if (error.name != "NavigationDuplicated") {
                  throw error;
                }
              });
        },
        routerPush(state, step) {
            const newStep = (Number(step) + 1).toString();
            const signupConfig = JSON.parse(window.localStorage.getItem('signup-config'));
            let params = router.currentRoute.params;

            if (params.section === 'intake') {
                if (newStep < state.currentIntakeInstance.data.intake_responses.length) {
                    const newResponse = state.currentIntakeInstance.data.intake_responses[newStep];

                    params['questionId'] = newResponse._id;
                    // FIXME: NavigationDuplicated
                    router.push({ name: 'intake-wrapper', params, query: { step: newStep } }).catch(error => {
                        if (error.name != "NavigationDuplicated") {
                          throw error;
                        }
                      });
                } else {
                    window.localStorage.setItem('intake_completed', 'true');

                    if (signupConfig.membership.hasMembership) {
                        const membershipParams = {
                            section: 'membership',
                            intakeId: params.intakeId,
                            membershipId: state.selectedIntakeTemplate.data.plan_checkout_options.membership_options[0]

                        }
                        router.push({
                            name: 'membership-signup',
                            params: membershipParams
                        }).catch(err => console.error(err));
                    } else if (signupConfig.product.main_products.length) {

                        const checkoutParams = {
                            section: 'product-configure',
                            intakeId: params.intakeId,
                        }

                        router.push({
                            name: 'treatment-checkout',
                            params: checkoutParams,
                            query: {
                                product: signupConfig.product.main_products[0],
                            }
                        }).catch(err => console.error(err));
                    } else if (signupConfig.lab.product) {
                        const labCheckoutParams = {
                            section: 'lab-checkout',
                            intakeId: params.intakeId,
                            labProductId: signupConfig.lab.product,
                        }

                        router.push({ name: 'lab-checkout', params: labCheckoutParams }).catch(err => console.error(err));
                    } else if (!signupConfig.product.main_products.length) {
                        window.localStorage.setItem('no-product', 'true');
                        const identityParams = {
                            section: 'identification-step',
                            intakeId: params.intakeId,
                            photoType: 'id_image',
                        }
                        router.push({
                            name: 'identification-step-alt',
                            params: identityParams,
                        }).catch(err => console.log(err));
                    } else {
                        const treatmentParams = {
                            section: 'treatment',
                            intakeId: params.intakeId
                        }
                        router.push({
                            name: 'treatment-selection',
                            params: treatmentParams
                        }).catch(err => console.log(err));
                    }


                }
            }
        },
        routerPushBack(state, payload) {
            const { step = null, gender = null } = payload || {};
            const intake_template = state.selectedIntakeTemplate.data;

            const params = router.currentRoute.params;

            if (params.section === 'intake') {
                const newStep = (Number(step) - 1);

                if (step === 0) {
                    const modifiedParams = { ...params };

                    modifiedParams['questionId'] = 'none';
                    modifiedParams['section'] = 'pharmacy-overview';

                    router.push({ name: 'intake-wrapper', params: modifiedParams }).catch((err) => {
                        console.error(err);
                    });
                } else {
                    const newResponse = state.currentIntakeInstance.data.intake_responses[newStep];
                    const newResponseId = newResponse._id;

                    params['questionId'] = newResponseId;
                    router.push({ name: 'intake-wrapper', params, query: { step: newStep } }).catch((err) => {
                        console.error(err);
                    });
                }
            }

            if (params.section === 'pharmacy-overview') {
                params['questionId'] = 'none';
                params['section'] = 'body';
                router.push({ name: 'intake-wrapper', params }).catch((err) => {
                    console.error(err);
                });
            }

            if (params.section === 'pharmacy-questions') {
                const currentStep = Number(step);

                if (currentStep === 1 && gender === 'male' || currentStep === 0) {
                    params['section'] = 'profile';
                    params['questionId'] = 'none';
                    router.push({ name: 'intake-wrapper', params }).catch((err) => {
                        console.error(err);
                    });
                } else {
                    const newStep = currentStep - 1;

                    params['questionId'] = 'current';
                    router.push({ name: 'intake-wrapper', params, query: { step: newStep } }).catch((err) => {
                        console.error(err);
                    });
                }
            }

            if (params.section === 'body') {
                params['section'] = 'profile';
                router.push({name: 'intake-wrapper', params: params}).catch((err) => {
                    console.log(err)
                })
            }

            if (params.section === 'treatment') { //go back to intake
                params['section'] = 'product-configure';
                router.push({params: params, name: 'treatment-selection'}).catch((err) => {
                    console.log(err)
                });
            }

            if (params.section === 'product-configure') {
                const intake_template = JSON.parse(window.localStorage.getItem('intake_template'));
                const newResponse = state.currentIntakeInstance.data.intake_responses.at(-1);
                const newResponseId = newResponse._id;
                const step = state.currentIntakeInstance.data.intake_responses.length;
                const newStep = step - 1;
                const newPath = '/consultation/intake/' + state.currentIntakeInstance.data.user._id + '/' + state.currentIntakeInstance.data._id + '/question/' + newResponseId;

                router.push({ path: newPath, query: { step: newStep } }).catch((err) => {
                    console.error(err);
                })

                if (!intake_template.bundle_support && intake_template.product_defaults.length > 1) {
                    router.push({path: `/consultation/alternative-products/${state.currentIntakeInstance.data._id}`}).catch((err) => {
                        console.log(err)
                    })
                } else {
                    const newResponse = state.currentIntakeInstance.data.intake_responses.at(-1);
                    const newResponseId = newResponse._id;
                    const step = state.currentIntakeInstance.data.intake_responses.length;
                    const newStep = step - 1;
                    const newPath = '/consultation/intake/' + state.currentIntakeInstance.data.user._id + '/' + state.currentIntakeInstance.data._id + '/question/' + newResponseId;

                    router.push({ path: newPath, query: { step: newStep } }).catch((err) => {
                        console.error(err);
                    });
                }
            }

            if (params.section === 'summary') {
                if (intake_template.product_addons.length) {
                    return router.push({path: `/consultation/treatment/${state.currentIntakeInstance.data._id}`}).catch((err) => {
                        console.log(err)
                    });
                } else {
                    return router.push({
                        path: `/consultation/product-configure/${state.currentIntakeInstance.data._id}`,
                        query: {
                            product: intake_template.product_defaults[0]
                        }
                    }).catch((err) => {
                        console.log(err)
                    });
                }
            }

            if (params.section === 'shipping') {
                params['section'] = 'summary';
                return router.push({params: params}).catch((err) => {
                    console.log(err)
                });
            }

            if (params.section === 'identification-step') {
                const photoType = params.photoType;
                if (photoType === 'verify_account') {
                    // eslint-disable-next-line camelcase
                    if (intake_template.product_addons.length) {
                        return router.push({ path: `/consultation/treatment/${state.currentIntakeInstance.data._id}` }).catch((err) => {
                            throw err;
                        });
                    } else {
                        return router.push({
                            path: `/consultation/product-configure/${state.currentIntakeInstance.data._id}`,
                            query: {
                                // eslint-disable-next-line camelcase
                                product: intake_template.product_defaults[0],
                            },
                        }).catch((err) => {
                            throw err;
                        });
                    }
                } else {
                    params['section'] = 'identification-step';
                    params['photoType'] = 'verify_account';
                    return router.push({params: params}).catch((err) => {
                        throw err;
                    });
                }
            }

            if (params.section === 'checkout') {
                params['section'] = 'identification-step';
                params['photoType'] = 'verify_account';
                return router.push({params: params}).catch((err) => {
                    throw err;
                });
            }

        },
        setGlobalIntakeData(state, data) {
            state.globalIntake.data = data;
        },
        setGlobalIntakeLoading(state, loading) {
            state.globalIntake.loading = loading;
        },
        setSelectedIntakeTemplateLoading(state, loading) {
            state.selectedIntakeTemplate.loading = loading;
        },
        setSelectedIntakeTemplateData(state, data) {
            state.selectedIntakeTemplate.data = data;
        },
        setSignupIntakeTemplateId(state, id) {
            state.signupIntakeTemplateId = id;
        },
        setCurrentIntakeInstanceData(state, data) {
            state.currentIntakeInstance.data = data;
            localStorage.setItem('z_intake', JSON.stringify(data));
        },
        setCurrentIntakeInstanceLoading(state, loading) {
            state.currentIntakeInstance.loading = loading;
        },
        setCompletedIntakeSave(state, data) {
            state.completedIntake.saved = data;
        },
        setCompletedIntakeLoading(state, loading) {
            state.completedIntake.loading = loading;
        },
        setIntakeExpirationLoading(state, loading) {
            state.intakeExpiration.loading = loading;
        },
        setIntakeExpirationSaved(state, saved) {
            state.intakeExpiration.saved = saved;
        },
        setDashboardIncompleteIntakes(state, payload) {
            Vue.set(state.incomplete_intakes, payload.id, payload.value);
        },
        setPatientIntakes(state, payload) {
            Vue.set(state.patient_intakes, payload.id, payload.value);
        },
        setIncompleteIntakeErr(state, err) {
            state.incomplete_intakes.error.response = err;
            state.incomplete_intakes.error.is_err = true;
        },
        setProductIntake(state, payload) {
            Vue.set(state.product_intake, payload.id, payload.value);
        }
    },
    actions: {
        createIntakeInstance({commit, getters, state, rootState}, payload) {
            let orgId = rootState.orgId.data;
            commit('setCurrentIntakeInstanceLoading', true);
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: baseUrl + '/users/' + orgId + '/user/' + payload.userId + '/template/' + payload.templateId,
                    data: payload
                }).then((response) => {
                    let intake = response.data;
                    commit('setIntakeId', intake._id);
                    resolve(intake);
                    commit('setCurrentIntakeInstanceLoading', false);
                }).catch((err) => {
                    reject(err);
                    commit('setCurrentIntakeInstanceLoading', false);
                })
            })
        },
        async addIntakeProducts({ rootState }, payload) {
            const orgId = rootState.orgId.data;

            return axios.post(baseUrl + `/v2/intakes/${payload.intakeId}/products`,
                { products: payload.products },
                { headers: { 'zenpatient-organization': orgId },
                });
        },
        async removeIntakeProducts({ rootState }, payload) {
            const orgId = rootState.orgId.data;

            return axios.delete(baseUrl + `/v2/intakes/${payload.intakeId}/products`, {
                headers: { 'zenpatient-organization': orgId },
                data: { products: payload.products },
            });
        },
        //for adding products
        async updateIntakeProducts({commit, dispatch, state, rootState}, payload) {
            let products = payload.selectedProducts.map(product => product.selected_configuration.treatment_properties);
            await dispatch('retrieveIntake', {
                _id: state.intake_id,
                user: payload.patient_id
            })
            return await dispatch('addIntakeProducts', {
                intakeId: state.intake_id,
                products,
            });
        },
        async removeAddonProducts({ dispatch, state, rootState }, payload) {
            let products = payload.selectedProducts.map(product => product.selected_configuration.treatment_properties);
            const orgId = rootState.orgId.data;

            let data = {
                products,
                intakeId: state.intake_id,
            };

            await dispatch('removeIntakeProducts', data);
        },
        updateIntake({commit, getters, state, rootState}, payload) {
            const request_body = payload.update_fields;
            const orgId = rootState.orgId.data;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: baseUrl + '/intakes/' + orgId + '/patient/' + payload.user_id + '/intake/' + payload.intake_id,
                    data: request_body
                }).then((response) => {
                    resolve(response.data);
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        updateIntakeResponse({commit, getters, state, rootState}, payload) {
            const req_payload = {response: payload.response}
            let orgId = rootState.orgId.data;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: baseUrl + '/intakes/' + orgId + '/patient/' + payload.user_id + '/intake/' + payload.intake_id + '/response/' + payload.response_id,
                    data: req_payload
                }).then((saved_response) => {
                    resolve(saved_response.data);
                }).catch((err) => {
                    console.log(err)
                    reject(err);
                })
            })
        },
        uploadProfileImage({commit, getters, state, rootState}, formData) {
            let orgId = rootState.orgId.data;
            return new Promise((resolve, reject) => {
                axios.post(baseUrl + '/patient-identification/' + orgId,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    if (axios.isAxiosError(error)) {
                        reject(error.response.data);
                    } else {
                        reject(error);
                    }
                })
            })
        },
        async completeIntakeV2({ rootState }, payload) {
            return axios.post(baseUrl + '/v2/intakes/' + payload.intake + '/complete',
                { },
                { headers: { 'zenpatient-organization': rootState.orgId.data } });
        },
        retrieveIntake({ commit, rootState }, payload) {
            let intake_id = payload._id;
            let patient_id = payload.user;
            let orgId = rootState.orgId.data;
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/intakes/' + orgId + '/patient/' + patient_id + '/patient-intake/' + intake_id,
                    method: 'GET'
                }).then(response => {
                    commit('setCurrentIntakeInstanceData', response.data);
                    resolve(response.data);
                }).catch((err) => {
                    reject(err.response?.data);
                })
            })
        },
        async retrieveProductIntake({commit, getters, state, rootState}, payload) {
            const { product_template_id } = payload;
            commit('setProductIntake', { id: 'error', value: false });
            commit('setProductIntake', { id: 'loading', value: true });

            try {
                let response = await axios.get(`${baseUrl}/intake-template/${rootState.orgId.data}/product/${product_template_id}`);
                if ('data' in response) {
                    commit('setProductIntake', { id: 'loading', value: false });
                    commit('setProductIntake', { id: 'data', value: response.data });
                    return response.data;
                }
            } catch (error) {
                commit('setProductIntake', { id: 'loading', value: false });
                commit('setProductIntake', { id: 'error', value: true });
                commit('setProductIntake', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setProductIntake', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },
        retrieveIncompleteIntakes({commit, getters, state, rootState}, payload) {

            const patient_id = payload.user;
            const organization = rootState.orgId.data;
            commit('setDashboardIncompleteIntakes', {id: 'loading', value: true});

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/intakes/' + organization + '/patient/' + patient_id + '/incomplete',
                    method: 'GET',
                }).then((response) => {
                    commit('setDashboardIncompleteIntakes', {id: 'loading', value: false});
                    commit('setDashboardIncompleteIntakes', {id: 'data', value: response.data});
                    resolve(response.data);
                }).catch((err) => {
                    commit('setDashboardIncompleteIntakes', {id: 'loading', value: false});
                    commit('setIncompleteIntakeErr', err.error);
                    reject(err.error)
                })
            })
        },
        patientClosesIntake({commit, getters, state, rootState}, payload) {

            const patient = payload.user;
            const organization = rootState.orgId.data;
            const intake = payload.intake;

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/intakes/' + organization + '/patient/' + patient + '/patient-intake/' + intake + '/patient-close',
                    method: 'PUT',
                    data: payload
                }).then((response) => {
                    resolve(response.data);
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        getGlobalIntake({commit, getters, state, rootState}, payload) {
            commit('setGlobalIntakeLoading', true);
            const {organization} = payload;
            const orgId = rootState.orgId.data;
            let organizationId = orgId ? orgId : organization;
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/intake-template/' + orgId + '/global',
                    method: 'GET',
                }).then(response => {
                    commit('setGlobalIntakeData', response.data);
                    commit('setSelectedIntakeTemplateData', response.data);
                    resolve(response.data);
                    commit('setGlobalIntakeLoading', false);
                }).catch(err => {
                    reject(err);
                    commit('setGlobalIntakeLoading', false);
                })
            })
        },
        getIntakeTemplate({commit, getters, state, rootState}, payload) {
            const orgId = rootState.orgId.data;
            commit('setSelectedIntakeTemplateLoading', true);
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/intake-template/' + orgId + '/template/' + payload.templateId,
                    method: 'GET'
                }).then(response => {
                    commit('setSelectedIntakeTemplateData', response.data);
                    window.localStorage.setItem('intake_template', JSON.stringify(response.data));
                    resolve(response.data);
                    commit('setSelectedIntakeTemplateLoading', false);
                }).catch(err => {
                    reject(err);
                    commit('setSelectedIntakeTemplateLoading', false);
                })
            })
        },
        async retrievePatientIntakes({commit, rootState, state, context}, payload) {
            const {user, query} = payload;
            const organization = rootState.orgId.data
            commit('setPatientIntakes', {id: 'loading', value: true});
            commit('setPatientIntakes', {id: 'error', value: false});
            try {
                let intake_response = await axios.get(`${baseUrl}/intakes/${organization}/patient/${user}`,
                    {
                        params: query
                    });
                commit('setPatientIntakes', {id: 'loading', value: false});
                commit('setPatientIntakes', {id: 'data', value: intake_response.data})
                return intake_response.data;
            } catch (error) {
                commit('setPatientIntakes', {id: 'loading', value: false});
                commit('setPatientIntakes', {id: 'data', value: null})

                if (axios.isAxiosError(error)) {
                    commit('setPatientIntakes', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }
                commit('setPatientIntakes', { id: 'error', value: error });
                throw error;
            }
        },
        setChildResponseByQuestionId({commit, getters, state, rootState}, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/intakes/' + payload.organization + '/patient/' + payload.user_id + '/intake/' + payload.intake_id + '/question/' + payload.parent_response_id + '/response/child/update',
                    method: 'PUT',
                    data: payload
                }).then((response) => {
                    resolve(response.data);
                }).catch((err) => {
                    console.log(err);
                    reject(err);
                })
            })
        },
        retrieveIntakeResponseById({commit, getters, state, rootState}, payload) {
            commit('setIntakeInstanceResponseLoading', true);
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/intakes/' + payload.orgId + '/patient/' + payload.userId + '/intake/' + payload.intakeId + '/response/' + payload.questionId,
                    method: 'GET'
                }).then((response) => {
                    commit('setIntakeInstanceResponse', response.data);
                    commit('setIntakeInstanceResponseLoading', false);
                    resolve(response.data);
                }).catch((err) => {
                    commit('setIntakeInstanceResponseLoading', false);
                    reject(err);
                })
            })
        },
        saveSelectedIntakeTemplate({commit, getters, state}, payload) {
            commit('setSelectedIntakeTemplateData', payload);
        },
        saveIntakeTemplateId({commit, getters, state}, payload) {
            commit('setSignupIntakeTemplateId', payload);
        },
        addCompletedIntake({ commit, rootState }, payload) {
            const orgId = rootState.orgId.data;

            commit('setCompletedIntakeLoading', true);

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/users/' + orgId + '/user/' + payload.userId + '/add-completed-intake',
                    method: 'POST',
                    data: payload.data,
                }).then(response => {
                    commit('setCompletedIntakeSave', true);
                    resolve(response.data);
                    commit('setCompletedIntakeLoading', false);
                }).catch(err => {
                    reject(err);
                    commit('setCompletedIntakeLoading', false);
                });
            });
        },
        addIntakeExpiration({ commit, rootState }, payload) {
            const orgId = rootState.orgId.data;

            commit('setIntakeExpirationLoading', true);

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/intakes/' + orgId + '/patient/' + payload.patient_id + '/intake/' + payload.intake_id + '/set-expiration',
                    method: 'PUT',
                    data: payload.data,
                }).then(response => {
                    resolve(response.data);
                    commit('setIntakeExpirationLoading', false);
                    commit('setIntakeExpirationSaved', true);
                }).catch(err => {
                    reject(err);
                    commit('setIntakeExpirationLoading', false);
                    commit('setIntakeExpirationSaved', false);
                });
            });
        },
    }
}
