<template> 
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.75 4.75L13.25 10L7.75 15.25"
            :stroke="stroke"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'ChevronRight',
    props: {
        stroke: {
            type: String,
            default: '#242E49',
        },
        width: {
            type: Number,
            default: 20,
        },
        height: {
            type: Number,
            default: 20,
        },
    },
};
</script>
