<template>
    <div class="product-nav__wrapper">
        <div class="product-nav__logo-container">
            <div class="product-nav__back" @click="backPage()">
                <ChevronBackIcon />
            </div>
            <div class="product-nav__logo" :style="{'background-image': `url(${organizationSettings.logo})`}"></div>
        </div>
        <p class="product-nav__step">
            <span v-if="section === 'product-plan'">Protocol Preference</span>
            <span v-if="section === 'checkout'">Checkout</span>
        </p>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import ChevronBackIcon from '@/assets/icons/ChevronBackIcon.vue';

export default {
    name: 'ProductNavigation',
    components: { ChevronBackIcon },
    methods: {
        backPage() {
            if (this.previousSection) {
                this.$router.push({ path: `/signup/${this.previousSection}/${this.$route.params.productTemplateId}` });
            } else {
                this.$router.push({ path: `/dashboard/${this.user._id}/profile` });
            }
        },
    },
    computed: {
        section() {
            return this.$route.params.section;
        },
        previousSection() {
            return this.section === 'checkout' ? 'product-plan' : null;
        },
        ...mapState({
            user: (state) => state.user.user,
            organizationSettings: state => state.organizationSettings.data,
        }),
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/utility.scss';

.product-nav {
    &__wrapper {
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
        align-items: center;
        background: #fff;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05) !important;
        border-bottom: 0.0625rem solid rgb(224, 224, 224);
        height: 72px;
        z-index: $z-nav;
    }

    &__logo-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        height: 70px;
        position: absolute;
        left: 15px;
        z-index: $z-nav;
    }

    &__back {
        display: flex;
        width: 25px;
        height: 25px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__logo {
        width: 150px;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: 590px) {
            display: none;
        }
    }

    &__step {
        width: 100%;
        text-align: center;
        margin: auto;
        font-family: "Sofia Semi Bold", sans-serif;
        font-size: 0.875rem;
        font-weight: 700;
        position: absolute;
    }
}
</style>
