<template>
    <div class="settings-section">
        <div class="settings-alert">
            <transition name="alert-fade" mode="out-in">
                <HAlert v-if="alert.status" :message="alert.message" :status="alert.status" @dismiss="dismissAlert" />
            </transition>
        </div>
        <div class="settings-section__return settings-mobile" @click="handleReturn">
            Return to Settings
        </div>
        <div class="settings-section__header">
            <h2>Payment Information</h2>
            <p>Manage and update your payment information used for purchases on your account.</p>
        </div>
        <form class="settings-billing" @submit.prevent="updateBilling()">
            <HFSABadge />
            <div class="checkout-existing checkout-existing__payment">
                <payment-option
                    ref="elementRef"
                    :has_card="hasCard"
                    :patient-card="patientCard"
                    :payment-error="paymentError"
                    @token="saveCard"
                    @event="cardChange"
                    @edit-card="cardForm ? cancelNewCard() : enterNewCard()"
                />
            </div>
            <h-button
                v-if="(cardForm && validNewCard) || (validNewCard && !hasCard)"
                label="Submit"
                variant="primary"
                :loading="submitting"
                class="settings-section__btn"
            />
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import PaymentOption from '@/components/Checkout/PaymentOption.vue';
import HAlert from '@/components/Shared/HAlert.vue';
import HButton from '@/components/Shared/HButton.vue';
import HFSABadge from '@/components/Shared/HFSABadge.vue';
import { hasStripeProfile } from '@/domains/user/lib/checkUserVerification.js';
import Alert from '@/helpers/alert';

export default {
    name: 'BillingSection',
    components: {
        HFSABadge,
        PaymentOption,
        HButton,
        HAlert,
    },
    data() {
        return {
            hasStripeAccount: false,
            submitting: false,
            hasCard: false,
            cardForm: false,
            newCard: false,
            validNewCard: false,
            alert: new Alert(),
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            patientCard: state => state.payment.patientCard.data,
            paymentError: state => state.payment.error,
        }),
    },
    async created() {
        this.$nextTick(async () => {
            this.hasStripeAcount = hasStripeProfile(this.user);

            if ('stripe_profile' in this.user) {
                this.getPatientCard({ user_id: this.user._id });
                this.hasCard = true;
            }
        });
    },
    methods: {
        handleReturn() {
            this.$router.push(`/dashboard/${this.user._id}/settings`);
        },
        dismissAlert() {
            this.alert.dismiss();
        },
        cancelNewCard() {
            this.newCard = false;
            this.hasCard = true;
            this.cardForm = false;
            this.paymentError.error = false;
            this.$refs.elementRef.getStripeRef().clear();
        },
        enterNewCard() {
            this.newCard = true;
            this.cardForm = true;
            this.hasCard = false;
            this.validNewCard = false;
        },
        cardChange(event) {
            this.validNewCard = Boolean(event.complete);

            if (event.error !== undefined) {
                this.paymentError.error = true;
                this.paymentError.error_msg = event.error.message;
            } else {
                this.paymentError.error = false;
            }
        },
        async saveCard(token) {
            try {
                if (this.hasStripeAcount) {
                    await this.updateCreditCard({
                        token,
                        charge: false,
                        new_user: false,
                        charge_amount: null,
                        user: this.user,
                    });
                } else {
                    const stripePayload = {
                        organization: this.user.organization,
                        user_id: this.user._id,
                        token,
                    };

                    await this.createBillingProfile(stripePayload);
                }
                await this.getPatientCard({ user_id: this.user._id });
                this.newCard = false;
                this.hasCard = true;
                this.cardForm = false;
                this.paymentError.error = false;
                this.alert.showWithTimeout('Credit card updated successfully', 'success');
            } catch (error) {
                this.alert.showWithTimeout(error.message, 'critical');
            } finally {
                this.submitting = false;
            }
        },
        updateBilling() {
            this.submitting = true;
            this.$refs.elementRef.getStripeRef().submit();
        },
        ...mapActions({
            getPatientCard: 'payment/getPatientCard',
            updateCreditCard: 'payment/updateCreditCard',
            createBillingProfile: 'user/createBillingProfile',
        }),
    },
};
</script>
