<template>
    <div>
        <div class="verify-account">
            <div class="identity__header">
                <h1>Verify Your Account</h1>
                <p class="identity__header--desc">Digital Safety Starts here</p>
                <slot name="why" />
            </div>
            <div class="verify-account__options">
                <VerifyCard
                    type="verify-id"
                    title="Identity Verification"
                    :badge="{ label: 'Quick & Easy', color: '#55D291' }"
                    :withAction="true"
                    button_label="Select"
                    @click.native="handleNext('existing_identity')"
                    class="pointer"
                    data-cypress="id_verification"
                >
                    <div>
                        <p class="h-caption text-dim-80">Verify by either uploading any of the following</p>
                        <ul class="h-caption">
                            <li>Passport</li>
                            <li>Drivers License</li>
                            <li>Valid Government ID</li>
                        </ul>
                        <p class="h-caption text-dim-60">*all documents are destroyed after account verification</p>
                    </div>
                </VerifyCard>
                <div v-if="isHealthspan" data-cypress="schedule" class="schedule-btn" @click="handleNext('schedule')">
                    <div>
                        <p class="schedule-btn__title">
                            Having Trouble?
                        </p>
                        <p class="schedule-btn__body text-dim-80">
                            Schedule a call with our care team for assistance with verification.
                        </p>
                    </div>
                    <ion-icon name="chevron-forward-outline" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VerifyCard from './SubComponents/VerifyCard.vue';
import {mapState} from 'vuex'

export default {
    name: 'VerifyAccount',
    components: {
        VerifyCard,
    },
    props : {
        handleNext: {
            type: Function,
            required: true,
        },
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
        isHealthspan() {
            return this.user && this.user.organization === process.env.VUE_APP_ORG_ID;
        }
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/_type.scss';

.verify-account {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 0 1.5rem;
    margin: 0 auto;

    &__options {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    @media (min-width: 1280px) {
        padding: 0;
    }
}

.schedule-btn {
    @include body;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: 1px solid #ececec;
    border-radius: 0.75rem;
    padding: 1.875rem 2.25rem;
    transition: border 0.3s ease;

    &__title {
        font-weight: 700;
        margin: 0 0 0.75rem;
    }
    
    &__body {
        margin: 0;
    }

    ion-icon {
        font-size: 30px;
    }

    &:hover {
        border: 1px solid #4a4a4a;
        cursor: pointer;
    }
}

</style>
