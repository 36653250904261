<template>
    <div>
        <div class="schedule-call" :class="{'schedule-call__hidden': scheduled}">
            <div class="identity__header schedule__header">
                <h1>Schedule Your Call</h1>
                <p class="identity__body text-dim-80">Select a date & time below</p>
            </div>
            <div class="schedule-call__cal">
                <Calcom :calcomConfig="calcomConfig" :calcomLink="calcomLink" :email="user.email" :name="fullName" :id="user._id" :intake_id="intakeId" @booking-successful="handleBookingSuccessful"/>
            </div>
        </div>
        <div v-if="scheduled" class="scheduled">
            <div class="scheduled__img__container">
                <img src="@/assets/images/verification/scheduled.svg"/>
            </div>
            <div class="scheduled__header">
                <h1>Successfully Scheduled!</h1>
                <p class="identity__body strong">Get Ready for your appointment</p>
            </div>
            <div><h-badge label="Appointment Details" color="#55D291" dark small /></div>
            <div class="scheduled__details">
                <div class="scheduled__details__item">
                    <div>
                        <img src="@/assets/images/verification/calendar.svg"/>
                    </div>
                    <div>{{localDate}}</div>
                </div>
                <div class="scheduled__details__item">
                    <div>
                        <img src="@/assets/images/verification/clock.svg"/>
                    </div>
                    <div>{{localTime}}</div>
                </div>
            </div>
            <div class="scheduled__button-container">
                <h-button label="Continue" variant="cta" full_width large :handleClick="handleContinue"/>
            </div>
        </div>
        <div v-if="!scheduled" class="identity__extra">
            <div class="identity__link" @click="handleReturn">Return</div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import HBadge from '@/components/Shared/HBadge.vue';
import HButton from '@/components/Shared/HButton.vue';
import Calcom from '@/components/Identification/Calcom.vue'; // Add this line

    export default {
        name: "Schedule",
        components: {
            HBadge,
            HButton,
            Calcom
        },
        data() {
            return {
                scheduled: null
            }
        },
        props: {
            calcomConfig: {
                type: Object,
                required: true
            },
            calcomLink: {
                type: String,
                required: true
            },
            handleReturn: {
                type: Function,
                required: true
            },
            handleContinue: {
                type: Function,
                required: true
            }
        },
        computed: {
            ...mapState({
                    user: (state) => state.user.user,
                }),
            fullName() {
                return `${this.user.first_name} ${this.user.last_name}`;
            },
            intakeId() {
                return this.$route.params.intakeId || '';
            },
            localDate() {
                const utcMoment = moment.utc(this.scheduled.date, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
                return utcMoment.local().format("MMMM Do YYYY");
            },
            localTime() {
                const utcMoment = moment.utc(this.scheduled.date, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
                return utcMoment.local().format("LT z");
            },
        },
        methods: {
            handleBookingSuccessful(data) {
                if (data && data.confirmed) {
                    this.scheduled = data;
                }
            },
        },
    }
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
.schedule__header {
    h1 {
        @extend %helveticaNowDisplayImportant;
        text-transform: none;
    }
}
.schedule-call {
    &__cal {
        padding: 2rem 0 0;
    }

    &__hidden {
        display: none;
    }
}
.scheduled {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 0 1.5rem;

    &__img {
        &__container {
            position: relative;
            width: 100%;
            max-width: 249px;
            height: 253px;
            padding: 0 1rem;
            margin: 0 auto;

            img {
                position: absolute;
                top: 50%;
                bottom: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__header {
        text-align: center;

        h1 {
            @extend %helveticaNowDisplayImportant;
            font-size: 1.75rem !important;
            line-height: 2.5rem !important;
            font-weight: 400 !important;
            text-transform: capitalize;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: .75rem;
        background-color: #F9F9F9;
        width: 100%;
        max-width: 490px;
        padding: 1rem 3rem;

        &__item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: .5rem;
            width: 100%;
            padding: 1rem 0;

            &:first-child {
                border-bottom: 1px solid #E5E5E5;
            }
        }
    }

    &__button-container {
        width: 100%;
        max-width: 490px;

        @extend %helveticaNowDisplayImportant;
        font-size: 0.875rem;
        letter-spacing: 0.2rem;
        font-weight: 800;
        text-transform: uppercase;
    }

    @media (min-width: 1280px) {
        &__header {
            @include helveticaNowDisplayImportantMixin();

            h1 {
                @include helveticaNowDisplayImportantMixin();
                font-size: 3rem !important;
                line-height: 3.5rem !important;
                font-weight: 300 !important;
            }
        }
    }

}

</style>
