import axios from "axios";
import Vue from "vue";

const baseUrl = process.env.VUE_APP_BASE_END;

export const productTemplates = {
    namespaced: true,
    state: {
        upsellProductTemplates: {
            loading: false,
            data: null,
        },
        signupProductTemplate: null,
        labProductId: null,
        product_category_template: {},
        selectedProduct: null,
    },
    mutations: {
        setUpsellProductTemplatesData(state, data) {
            state.upsellProductTemplates.data = data;
        },
        setUpsellProductTemplatesLoading(state, loading) {
            state.upsellProductTemplates.loading = loading;
        },
        setSignupProductTemplateId(state, data) {
            state.signupProductTemplate = data;
        },
        setProductCategoryTemplate(state, payload) {
            Vue.set(state.product_category_template, payload.id, payload.value);
        },
        setSelectedProduct(state, { product, referrer = null }) {
            state.selectedProduct = {
                ...product,
                id: product._id,
                referrer,
            };
        },
    },
    actions: {
        getManyProductTemplatesByIds({ commit, rootState }, payload) {
            let orgId = rootState.orgId.data;

            commit('setUpsellProductTemplatesLoading', true);
            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + '/product-templates/' + orgId + '/many',
                    method: 'GET',
                    params: { ids: payload.ids },
                })
                    .then((response) => {
                        commit('setUpsellProductTemplatesData', response.data);
                        resolve(response.data);
                        commit('setUpsellProductTemplatesLoading', false);
                    })
                    .catch((err) => {
                        reject(err);
                        commit('setUpsellProductTemplatesLoading', false);
                    });
            });
        },
        async getProductTemplateByCategory({ commit, rootState }, payload) {
            const organization = rootState.orgId.data;

            commit('setProductCategoryTemplate', { id: 'error', value: false });
            commit('setProductCategoryTemplate', { id: 'loading', value: true });

            try {
                const response = await axios.get(`${baseUrl}/product/${organization}/template/category/${payload.product_category_id}`);

                if ('data' in response) {
                    commit('setProductCategoryTemplate', { id: 'loading', value: false });
                    commit('setProductCategoryTemplate', { id: 'data', value: response.data });

                    return response.data;
                }
            } catch (error) {
                commit('setProductCategoryTemplate', { id: 'error', value: true });
                commit('setProductCategoryTemplate', { id: 'data', value: null });
                commit('setProductCategoryTemplate', { id: 'loading', value: false });

                if (axios.isAxiosError(error)) {
                    commit('setProductCategoryTemplate', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },

        saveSignupProductTemplateId({ commit }, payload) {
            commit('setSignupProductTemplateId', payload);
        },
    },
};
