<template>
<div class="input-white-container-size">
    <div class="input-white-container">
        <div class="flex-row">
            <h4 class="h4-account">Payment Method</h4>
            <div class="open-container">
                <button class="transparent-button" @click="openContainer()">
                        <span class="down-arrow-container">
                            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path
                                class="icon-styleable-color"
                                d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path><path d="M0 0h24v24H0V0z"
                                                                                                        fill="none"></path></svg>
                        </span>
                </button>
            </div>
        </div>

        <collapse-transition dimension="height">
            <div v-show="open" class="form-block">
                <div v-show="!open_edit" class="profile_static">
                    <div class="mb2r">

                        <div class="basic-info-container">
                            <div class="payment-method-wrapper">
                                <div v-if="patientCard" class="payment-method-container">
                                    <div class="payment-detail">{{ patientCard.brand }}</div>
                                    <div class="payment-detail">•••• •••• •••• {{ patientCard.last4 }}</div>
                                </div>
                                <div v-else class="payment-method-container">
                                    <div class="payment-detail">No card on file</div>
                                </div>
                            </div>
                        </div>

                        <div class="new-payment-container">
                            <button class="new-payment-button" @click="openEdit()">Change Payment Method</button>
                        </div>

                    </div>
                </div>
                <form v-show="open_edit" @submit.prevent="submit">

                    <div class="profile-static">
                        <div class="mb2r">
                            <div class="credit-card-container-form">
                                <div class="credit-card-inner-container-form">
                                    <div class="stripe-payment-container">
                                        <div class="stripe-text-field">
                                            <!-- <div id="card" ref="card"></div> -->
                                            <stripe-element-card ref="elementRef" :elementStyle="style" :pk="publishable_key" @token="saveCard" @error="cardError"/>
                                        </div>

                                        <!--error msg-->
                                        <div v-if="card_err.error" class="failed-payment-container mt1r">
                                            <span>{{ card_err.error_msg }}</span>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="submit-button-container">
                            <div class="input-style-block">
                                <button class="dashboard-button black-bg" type="submit" >
                                    {{ save_button }}
                                </button>
                            </div>


                            <button class="cancel-button" type="button" @click="cancelEdit()">
                                <img src="@/assets/icons/close-icon.svg"/>Cancel
                            </button>
                        </div>
                    </div>
                </form>

            </div>

        </collapse-transition>
    </div>
</div>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition'
import {mapActions, mapMutations, mapState} from 'vuex';
import {email, minLength, required} from 'vuelidate/lib/validators';
import { StripeElementCard } from '@vue-stripe/vue-stripe';

//instantiate Stripe Instance
let stripe;
let card = null;

export default {
    name: 'payment-settings',
    components: {
        CollapseTransition: CollapseTransition,
        StripeElementCard,
    },
    data() {
        return {
            open: true,
            open_edit: false,
            save_button: 'Submit',
            err_status: {
                isErr: false,
                err_msg: null
            },
            publishable_key: process.env.VUE_APP_STRIPE_KEY,
            style: {
                base: {
                    color: '#000',
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: '16px',
                    backgroundColor: '#fff',
                },
            }
        }
    },
    beforeDestroy() {
        if (this.$refs.elementRef) {
            this.$refs.elementRef?.destroy?.();
        }
    },
    validations: {
        originalData: {
            email: {required, email},
            first_name: {required},
            last_name: {required},
            phone_number: {required, minLength: minLength(10)}
        }
    },
    methods: {

        cancelEdit() {
            this.card_err.error = false;
            this.open_edit = !this.open_edit;
            this.card_err.error = false;
            this.card_err.error_msg = '';
            this.$refs.elementRef.clear();
        },
        submit () {
            this.$refs.elementRef.submit();
        },
        cardError (error) {
            this.card_err.error = true;
            this.card_err.error_msg = error.message;
            this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
            this.save_button = 'Submit';
            return;
        },
        async saveCard(token) {

            //change the state of the button
            this.card_err.error = false;
            this.save_button = 'Submitting';

            try {
                let payload = {
                    token,
                    charge: false,
                    new_user: false,
                    charge_amount: null,
                    user: this.userData
                };

                if (this.has_stripe_profile) {
                    await this.updateCreditCard(payload);
                } else {
                    const stripe_profile_payload = {
                        organization: this.userData.organization,
                        user_id: this.userData._id,
                        token
                    };
                    await this.createBillingProfile(stripe_profile_payload);
                }
                await this.getPatientCard({user_id: this.userData._id})
                this.open_edit = false;
                this.save_button = 'Submit';
            } catch (e) {
                this.card_err.error = true;
                if ('message' in e) {
                    this.card_err.error_msg = e.message;
                } else {
                    this.card_err.error_msg = 'There was an error. Please Try Again.'
                }
                this.save_button = 'Submit';
                this.$forceUpdate();
            }
        },
        openContainer() {
            this.open = !this.open;
        },
        openEdit() {
            this.open_edit = !this.open_edit;
            this.copied_data = {...this.userData};
        },

        ...mapActions({
            updateCreditCard: 'payment/updateCreditCard',
            getPatientCard: 'payment/getPatientCard',
            getMerchant: 'integrations/getMerchant',
            createBillingProfile: 'user/createBillingProfile'
        }),
        ...mapMutations({
            removePaymentError: 'payment/removePaymentError'
        })
    },
    created() {
        //we need to make a copy of the original data
        //so that when we edit the data7
        this.originalData = Object.assign({}, this.userData);
        this.$nextTick(() => {
            if ('stripe_profile' in this.userData) {
                this.getPatientCard({user_id: this.userData._id});
            }
        })
    },
    computed: {
        ...mapState({
            userData: state => state.user.user,
            duplicate_email: state => state.user.duplicate_email,
            card_err: state => state.payment.error,
            patientCard: state => state.payment.patientCard.data
        }),
        has_stripe_profile() {
            let stripe_profile = this.userData.stripe_profile || null;
            return !!(stripe_profile);
        },
        async cardElements() {
            const response = await this.getMerchant();
            const public_key = process.env.VUE_APP_STRIPE_KEY;
            stripe = Stripe(public_key, {
                stripeAccount: response.merchant_id
            });
            return stripe.elements({
                fonts: [
                    {
                        cssSrc: "https://fonts.googleapis.com/css?family=Poppins&display=swap",
                    },
                ],
            })
        }


    }
}
</script>
