<template>
<div v-if="intake_instance_response.data.question" class="intake-question">
    <div class="intake-question__header">
        <h2>{{ intake_instance_response.data.question.label }}</h2>
        <p v-if="intake_instance_response.data.question.description_text" class="intake-question__small">
            {{ intake_instance_response.data.question.description_text }}</p>
    </div>

    <div class="intake-question__options">
        <div v-for="(option, index) in intake_instance_response.data.question.options" v-bind:key="index" class="intake-question__single-option">
            <input :id="option._id" v-model="responses" :value="option._id" class="hidden-radio"
                    type="radio" @click="checkForm(option._id)">
            <label :for="option._id">
                <div class="radio-text">{{ option.label }}</div>
            </label>
        </div>
    </div>

    <div v-if="selectedReponseConditions" class="intake-question__textarea">
        <label class="intake-question__small">{{ selectedReponseConditions.question.label }}</label>
        <textarea v-model="selectedReponseConditions.free_format_response"></textarea>
        <div class="intake-button">
            <h-button label="Continue" variant="primary" :loading="submitting" :disabled="!checkTrue" full_width large :handleClick="handleContinue"></h-button>
        </div>
    </div>
    <div v-if="!selectedReponseConditions && saved" class="intake-button">
            <h-button
                label="Continue"
                variant="primary"
                :loading="submitting"
                :disabled="!checkTrue"
                full_width
                large
                :handle-click="() => checkForm(responses)" 
                data-test="continue-button"
            />
    </div>
    <div class="intake-certification">
        <h-certification/>
    </div>
</div>
</template>

<script>
import {mapGetters, mapMutations, mapActions, mapState} from 'vuex'
import HButton from '../../Shared/HButton.vue';
import HCertification from '@/components/Shared/HCertification.vue';

export default {
    name: 'RadioSingle',
    data() {
        return {
            submitting: false,
            responses: null,
            saved: false,
        }
    },
    props: ['step', 'loaded'],
    components: {
        HButton,
        HCertification
    },
    methods: {
        checkForm(selected) {
            this.responses = selected;

            const vm = this;
            if (!vm.responses) {
                return;
            }

            const patient_response = {response: vm.responses}

            try {
                let payload = {
                    response_id: vm.intake_instance_response.data._id,
                    user_id: vm.user._id,
                    intake_id: vm.$route.params.intakeId,
                    response: patient_response
                }
                vm.updateIntakeResponse(payload);
            } catch (error) {
                console.log('updateIntakeResponseError', error)
            }

            if (!vm.selectedReponseConditions) {
                this.handleNext();
                return;
            }
            vm.selectedReponseConditions.free_format_response = '';
        },
        handleContinue() {
            const vm = this;
            vm.submitting = true;
            try {
                let payload = {
                    parent_response_id: vm.intake_instance_response.data._id,
                    user_id: vm.user._id,
                    organization: vm.user.organization,
                    intake_id: vm.$route.params.intakeId,
                    responses: [
                        {
                        question: vm.selectedReponseConditions.question._id,
                        is_free_format: vm.selectedReponseConditions.question.type === 'long-text' || selectedReponseConditions.question.type === 'short-text',
                        free_format_response: vm.selectedReponseConditions.free_format_response,
                        },
                    ],
                }
                vm.setChildResponseByQuestionId(payload)
                vm.handleNext();
            } catch (error) {
                console.log('setChildResponseByQuestionIdError', error)
                vm.submitting = false;
            }
        },
        handleNext() {
            setTimeout(() => {
                this.submitting = false;
                this.routerPush(this.step);
            }, 300)
        },
        ...mapActions({
            updateIntakeResponse: 'intake/updateIntakeResponse',
            setChildResponseByQuestionId: 'intake/setChildResponseByQuestionId'
        }),
        ...mapMutations({
            routerPush: 'intake/routerPush'
        })
    },
    computed: {
        ...mapGetters({
            totalSteps: 'intake/totalSteps'
        }),
        ...mapState({
            intake_id: state => state.intake.intake_id,
            states: state => state.states.states,
            user: state => state.user.user,
            signupIntakeTemplateId: state => state.intake.signupIntakeTemplateId,
            selectedIntakeTemplate: state => state.intake.selectedIntakeTemplate,
            currentIntakeInstance: state => state.intake.currentIntakeInstance,
            intake_instance_response: state => state.intake.intake_instance_response
        }),
        checkTrue() {
            return (this.responses);
        },
        more_than_two() {
            return (this.intake_instance_response.data.question.options.length > 2);
        },
        selectedReponseConditions: {
            get() {
                const vm = this;
                let response_conditions = vm.intake_instance_response.data.question.response_conditions || [];
                let patient_responses = vm.responses || [];
                let matched_response_condition = response_conditions.find((response_condition) => {
                    return response_condition.selected_option === patient_responses;
                })
                return matched_response_condition || null;
            }
        },
    },
    created() {
        const vm = this;
        //instantiate values of responses and response conditions
        if (this.intake_instance_response.data['response']) {
            this.responses = this.intake_instance_response.data.response._id;
            this.saved = true;
        }
        let response_conditions = vm.intake_instance_response.data.question.response_conditions || [];
        let child_responses = vm.intake_instance_response.data.child_responses || [];
        response_conditions.forEach((response_condition) => {
            child_responses.forEach((child_response) => {
                if (child_response.question === response_condition.question._id) {
                    response_condition.free_format_response = child_response.free_format_response;
                }
            })
        })
    },
}

</script>
