<template>
    <div class="checkout">
        <h-text-input
            id="first_name"
            name="first_name"
            label="First Name"
            v-model="shipping_info.first_name"
            :vuelidate="$v.shipping_info.first_name"
            :errors="[$v.shipping_info.first_name.$invalid && $v.shipping_info.first_name.$dirty && 'Enter your first name.']"
        />
        <h-text-input
            id="last_name"
            name="last_name"
            label="Last Name"
            v-model="shipping_info.last_name"
            :vuelidate="$v.shipping_info.last_name"
            :errors="[$v.shipping_info.last_name.$invalid && $v.shipping_info.last_name.$dirty && 'Enter your last name.']"
        />
        <h-text-input
            id="autocomplete"
            ref="autocompleteInput"
            name="street_address"
            label="Street Address"
            placeholder=""
            v-model="shipping_info.street_address"
            @keydown.enter="handlePlaceSelection();"
            :vuelidate="$v.shipping_info.street_address"
            :errors="[
                !$v.shipping_info.street_address.required && $v.shipping_info.street_address.$dirty && 'Please enter your street address.',
                !$v.shipping_info.street_address.maxLength && $v.shipping_info.street_address.$dirty && 'Street address must be less than 35 characters long.',
            ]"
        />
        <h-text-input
            id="apt_suite"
            name="apt_suite"
            label="Apt, Suite"
            v-model="shipping_info.apt_suite"
            :vuelidate="$v.shipping_info.apt_suite"
            :errors="[!$v.shipping_info.apt_suite.maxLength && $v.shipping_info.apt_suite.$dirty && 'Apt/Suite must be less than 35 characters long.']"
        />
        <h-text-input
            id="city"
            name="city"
            label="City"
            v-model="shipping_info.city"
            :vuelidate="$v.shipping_info.city"
            :errors="[
                !$v.shipping_info.city.required && $v.shipping_info.city.$dirty && 'Please enter your city.',
                !$v.shipping_info.city.maxLength && $v.shipping_info.city.$dirty && 'City must be less than 35 characters long.',
            ]"
        />
        <div class="checkout__row">
            <HDropDown
                label="State"
                :options="new_states"
                placeholder="Select State"
                v-model="shipping_info.state"
                :selected="shipping_info.state"
                :vuelidate="$v.shipping_info.state"
                :errors="[!$v.shipping_info.state.required && $v.shipping_info.state.$dirty && 'Please enter your state.']"
            />
            <h-text-input
                id="zip_code"
                name="zip_code"
                label="Zip Code"
                v-model="shipping_info.zip_code"
                :vuelidate="$v.shipping_info.zip_code"
                :errors="[$v.shipping_info.zip_code.$invalid && $v.shipping_info.zip_code.$dirty && 'Please enter a valid zip code.']"
            />
        </div>
        <h-text-input
            id="country"
            name="country"
            label="Country"
            v-model="shipping_info.country"
            :vuelidate="$v.shipping_info.country"
            :errors="[$v.shipping_info.country.$invalid && $v.shipping_info.country.$dirty && 'Please enter your country.']"
        />
        <h-text-input
            id="phone_number"
            ref="phoneNumberInput"
            name="phone_number"
            label="Phone Number"
            v-model="shipping_info.phone_number"
            :vuelidate="$v.shipping_info.phone_number"
            mask="(###) ###-####"
            :errors="[$v.shipping_info.phone_number.$invalid && $v.shipping_info.phone_number.$dirty && 'Please enter your phone number.']"
        />
        <h-text-input
            v-if="!user.mobile_number"
            id="mobile_number"
            v-model="shipping_info.mobile_number"
            name="mobile_number"
            label="Mobile Number"
            :vuelidate="$v.shipping_info.mobile_number"
            mask="(###) ###-####"
        />
        <div v-if="!user.mobile_number" class="h-caption checkout__label">
            Please provide your mobile number to receive important account and treatment updates. Please read our
            <div class="link" role="button" tabIndex="0" @click="toggleSmsPolicy">
                SMS Policy
            </div>
            for more information.
        </div>
        <h-button
            v-if="show_button"
            variant="primary"
            label="Next"
            large
            :handleClick="emitShippingData"
            :loading="loading"
            :disabled="$v.shipping_info.$invalid"
        />
        <transition name="sidebar" mode="out-in">
            <SmsPolicySidePanel v-if="smsPolicyVisible" :open="smsPolicyVisible" @handleClose="toggleSmsPolicy" />
        </transition>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
import {loadGoogleMaps, initMap} from "@/helpers/googlemaps";
import HTextInput from '@/components/Shared/HTextInput';
import HDropDown from '@/components/Shared/HDropdown';
import HButton from '@/components/Shared/HButton';
import SmsPolicySidePanel from '@/components/Shared/SmsPolicySidePanel';

const isZipcode = helpers.regex('isZipcode', /^\d{5}(-\d{4})?$/);

export default {
    name: 'AutoComplete',
    props: {
        shipping: {
            type: Object,
            default: () => {},
        },
        loading: {
            type: Boolean,
            required: false,
        },
        show_button: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    components: {
        HTextInput,
        HDropDown,
        HButton,
        SmsPolicySidePanel,
    },
    data() {
        return {
            shipping_info: {
                first_name: '',
                last_name: '',
                street_address: '',
                apt_suite: '',
                city: '',
                state: '',
                zip_code: '',
                country: 'us',
                phone_number: '',
                mobile_number: '',
            },
            new_states: [],
            api_key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
            smsPolicyVisible: false,
        };
    },
    validations: {
        shipping_info: {
            first_name: { required },
            last_name: { required },
            street_address: { required, maxLength: maxLength(35) },
            apt_suite: { maxLength: maxLength(35) },
            city: { required, maxLength: maxLength(35) },
            state: { required },
            zip_code: { required, minLength: minLength(5), isZipcode },
            country: { required },
            phone_number: { required, minLength: minLength(14) },
            mobile_number: { required: false, minLength: minLength(14) },
        }
    },
    watch: {
        'shipping_info': {
            handler(shipping_info) {
                this.onShippingChange();
            },
            immediate: true,
            deep: true
        }
    },
    created() {
        this.getFirstName();
        this.getLastName();
        this.shipping_info = this.shipping;
        this.onShippingChange();
    },
    mounted() {
        const googleMapsCallback = () => {
            initMap(this.$refs.autocompleteInput, this.onInputFocus, this);
            this.populateStates();
        }

        loadGoogleMaps(this.api_key, googleMapsCallback)
    },
    computed: {
        ...mapState({
            states: (state) => state.states.states,
            user: state => state.user.user
        }),
    },
    methods: {
        async populateStates() {
            await this.retrieveStates();
            this.new_states = this.states.map((item) => {
                return { ...item, value: item.abbreviation };
            });
        },
        onInputFocus() {
            this.$refs.phoneNumberInput.$refs.input_element.focus();
        },
        emitShippingData() {
            this.$emit('shipping-data', this.shipping_info);
        },
        onShippingChange() {
            this.$emit('shipping-change', {
                data: this.shipping_info,
                validations: this.$v.shipping_info
            })
        },
        ...mapActions({
            retrieveStates: 'states/retrieveStates',
        }),
        getFirstName() {
            this.first_name = this.shipping_info.first_name ? this.shipping_info.first_name : this.user.first_name ? this.user.first_name : '';
        },
        getLastName() {
            this.last_name = this.shipping_info.last_name ? this.shipping_info.last_name : this.user.last_name ? this.user.last_name : '';
        },
        toggleSmsPolicy() {
            this.smsPolicyVisible = !this.smsPolicyVisible;
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/utility.scss';

.checkout {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin: 0 auto;

    &__row {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (min-width: 810px) {
            flex-direction: row;
            gap: 0.5rem;

            select {
                flex: 2;
            }
        }
    }

    &__label {
        color: #928D8A;
    }

    button {
        margin-top: 1rem;
    }
}

.pac-item {
    font-size: 0.875rem;
    padding: 0.5rem 0.875rem;
    cursor: pointer;
}

.link {
  @include caption;
  display: inline-block;
  color: $powder-blue-200;
  cursor: pointer;
}

.pac-container {
    z-index: $z-autocomplete;
}
</style>
