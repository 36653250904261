<template>
    <div class="complete-visits__container">
        <div class="complete-visits__header">
            <h2 class="headline-3-slim">
                Complete Your Visits
            </h2>
            <p class="cereal-text-18">
                You have recently started visits for
                your prescription medications. Pickup where you left off and
                complete your visits today.
            </p>
        </div>
        <div v-if="!formatted_visits.length" class="complete-visits__loading">
            <div class="complete-visits__item" :style="{'min-height': '480px'}"></div>
        </div>
        <div v-if="formatted_visits.length" class="complete-visits__incomplete-items">
            <div v-for="(visit, index) in formatted_visits" :key="index" class="complete-visits__item">
                <div class="complete-visits__item-header">
                    <div v-if="!visit.template.bundle_support" class="complete-visits__item-product">
                        <div
                            class="complete-visits__item-image"
                            :style="{'background-image': `url(${visit.products[0].product_images[0]})`}"
                        ></div>
                        <div class="complete-visits__item-description">
                            <div class="complete-visits__item-title">
                                {{ visit.products[0].product_name }}
                            </div>
                            <div v-snip="{ lines: 4 }" class="complete-visits__item-desc">
                                {{ visit.products[0].description || 'Complete Intake' }}
                            </div>
                        </div>
                    </div>
                    <div v-if="visit.template.bundle_support" class="complete-visits__item-product">
                        <div
                            class="complete-visits__item-image"
                            :style="{'background-image': `url(${visit.template.bundle_settings.image})`}"
                        ></div>
                        <div class="complete-visits__item-description">
                            <div class="complete-visits__item-title">
                                {{ visit.template.bundle_settings.title }}
                            </div>
                            <div v-snip="{ lines: 4 }" class="complete-visits__item-desc">
                                {{ visit.template.bundle_settings.description || 'Bundle Description' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="complete-visits__status">
                    <div class="complete-visits__status-title">
                        Progress
                    </div>
                    <div class="complete-visits__status-item">
                        <div class="complete-visits__status-type">
                            Questions
                        </div>
                        <div class="complete-visits__status-section">
                            <div class="complete-visits__status-data">
                                <span
                                    v-if="visit.status.complete.total !== visit.status.questions.total"
                                >{{ visit.status.complete.total }}/{{ visit.status.questions.total }}</span>
                                <span
                                    v-if="visit.status.complete.total === visit.status.questions.total"
                                    class="complete-visits__status-checkmark complete"
                                >
                                    <span class="arbor-list__checkmark"></span>
                                </span>
                            </div>
                            <div class="complete-visits__status-bar">
                                <div
                                    class="complete-visits__status-bar-inner"
                                    :style="{'width': generate_intake_progress(visit.status.complete.total, visit.status.questions.total)}"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <!--                    TODO: Add support for verification -->
                    <div v-if="false" class="complete-visits__status-item">
                        <div class="complete-visits__status-type">
                            Verification
                        </div>
                        <div class="complete-visits__status-section">
                            <div class="complete-visits__status-data">
                                <span
                                    v-if="completed_verification(visit.intake).length < 2"
                                >{{ completed_verification(visit.intake).length }}/2</span>
                                <span
                                    v-if="completed_verification(visit.intake).length === 2"
                                    class="complete-visits__status-checkmark complete"
                                >
                                    <span class="arbor-list__checkmark"></span>
                                </span>
                            </div>
                            <div class="complete-visits__status-bar">
                                <div
                                    class="complete-visits__status-bar-inner"
                                    :style="{'width': completed_verification(visit.intake).length > 1 ? '100%' : completed_verification(visit.intake).length ? '50%' : '0' }"
                                ></div>
                            </div>
                        </div>
                    </div>

                    <div class="complete-visits__status-item">
                        <div class="complete-visits__status-type">
                            Checkout
                        </div>
                        <div class="complete-visits__status-section static">
                            <div class="complete-visits__status-data">
                            </div>
                            <div class="complete-visits__status-desc">
                                Pending
                            </div>
                        </div>
                    </div>
                </div>
                <div class="complete-visits__footer">
                    <button class="pill-button black-btn" @click="complete_intake(visit)">
                        Complete Visit
                    </button>
                    <button class="pill-button" @click="cancel_visit(visit)">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import { analytics, analyticsActions } from '@/helpers/analytics';
import IntakeService from '@/services/IntakeService';

import { isMedicalProfileEmpty } from '../intake/utils/medicalProfileUtils';

export default {
    name: 'CompleteVisits',
    props: ['incomplete_visits'],
    components: {
    },
    data() {
        return {
            formatted_visits: [],
            options: {
                currentPage: 0,
                infinite: 4,
                slidesToScroll: 4,
                loop: true,
                thresholdDistance: 0,
                thresholdTime: 300,
            },
            configured_product: null,
        };
    },
    async created() {
        await this.format_products(this.incomplete_visits);
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            intake_instance: state => state.intake.currentIntakeInstance.data,
        }),
    },
    methods: {
        ...mapActions({
            get_products: 'productTemplates/getManyProductTemplatesByIds',
            retrieve_incomplete_intakes: 'intake/retrieveIncompleteIntakes',
            close_intake: 'intake/patientClosesIntake',
            retrieve_intake: 'intake/retrieveIntake',

        }),
        ...mapMutations({
            set_intake: 'intake/setIntakeId',

        }),
        hasNoProfile() {
            return !this.user.birthday || !this.user.sex || !this.user.state;
        },
        hasNoBodyInfo() {
            return !this.user.weight || !this.user.height;
        },
        async complete_intake(visit) {
            window.t = visit;
            analytics.track(analyticsActions.complete_visit_click, {
                source: 'dashboard',
                intakeId: visit.intake._id,
                templateId: visit.template._id,
                templateName: visit.template.name,
            });

            visit.intake['total_steps'] = visit.status.questions.total;

            const intake = await this.retrieve_intake({
                user: this.user._id,
                _id: visit.intake._id,
            });

            window.localStorage.setItem('z_intake', JSON.stringify(intake));
            window.localStorage.removeItem('intake_template');
            window.localStorage.setItem('intake_template', JSON.stringify(visit.template._id));

            this.$store.commit('intake/initializeIntakeId');
            this.set_intake(intake._id);
            IntakeService.initSignupConfiguration(visit.template);

            if (this.hasNoProfile() || this.hasNoBodyInfo()) {
                await this.$router.push({ path: '/consultation/profile/' + this.user._id + '/' + intake._id + '/question/none' });

                return;
            }

            const { medical_profile: medicalProfile } = this.user;

            if (!medicalProfile || isMedicalProfileEmpty(medicalProfile)) {
                await this.$router.push({ path: `/consultation/pharmacy-overview/${this.user._id}/${intake._id}/question/none` });

                return;
            }

            if (visit.status.complete.total < visit.status.questions.total) {
                const responses = visit.status.incomplete.verification?.length ? visit.status.incomplete.verification : visit.status.incomplete.intake_responses;
                const firstIncompleteResponse = responses[0];
                const intakeResponse = visit.intake.intake_responses.find(i => i._id === firstIncompleteResponse._id);
                const responseIndex = visit.intake.intake_responses.indexOf(intakeResponse);

                await this.$router.push({
                    path: '/consultation/intake/' + this.user._id + '/' + visit.intake._id + '/question/' + intakeResponse._id,
                    query: { step: responseIndex },
                    replace: true,
                });
            } else {

                const checkoutParams = {
                    intakeId: visit.intake._id,
                    section: 'product-configure',
                };

                await this.$router.push({
                    name: 'treatment-checkout',
                    params: checkoutParams,
                    query: {
                        product: visit.products[0]._id,
                    },
                });

            }
        },

        async cancel_visit(visit) {
            try {
                const payload = {
                    user: this.user._id,
                    intake: visit.intake._id,
                };

                await this.close_intake(payload);
                const incompleteVisits = await this.retrieve_incomplete_intakes({ user: this.user._id });

                await this.format_products(incompleteVisits);
            } catch (err) {
                this.cancel_text = 'Error. Try Again';
            }
        },

        completed_verification(intake) {
            const completedIntake = [];

            if (intake.image_verification.id_image_url) {
                completedIntake.push(intake.image_verification.id_image_url);
            }

            if (intake.image_verification.profile_image_url) {
                completedIntake.push(intake.image_verification.profile_image_url);
            }

            return completedIntake;
        },

        async format_products(incompleteVisits) {
            const visits = incompleteVisits.map(async i => {
                try {
                    const payload = {
                        ids: i.template.product_defaults,
                    };
                    const products = await this.get_products(payload);

                    return {
                        ...i,
                        products,
                    };
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            });

            this.formatted_visits = await Promise.all(visits);
        },

        generate_intake_progress(totalComplete, totalQuestions) {
            return `${Math.floor((totalComplete / totalQuestions) * 100)}%`;
        },
    },
};
</script>
<style lang="scss">
.complete-visits {
    &__container {
        border-radius: 12px;
        border: 1px solid #EBEBEB;
        background-color: #f7f7f7;
        overflow: hidden;
        padding: 2rem 1.5rem;

        @media (min-width: 760px) {
            padding: 2rem;
        }

    }

    &__incomplete-items {
        display: flex;
        gap: 1.5rem;
        margin-top: 2rem;
        justify-content: start;
        flex-wrap: wrap;
        width: 100%;

        @media screen and (max-width: 1090px) {
            flex-direction: column;
        }
    }

    &__item {
        background-color: #FFFFFF;
        border-radius: 12px;
        box-shadow: rgb(0 0 0 / 8%) 0 0.25rem 0.75rem;
        padding: 2rem 1.5rem;

        @media (min-width: 760px) {
            max-width: 340px;
        }
    }

    &__item-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-family: "ZP-UI", sans-serif;
        align-items: center;
        border-bottom: 1px solid #EBEBEB;
        padding-bottom: 1.5rem;

        .VueCarousel {
            max-width: 370px;
            min-height: 220px;

            @media screen and (min-width: 1400px) {
                max-width: 370px;
            }

            @media screen and (max-width: 1400px) {
                max-width: 430px;
            }

            @media screen and (max-width: 1300px) {
                max-width: 300px;
            }

            @media screen and (max-width: 1090px) {
                max-width: 450px;
            }
        }

        @media screen and (max-width: 1090px) {
            justify-content: start;
        }
    }

    &__item-product {
        display: flex;
        gap: 1rem;
        width: 100%;

        @media screen and (max-width: 530px) {
            flex-direction: column;
        }

        @media screen and (max-width: 920px) {
            flex-direction: column;
        }
    }

    &__item-image {
        width: 150px;
        height: 150px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #f9f9f9;
        border-radius: 12px;
        margin: 0 auto;
    }

    &__item-title {
        font-weight: 500;
        font-size: 1.125rem;
        margin-bottom: 0.5rem;

        @media (min-width:760px) {
            font-size: 1rem;
        }
    }

    &__item-description {
        font-size: 0.875rem;

        @media (min-width:760px) {
            width: 250px;
            min-height: 115px;
        }
    }

    &__status {
        margin-top: 1rem;
        font-family: "ZP-UI", sans-serif;
        border-bottom: 1px solid #EBEBEB;
        padding-bottom: 1rem;
    }

    &__status-title {
        font-weight: 500;
        margin-bottom: 1rem;
    }

    &__status-item {
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__status-type {
        font-size: 0.875rem;
        font-weight: 500;
        color: $neutral500;
    }

    &__status-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 190px;

        @media screen and (max-width: 1140px) {
            min-width: inherit;
        }

        @media screen and (max-width: 1090px) {
            min-width: 190px;
        }

        @media screen and (max-width: 460px) {
            min-width: inherit;
        }

        &.static {
            justify-content: start;
        }
    }

    &__status-data {
        font-weight: 500;
        margin-right: 1rem;
    }

    &__status-desc {
        font-weight: 500;
        font-size: 0.875rem;
        color: #a7a7a7;
        margin-left: 1.5rem;
    }

    &__status-checkmark {
        width: 20px;
        height: 20px;
        background-color: #a7a7a7;
        border-radius: 100%;
        display: block;

        &.complete {
            background-color: $nature400;
        }

        .arbor-list__checkmark {
            border-left: 1.5px solid #fff;
            border-bottom: 1.5px solid #fff;
            position: relative;
            left: 6px;
            top: -6px;
        }
    }

    &__status-bar {
        width: 150px;
        height: 5px;
        background-color: $nature100;
        border-radius: 12px;

        @media screen and (max-width: 1140px) {
            display: none;
        }

        @media screen and (max-width: 1090px) {
            display: block;
        }

        @media screen and (max-width: 460px) {
            display: none;
        }

    }

    &__status-bar-inner {
        height: 100%;
        background-color: $nature400;
        border-radius: 12px;
        max-width: 150px;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.875rem;
        margin-top: 1.5rem;

        button, a {
            width: 100%;
        }

        @media (min-width: 760px) {
            flex-direction: row;
        }
    }

}
</style>
