<template>
    <div>
        <Alert
            action-text="Verify Mobile Number"
            title="Enable SMS Notifications"
            icon="information-circle"
            type="error"
            @onAction="verifyMobile"
        >
            <template #message>
                <div>
                    In order to provide better assistance, a member from our clinician team may need
                    to communicate over text message with you regarding your treatment plan.
                    Please read our
                    <div class="link" role="button" tabIndex="0" @click="toggleSmsPolicy">
                        SMS Policy
                    </div>
                    for more information.
                </div>
            </template>
        </Alert>
        <div class="subscription-modal__container">
            <modal
                classes="subscription-modal"
                height="auto"
                :scrollable="true"
                name="verification-modal"
            >
                <mobile-verification-modal
                    @onModalClose="onClose"
                    @onSuccess="onSuccess()"
                ></mobile-verification-modal>
            </modal>
        </div>
        <transition name="sidebar" mode="out-in">
            <SmsPolicySidePanel v-if="smsPolicyVisible" :open="smsPolicyVisible" @handleClose="toggleSmsPolicy" />
        </transition>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Alert from '@/components/Components/Alert.vue';
import MobileVerificationModal from '@/components/Dashboard/Modals/MobileVerificationModal.vue';
import SmsPolicySidePanel from '@/components/Shared/SmsPolicySidePanel';

export default {
    name: 'MobileVerifyAlert',
    components: {
        Alert,
        MobileVerificationModal,
        SmsPolicySidePanel,
    },
    data() {
        return {
            smsPolicyVisible: false,
        };
    },
    computed: {
        ...mapState({
            organization: (state) => state.organization.data,
        }),
    },
    async created() {
        await this.getOrganization();
    },
    methods: {
        ...mapActions({
            getOrganization: 'getOrganization',
        }),
        onSuccess() {
            this.$emit('numberVerified', true);
        },
        onClose() {
            this.$modal.hide('verification-modal');
        },
        verifyMobile() {
            this.$modal.show('verification-modal');
            this.$forceUpdate();
        },
        toggleSmsPolicy() {
            this.smsPolicyVisible = !this.smsPolicyVisible;
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/scss/_type.scss';
@import '@/assets/scss/_colors.scss';

.verify-modal-container {
  background-color: $neutral100;
  min-height: 500px;
  border-radius: 30px;
  flex-direction: row;
  background-image: url("../../../assets/images/zp-illustrations/hand.svg");
  background-repeat: no-repeat;
  background-position: 90% 80%;
}

.verify-modal-side {
  min-width: 250px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $stone100;
}

.verify-modal {
  padding: 2rem;
}

.text-input-container {
  margin-bottom: 1rem;
}

.submit-button {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background-color: #198030;
  color: #daf2dd;
}

.error-text {
  color: $warm400;
  max-width: 400px;
}

.link {
  @include caption;
  display: inline-block;
  color: $powder-blue-200;
  cursor: pointer;
}

.side-panel__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > p {
    @include body;
    margin-bottom: 0;
  }
}
</style>
