<template>
    <div>
        <ProductNavigation />
        <ProtocolSkeleton v-if="productLoading" />
        <div v-show="!productLoading" class="protocol">
            <div class="protocol__side">
                <treatment-product-image :width="width" :image="preferenceImage" :testimonial="testimonial" />
                <div v-if="features" class="protocol__side-benefits">
                    <Benefits :features="features" />
                </div>
            </div>
            <div class="protocol__preferences">
                <ProductPlanDurations v-if="product" :product="product" :selected-plan-id="selectedPlanId" @onPlanSelected="onSelectPlan" />
                <div v-if="product" class="protocol-selection__header">
                    <h1 v-if="product.product_name">
                        {{ product.product_name }}
                    </h1>
                    <p v-if="product.description" id="checkout-desc" class="protocol-body810">
                        {{ product.description }}
                    </p>
                </div>
            </div>
        </div>
        <transition name="protocol-transform" mode="out-in">
            <div v-if="showSummary" class="protocol-summary__mobile">
                <ProductTotal
                    :submitting="isSubmitting"
                    :product="product"
                    :selected-plan-id="selectedPlanId"
                    :plan-options="planOptions"
                    @onContinue="onContinue"
                />
            </div>
        </transition>
        <div v-if="product" class="protocol-summary__desktop">
            <ProductTotal
                :submitting="isSubmitting"
                :product="product"
                :selected-plan-id="selectedPlanId"
                :plan-options="planOptions"
                @onContinue="onContinue"
            />
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import Benefits from '@/components/Auth/Benefits.vue';
import ProductNavigation from '@/components/ProductOnboarding/Components/ProductNavigation';
import ProductPlanDurations from '@/components/ProductOnboarding/Components/ProductPlanDurations';
import ProductTotal from '@/components/ProductOnboarding/Components/ProductTotal';
import ProtocolSkeleton from '@/components/Shared/Skeletons/ProtocolSkeleton.vue';
import TreatmentProductImage from '@/components/TreatmentPreferences/Components/TreatmentProductImage';
import { CHECKOUT_BREAKPOINTS } from '@/constants/checkout';
import { analytics, analyticsActions } from '@/helpers/analytics';

export default {
    name: 'ProductOnboarding',
    components: {
        ProductPlanDurations,
        ProductNavigation,
        TreatmentProductImage,
        ProductTotal,
        Benefits,
        ProtocolSkeleton,
    },
    data() {
        return {
            product: null,
            products: [],
            preferenceImage: null,
            selectedPlanId: null,
            isSubmitting: false,
            productLoading: false,
            width: '100%',
            showSummary: false,
            testimonial: null,
            customFrequency: false,
        };
    },
    methods: {
        ...mapActions({
            getProductTemplate: 'products/getProductTemplate',
            getProducts: 'products/getProducts',
            getMembershipPrices: 'memberships/getMembershipPrices',
        }),
        ...mapGetters({
            getPlanOptions: 'products/getPlanOptions',
        }),
        handleResize() {
            if (window.innerWidth < 1024) {
                const yOffset = window.scrollY;
                const windowHeight = window.innerHeight;
                const documentHeight = document.documentElement.scrollHeight;

                const currentWidth = CHECKOUT_BREAKPOINTS.find((breakpoint) => window.innerWidth < breakpoint.width);

                this.showSummary = yOffset > currentWidth.yOffset || yOffset + windowHeight >= documentHeight;
            }
        },
        setLayout() {
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
            window.addEventListener('scroll', this.handleResize);
        },
        resize() {
            this.visible = false;
            const element = document.getElementsByClassName('checkout-display-section')[0];

            if (element) {
                this.width = element.getBoundingClientRect().width + 'px';
                this.visible = true;
                this.$forceUpdate();
            }
        },
        onSelectPlan(plan) {
            analytics.track(analyticsActions.treatment_preferences_duration, {
                months: plan.duration,
            });
            this.selectedPlanId = plan.id;
        },
        onContinue() {
            this.isSubmitting = true;
            const { product } = this;

            analytics.track(analyticsActions.treatment_preferences_continue, {
                dosage_type: product.dosage_type,
                organization: product.organization,
                product_id: product._id,
                name: product.name,
                product_name: product.product_name,
            });

            this.$router.push({ path: `/signup/checkout/${this.productId}`, query: { plan: this.selectedPlanId } });
            this.isSubmitting = false;
        },
        async initializeProduct() {
            if (this.productId) {
                try {
                    const productTemplate = await this.getProductTemplate({ product_template_id: this.productId });

                    this.products = await this.getProducts({ product_template: this.productId });

                    this.product = {
                        ...productTemplate,
                        products: this.products,
                    };

                    this.preferenceImage = this.product.product_images?.[0];
                    this.testimonial = this.product.testimonials?.[0] || null;

                    this.selectedPlanId = this.products.find(item => item.pricing?.default)?._id || this.products[0]._id;
                } catch (error) {
                    console.error('init product', error);
                }
            }
        },
    },
    async mounted() {
        await this.$nextTick();
        this.resize();
        this.setLayout();
    },
    async created() {
        this.productLoading = true;

        try {
            if (!this.productId) {
                return await this.$router.push('/get-started');
            }

            await this.initializeProduct();

            this.productLoading = false;
        } catch (error) {
            this.productLoading = false;
            console.error('Error created()', error);
        }
        this.resize();
        window.addEventListener('DOMContentLoaded', this.resize);
        window.addEventListener('resize', this.resize);
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            organizationSettings: (state) => state.organizationSettings,
        }),
        productId() {
            return this.$route.params.productTemplateId;
        },
        features() {
            return this.organizationSettings.data?.signup?.features;
        },
        planOptions() {
            return this.getPlanOptions();
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('scroll', this.handleResize);
    },
};
</script>
