<template>
    <div class="protocol-summary" v-if="duration">
        <div v-if="custom_frequency" class="protocol-summary__container">
            <div class="protocol-summary__badge--desktop">
                <h-badge label="Custom protocol Request" color="#FF5850" small dark/>
            </div>
            <div class="protocol-summary__badge--mobile">
                <h-badge label="Custom protocol Request" color="#FF5850" small/>
            </div>
            <div class="protocol-summary__duration">You have requested a custom protocol, we will provide you with a more detailed treatment dosage recommendation based on your feedback and once your provider has reviewed your health history.</div>
            <div class="protocol-summary__btn">
                <h-button label="Continue" variant="primary" :loading="submitting" :handleClick="onContinueSelected" large wide full_width cta/>
            </div>
        </div>

        <div v-else class="protocol-summary__container">
            <div class="protocol-summary__checkout">
                <div class="protocol-summary__checkout-top">
                    <div>
                        <p class="protocol-summary__title">Total</p>
                        <p v-if="has_review_request" class="protocol-summary__price">
                            <span class="protocol-summary__price--main">Starting at</span><span class="protocol-summary__price--main" v-if="total_pricing.total">${{price_per_month.toFixed(2)}} /mo</span>
                        </p>
                    <p v-else class="protocol-summary__price">
                        <span class="protocol-summary__price--main" v-if="total_pricing.total">${{total_pricing.total_discount.toFixed(2)}}</span>
                        <span class="protocol-summary__price--sub">
                                ${{(total_pricing.total_discount / duration.months).toFixed(2)}} per month
                        </span>
                    </p>
                    </div>
                    <div class="protocol-summary__btn">
                    <h-button label="Continue" variant="primary" :loading="submitting" :handleClick="onContinueSelected" large wide full_width cta/>
                    </div>
                </div>
                <div class="protocol-summary__checkout-bottom">
                        <h-badge v-if="has_review_request" label="Clinical Assistance" color="#55D291" small dark/>
                    <p v-else class="protocol-summary__price">
                        <span v-if="total_pricing && total_pricing.total && total_pricing.total !== total_pricing.total_discount" class="protocol-summary__body strike">${{total_pricing.total.toFixed(2)}}</span>
                        <span v-if="total_pricing && total_pricing.total_savings && total_pricing.total_savings !== 0" class="protocol-summary__price--savings h-orange strong">${{total_pricing.total_savings.toFixed(2)}} Savings</span>
                    </p>
                </div>

            </div>

            <div class="protocol-summary__lab" v-if="is_membership">
                <div class="protocol-summary__title">
                    <span>Lab Work & Analysis</span>
                    <div class="protocol-tooltip">
                        <img src="@/assets/icons/info.svg" alt="" @mouseover="handleTooltip" @mouseout="handleTooltip"/>
                        <h-tooltip :text="tooltip_content" v-if="tooltip_visible"/>
                    </div>
                </div>
                <p class="protocol-summary__price--main h-orange">Free</p>
            </div>

            <div class="protocol-summary__duration protocol-summary__body">Your plan will be billed and <span class="strong">shipped every {{duration.months}}
                month{{duration.months > 1 ? 's': ''}}</span>. Modify or cancel your plan at any time.</div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import product_service from '@/services/Product';
import HButton from "@/components/Shared/HButton";
import HBadge from "@/components/Shared/HBadge.vue"
import HTooltip from "../../Shared/HTooltip.vue";

export default {
    name: 'TreatmentTotal',
    components: {HButton, HBadge, HTooltip},
    props: ['intake', 'submitting', 'custom_frequency', 'product'],
    watch: {
        'treatment_settings': {
            async handler(settings) {
                this.configure_total_price(settings);
                this.has_review(settings)
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            total_pricing: null,
            duration: null,
            starter_duration: null,
            starter_pricing: null,
            has_review_request: false,
            tooltip_content: 'Receive detailed results that translate complex data into actionable health insights, reviewed by top medical professionals.',
            tooltip_visible: false,
        }
    },
    computed: {
        ...mapState({
            treatment_settings: state => state.treatment.treatment_preferences
        }),
        price_per_month() {
            return this.total_pricing.total_discount / this.duration.months;
        },
        is_membership() {
            return this.treatment_settings[this.$route.query.product].membership
        }
    },
    methods: {
        ...mapActions({}),
        handleTooltip() {
            this.tooltip_visible = !this.tooltip_visible;
        },
        onContinueSelected() {
            this.$emit('onContinue', true);
        },
        has_review(settings) {
            this.has_review_request = product_service.hasReview(settings)
        },
        configure_total_price(settings) {

            if (this.intake && this.intake.bundle_support) {
                let pricing = {
                    total: 0,
                    total_discount: 0,
                    product_savings: 0,
                    membership_savings: 0,
                    total_savings: 0
                }
                for(const [product_id, configuration] of Object.entries(settings)) {
                    if (configuration.pricing) {
                        pricing.total += configuration.pricing.total
                        pricing.total_discount += configuration.pricing.total_discount
                        pricing.product_savings += configuration.pricing.product_savings;
                        pricing.membership_savings += configuration.pricing.membership_savings;
                    }
                }
                pricing.total_savings = pricing.membership_savings + pricing.product_savings;
                this.total_pricing = pricing;
                // We check to see if there are multiple products on the treatment intake
                // When there are multiple products we want to make sure all products have the same duration
                let first_product = Object.keys(settings)[0];
                if (first_product) {
                    this.duration = settings[first_product].duration;
                }
            } else {
                const treatment_product = this.$route.query.product;
                if (this.treatment_settings && this.treatment_settings[treatment_product]) {
                    this.duration = this.treatment_settings[treatment_product].duration;
                    this.total_pricing = this.treatment_settings[treatment_product].pricing;
                    this.total_pricing.total_savings = this.treatment_settings[treatment_product].pricing.membership_savings + this.treatment_settings[treatment_product].pricing.product_savings;
                }

            }

            this.$forceUpdate();
        },
        months_string(months, upper=true) {
            if (upper) {
                return `${months} Month${months > 1 ? 's': ''}`
            }
            return `${months} month${months > 1 ? 's': ''}`
        },
    }
}
</script>
<style lang="scss">

</style>
