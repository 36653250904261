<template>
    <div class="co-plan" :class="{'co-plan-active': active}" @click="handleClick">
        <div>
            <div v-if="plan.duration" class="co-plan-duration">
                {{ plan.duration }} Month
            </div>
            <div v-else class="co-plan-duration">
                One Time Purchase
            </div>
            <div class="co-plan-price">
                {{ formattedPrice }}
            </div>
        </div>
        <div class="co-plan-badge">
            <div v-if="plan?.highlight" class="co-plan-highlight">
                Best Value
            </div>
            <div class="co-plan-savings">
                {{ formattedSavings }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlanCard',
    props: {
        plan: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        formattedPrice() {
            return `$${this.plan?.price.toFixed(2)}`;
        },
        formattedSavings() {
            return  this.plan?.savings > 0 ? `Save $${this.plan?.savings.toFixed(2)}` : 'Pay Full Price';
        },
    },
    methods: {
        handleClick() {
            this.$emit('select', this.plan.id);
        },
    },
};
</script>
