import axios from 'axios';
import Vue from "vue";

const baseUrl = process.env.VUE_APP_BASE_END;

export const appointments = {
    namespaced: true,
    state: {
        appointments: {},
        verify_appointments: {}
    },
    getters: {},
    mutations: {
        setAppointments(state, payload) {
            Vue.set(state.appointments, payload.id, payload.value);
        },
        setVerifyAppointments(state, payload) {
            Vue.set(state.verify_appointments, payload.id, payload.value);
        }
    },
    actions: {
        async retrieveAppointments({commit, rootState}, payload) {

            const {patient} = payload;

            commit('setAppointments', {id: 'loading', value: true});
            commit('setAppointments', {id: 'error', value: false});

            try {
                const response = await axios.get(`${baseUrl}/v2/appointments/${rootState.orgId.data}/user/${patient}`);
                if ('data' in response) {
                    commit('setAppointments', {id: 'loading', value: false});
                    commit('setAppointments', {id: 'data', value: response.data});
                    return response.data;
                }
            } catch (error) {
                commit('setAppointments', {id: 'loading', value: false});
                commit('setAppointments', {id: 'error', value: true});
                commit('setAppointments', {id: 'data', value: null});

                if (axios.isAxiosError(error)) {
                    commit('setAppointments', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },
        async getVerifyAppointments({commit, rootState}, payload) {

            const {patient} = payload;

            commit('setVerifyAppointments', {id: 'loading', value: true});
            commit('setVerifyAppointments', {id: 'error', value: false});

            try {
                const response = await axios.get(`${baseUrl}/v2/appointments/${rootState.orgId.data}/user/${patient}`);
                if ('data' in response) {
                    commit('setVerifyAppointments', {id: 'data', value: response.data});
                    return response.data;
                }
            } catch (error) {
                commit('setVerifyAppointments', {id: 'error', value: true});
                commit('setVerifyAppointments', {id: 'data', value: null});

                if (axios.isAxiosError(error)) {
                    commit('setVerifyAppointments', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            } finally {
                commit('setVerifyAppointments', {id: 'loading', value: false});
            }
        }
    }

}
