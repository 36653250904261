<template>
    <div class="checkout__container">
        <checkout-nav
            :nav_action="showAction"
            @onAction="handleOpenShop"
        />
        <lab-checkout v-if="productType === 'lab'" :product-id="productId" @handleClose="handleOpenShop" />
    </div>
</template>
<script>
import { mapState } from 'vuex';

import CheckoutNav from '@/components/Checkout/CheckoutNav';
import LabCheckout from '@/components/Checkout/LabCheckout';

export default {
    name: 'CheckoutWrapper',
    components: {
        LabCheckout,
        CheckoutNav,
    },
    data() {
        return {
            productType: this.$route.params.productType,
            productId: this.$route.params.productId,
        };
    },
    created() {
        this.$nextTick(() => {
            this.productType = this.$route.params.productType;
        });
    },
    computed: {
        ...mapState({
            selectedProduct: (state) => state.productTemplates.selectedProduct,
        }),
        showAction() {
            const productTemplate = this.selectedProduct || JSON.parse(window.localStorage.getItem('zp_product_template'));

            return productTemplate && 'referrer' in productTemplate && productTemplate.referrer === 'shop' ? 'Return to Shop' : '';
        },
    },
    methods: {
        async handleOpenShop() {
            await this.$router.push({ path: '/shop' });
        },
    },
};
</script>
<style lang="scss">
.checkout {
    &__container {
        min-height: 100vh;
        background: #f9f9f9;
    }
}
</style>
