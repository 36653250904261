<template>
    <div class="copanel">
        <div class="copanel-container">
            <div class="copanel-container__top">
                <h-badge label="Checkout" color="#55D291" dark small />
                <button @click="handlePanelClose">
                    <DismissIcon />
                </button>
            </div>
            <div class="copanel-content">
                <div v-if="isRecurring" class="copanel-section">
                    <h2>Plan</h2>
                    <div class="copanel-plans">
                        <PlanCard
                            v-for="plan in planOptions"
                            :key="plan.id"
                            :plan="plan"
                            :active="selectedPlan === plan.id"
                            @select="setActivePlan"
                        />
                    </div>
                </div>
                <div class="copanel-section">
                    <h2>Summary</h2>
                    <SummaryItem
                        :product="product"
                        :recurring-duration="isRecurring ? recurringDuration : 0"
                        :price="selectedPlanPricing"
                    />
                </div>
                <div class="copanel-section">
                    <h2>Payment</h2>
                    <HFSABadge />
                    <div class="checkout-existing checkout-existing__payment">
                        <payment-option
                            ref="elementRef"
                            :has_card="!!hasStripeAccount"
                            :patient-card="patientCard"
                            :payment-error="paymentError"
                            @token="handleSubmitByProduct"
                            @event="onCardChange"
                            @edit-card="isEditingCard ? cancelCard() : newCard()"
                        />
                    </div>
                </div>
                <div class="copanel-section">
                    <h2>Shipping</h2>
                    <div v-if="!isNewShipping" class="copanel-ship">
                        <h3>Ship To</h3>
                        <div class="copanel-ship__container">
                            <div class="copanel-ship__address">
                                <img src="@/assets/icons/location.svg" alt="location-pin-icon">
                                <div>
                                    <div>{{ user.shipping_info.street_address }}</div>
                                    <div>{{ user.shipping_info.city }} {{ user.shipping_info.state }} {{ user.shipping_info.zip_code }}</div>
                                </div>
                            </div>
                            <div class="link-button" @click="toggleEditShipping">
                                Edit
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <AutoComplete
                            :shipping="user.shipping_info"
                            :show_button="false"
                            @shipping-change="onShippingChange"
                        />
                        <div v-if="hasAddress" class="link-button copanel-ship__edit" @click="toggleEditShipping">
                            Cancel
                        </div>
                    </div>
                </div>
                <div class="copanel-section">
                    <PriceBreakdown :price="selectedPlanPricing" />
                </div>
                <div v-if="orderError" class="copanel-section">
                    <HAlert status="critical" :message="orderError" :with-dismiss="false" />
                </div>
                <h-button
                    :loading="submitted"
                    :disabled="isButtonDisabled || disableButton"
                    full-width
                    @click="onSubmit"
                >
                    Submit
                </h-button>
            </div>
        </div>
        <div class="copanel-backdrop" @click="handlePanelClose"></div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import DismissIcon from '@/assets/icons/Dismiss.vue';
import AutoComplete from '@/components/intake/AutoComplete';
import HAlert from '@/components/Shared/HAlert';
import HBadge from '@/components/Shared/HBadge.vue';
import HButton from '@/components/Shared/HButtonV2.vue';
import HFSABadge from '@/components/Shared/HFSABadge.vue';
import { unavailableLabStates } from '@/constants/labUnavailableStates';
import { errorMsgMapping } from '@/helpers/labs';
import ProductService from '@/services/Product';

import PaymentOption from './PaymentOption.vue';
import PlanCard from './SubComponents/PlanCard.vue';
import PriceBreakdown from './SubComponents/PriceBreakdown.vue';
import SummaryItem from './SubComponents/SummaryItem.vue';

export default {
    name: 'LabCheckout',
    components: {
        HAlert,
        HBadge,
        HButton,
        DismissIcon,
        PaymentOption,
        AutoComplete,
        HFSABadge,
        PlanCard,
        PriceBreakdown,
        SummaryItem,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        productId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isNewShipping: false,
            new_states: [],
            publishable_key: process.env.VUE_APP_STRIPE_KEY,
            hasStripeAccount: false,
            hasExistingCard: false,
            isEditingCard: false,
            isValidCard: false,
            submitted: false,
            product: {},
            orderError: null,
            shipping_error: null,
            validations: null,
            shippingInfo: {
                state: null,
            },
            newOrder: null,
            disableButton: false,
            selectedPlan: null,
        };
    },
    watch: {
        open: {
            immediate: true,
            handler(val) {
                if (val) {
                    document.body.style.overflow = 'hidden';
                }
            },
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            patientCard: (state) => state.payment.patientCard.data,
            paymentError: (state) => state.payment.error,
            states: (state) => state.states.states,
        }),
        isRecurring() {
            return this.product?.products?.some(product => product.pricing.recurring.is_recurring);
        },
        recurringDuration() {
            const selectedPlan = this.planOptions.find(plan => plan.id === this.selectedPlan);

            return selectedPlan ? parseInt(selectedPlan.duration) : 0;
        },
        planOptions() {
            return this.getPlanOptions();
        },
        selectedPlanPricing() {
            const selectedPlanOption = this.planOptions.find(plan => plan.id === this.selectedPlan);

            if (!selectedPlanOption) {
                const startingTotal = this.product?.starting_price?.total || 0;
                const startingTotalDiscount = this.product?.starting_price?.total_discount || 0;
                const startingSavings = startingTotal - startingTotalDiscount;

                return {
                    subTotal: startingTotal,
                    savings: startingSavings,
                    total: startingTotalDiscount,
                };
            }

            const { price, savings = 0 } = selectedPlanOption;

            return {
                subTotal: price + savings,
                savings,
                total: price,
            };
        },
        hasAddress() {
            const shipping = this.user.shipping_info;

            return (
                shipping.street_address &&
                shipping.city &&
                shipping.state &&
                shipping.zip_code &&
                shipping.country &&
                shipping.phone_number
            );
        },
        isButtonDisabled() {
            const shippingInvalid = this.validations && this.validations.$invalid;

            return shippingInvalid || !this.isValidCard;
        },
    },
    async created() {
        this.paymentError.error = false;
        this.paymentError.error_msg = null;

        this.$nextTick(async () => {
            //init product
            this.initializeUser();
            await this.initializeProduct();
            await this.initializePaymentInfo();
            await this.populateStates();
            this.setInitialSelectedPlan();
        });
    },
    beforeDestroy() {
        if (this.$refs.elementRef && this.$refs.elementRef.getStripeRef()) {
            this.$refs.elementRef.getStripeRef()?.destroy?.();
        }
        this.handlePanelClose();
    },
    methods: {
        setInitialSelectedPlan() {
            if (this.selectedPlan === null && this.planOptions.length > 0) {
                const bestValuePlan = this.planOptions.find(plan => plan.highlight);

                this.selectedPlan = bestValuePlan ? bestValuePlan.id : this.planOptions[0].id;
            }
        },
        onShippingChange(shipping) {
            this.shippingInfo = shipping.data;
            this.validations = shipping.validations;
        },
        async initializeProduct() {
            if (this.productId) {
                //init product
                try {
                    const productTemplate = await this.getProductTemplate({ product_template_id: this.productId });

                    const productResult = await this.get_products({
                        product_template: productTemplate._id,
                    });
                    let membershipPrices = null;

                    if (productTemplate.membership) {
                        membershipPrices = this.get_membership_prices({
                            membership: productTemplate.membership._id,
                        });
                    }

                    //configure prices
                    this.product = this.configure_prices({
                        ...productTemplate,
                        products: productResult,
                        membership_prices: membershipPrices,
                    });

                } catch (error) {
                    console.error('init product', error);
                }
            }
        },
        async initializePaymentInfo() {
            this.hasStripeAccount = this.hasStripeProfile();

            if (this.hasStripeAccount) {
                try {
                    await this.getPatientCard({ user_id: this.user._id });
                    this.hasStripeAccount = true;
                    this.hasExistingCard = true;
                    this.isValidCard = true;
                } catch (error) {
                    this.hasStripeAccount = false;
                    this.hasExistingCard = false;
                    this.isValidCard = false;
                }
            }
        },
        initializeUser() {
            this.shippingInfo.first_name = this.user.first_name;
            this.shippingInfo.last_name = this.user.last_name;
            this.shippingInfo.mobile_number = this.user.mobile_number || '';

            if (!this.hasAddress) {
                this.isNewShipping = true;
            } else {
                this.shippingInfo = {
                    ...this.user.shipping_info,
                    mobile_number: this.user.mobile_number || '',
                };
                this.isNewShipping = false;
            }
        },
        async populateStates() {
            //get states list
            await this.retrieveStates();
            this.new_states = this.states.map((item) => {
                return { ...item, value: item.abbreviation };
            });
        },
        configure_prices(product) {
            const startingProductPrice = this.configureProducts(product.products).find((p) => p.product_category === product.product_category._id);
            const startingProduct = product.products.find((p) => p._id === startingProductPrice._id);
            let startingMembership;

            if (product.membership_prices) {
                startingMembership = product.membership_prices.sort((a, b) => a.unit_amount - b.unit_amount)[0];
            }
            product.starting_price = ProductService.generatePrice(startingProduct, product.dosage_frequencies[0], startingMembership, null);
            product.starting_price.interval_count = startingProduct.pricing.interval_count;

            return product;
        },
        configureProducts(products) {
            return products
                .map((p) => {
                    return {
                        cost: p.pricing.unit_amount,
                        _id: p._id,
                        product_category: p.product_category,
                    };
                })
                .sort((a, b) => a.cost - b.cost);
        },
        hasStripeProfile() {
            return this.user.stripe_id && this.user.stripe_profile && this.user.stripe_profile.stripe_customer.default_source;
        },
        newCard() {
            this.isEditingCard = true;
            this.hasStripeAccount = false;
            this.isValidCard = false;
        },
        cancelCard() {
            this.isEditingCard = false;
            this.hasStripeAccount = true;
            this.isValidCard = true;
            this.paymentError.error = false;
            this.$refs.elementRef.getStripeRef().clear();
        },
        onNewCardSubmit() {
            // this will trigger the process
            this.$refs.elementRef.getStripeRef().submit();
        },
        onCardChange(event) {
            this.isValidCard = Boolean(event.complete);

            if (event.error !== undefined) {
                this.paymentError.error = true;
                this.paymentError.error_msg = event.error.message;
            } else {
                this.paymentError.error = false;
            }
        },
        generateShippingError(validations) {
            const fields = [];

            if (validations.first_name.$invalid) {
                fields.push('First Name');
            }

            if (validations.last_name.$invalid) {
                fields.push('Last Name');
            }

            if (validations.street_address.$invalid) {
                fields.push('Street Address');
            }

            if (validations.city.$invalid) {
                fields.push('City');
            }

            if (validations.state.$invalid) {
                fields.push('State');
            }

            if (validations.zip_code.$invalid) {
                fields.push('Zip Code');
            }

            if (validations.phone_number.$invalid) {
                fields.push('Phone Number');
            }

            return `Please make sure the required fields are filled out. ${fields.join(', ')}`;

        },
        async createStripeAccount(token) {
            try {
                if (this.user.stripe_profile && this.user.stripe_profile.stripe_customer.default_source) {
                    const payload = {
                        token,
                        charge: false,
                        new_user: false,
                        charge_amount: null,
                        user: this.user,
                    };

                    await this.updateCreditCard(payload);
                } else {
                    const stripePayload = {
                        organization: this.user.organization,
                        user_id: this.user._id,
                        token,
                    };

                    await this.createBillingProfile(stripePayload);
                }
            } catch (error) {
                this.paymentError.error = true;
                this.paymentError.error_msg = error.message;
                this.submitted = false;
                throw error;
            }
        },
        async createOrder() {
            return await this.createPatientOrder({
                organization: this.user.organization,
                data: {
                    name: `${new Date().toLocaleDateString()}_${this.product.name}`.replaceAll('-', '_'),
                    product_template: this.product._id,
                    product: this.product.products.find(product => product.pricing.type === 'one_time')?._id,
                    quantity: 1,
                },
            });
        },
        async createSubscription() {
            await this.createSubscriptionByProduct({
                organization: this.user.organization,
                productId: this.selectedPlan,
                quantity: 1,
            });

            this.handlePanelClose();
        },
        async handleSubmitByProduct(token) {
            this.submitted = true;
            this.disableButton = true;
            this.paymentError.error = false;
            this.paymentError.error_msg = null;

            //create stripe account or update card info
            if (!this.hasExistingCard && !this.hasStripeAccount) {
                try {
                    await this.createStripeAccount(token);
                } catch (error) {
                    console.error('handleSubmitByProduct error', error);

                    return;
                }
            }

            const selectedProduct = this.product.products.find(product => product._id === this.selectedPlan);

            try {
                if (selectedProduct?.pricing.recurring.is_recurring) {
                    await this.createSubscription();
                    await this.$router.push({ path: `/dashboard/${this.user._id}/subscriptions` });
                } else {
                    // create &  submit order
                    if (!this.newOrder) {
                        this.newOrder = await this.createOrder();
                    }

                    await this.submitOrder({ organization: this.user.organization, order_id: this.newOrder._id });
                    await this.$router.push({ path: `/lab-instructions/${this.newOrder._id}` });
                }
                window.localStorage.removeItem('zp_product_template');
            } catch (error) {
                this.submitted = false;
                this.disableButton = false;

                if (selectedProduct?.pricing.recurring.is_recurring) return;

                const matchResult = error.message.match(/Cause: (.*)/);
                const detail = matchResult ? JSON.parse(matchResult[1]).detail : null;

                this.orderError = errorMsgMapping[detail] || 'There was an error submitting your order.';
            }
        },
        async onSubmit(token) {
            this.shipping_error = null;

            if (this.isNewShipping) {
                if (this.validations && this.validations.$invalid) {
                    this.submitted = false;
                    this.shipping_error = this.generateShippingError(this.validations);

                    return;
                }

                const { mobile_number: mobileNumber, ...shippingInfo } = this.shippingInfo;

                const payload = {
                    _id: this.user._id,
                    shipping_info: shippingInfo,
                    mobile_number: mobileNumber || shippingInfo.phone_number,
                };

                try {
                    await this.updateUser(payload);
                } catch (e) {
                    this.submitted = false;
                    this.orderError = e.message;
                }
            }

            const userState = this.shippingInfo && this.shippingInfo.state;
            // eslint-disable-next-line camelcase
            const stateUnavailable = unavailableLabStates.some(state => state.abbreviation === userState);

            if (stateUnavailable) {
                this.submitted = false;
                this.orderError = 'Due to regulatory restrictions prohibiting the online prescribing of lab testing, we cannot provide lab testing in NY, NJ and RI.';

                return;
            }

            if (this.hasExistingCard && this.hasStripeAccount) {
                await this.handleSubmitByProduct(token);
            } else {
                await this.onNewCardSubmit();
            }
        },
        setActivePlan(id) {
            this.selectedPlan = id;
            this.orderError = null;
        },
        toggleEditShipping() {
            this.isNewShipping = !this.isNewShipping;
        },
        handlePanelClose() {
            document.body.style.overflow = 'unset';
            this.$emit('handleClose');
        },
        ...mapActions({
            getUser: 'user/retrieveUser',
            updateUser: 'user/updateUser',
            getPatientCard: 'payment/getPatientCard',
            updateCreditCard: 'payment/updateCreditCard',
            createBillingProfile: 'user/createBillingProfile',
            retrieveStates: 'states/retrieveStates',
            getProductTemplate: 'products/getProductTemplate',
            get_products: 'products/getProducts',
            createPatientOrder: 'orders/createPatientOrder',
            submitOrder: 'orders/submitOrder',
            get_membership_prices: 'memberships/getMembershipPrices',
            createSubscriptionByProduct: 'subscriptions/createSubscriptionByProduct',
        }),
        ...mapGetters({
            getPlanOptions: 'products/getPlanOptions',
        }),
    },
};
</script>
