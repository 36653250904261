export default {
    initSignupConfiguration(intakeTemplate, params) {
        let config = {};

        if (!intakeTemplate) {
            const productType = params.productType;
            const productId = params.productId;

            if (productType && productType === 'lab') {
                config = {
                    intake: {
                        hasIntake: false,
                    },
                    membership: {
                        hasMembership: false,
                    },
                    product: {
                        noProducts: true,
                    },
                    lab: {
                        product: productId,
                    },
                };
            }

            if (productType && productType === 'medication') {
                config = {
                    intake: {
                        hasIntake: false,
                    },
                    membership: {
                        hasMembership: false,
                    },
                    product: {
                        firstProductId: productId,
                    },
                };
            }

            if (productType && productType === 'membership') {
                config = {
                    intake: {
                        hasIntake: false,
                    },
                    membership: {
                        hasMembership: true,
                    },
                    product: {
                        noProducts: true,
                    },
                };
            }
        } else {
            config = {
                intake: {
                    hasIntake: true,
                    id: intakeTemplate._id,
                },
                membership: {
                    hasMembership: intakeTemplate.plan_checkout_options.membership_choice_step,
                },
                product: {
                    main_products: intakeTemplate.product_defaults,
                    addon_products: intakeTemplate.product_addons,
                    bundle_support: intakeTemplate.bundle_support,
                },
                lab: {
                    product: params ? params.labProductId : null,
                },
            };

            if (params && 'productTemplateId' in params) {
                if (params.productTemplateId) {
                    config.product['default'] = params.productTemplateId;
                }
            } else {
                config.product['default'] = intakeTemplate.product_templates[0];
            }
        }

        const converted = JSON.stringify(config);

        window.localStorage.setItem('signup-config', converted);
        window.localStorage.setItem('intake_template', JSON.stringify(intakeTemplate));

        return config;
    },

    clearSignupStorage() {
        window.localStorage.removeItem('signup-config');
        window.localStorage.removeItem('intake_template');
        window.localStorage.removeItem('selected_products');
        window.localStorage.removeItem('default_item');
    },

};
