<template>
    <div class="settings-section__content settings-shipping">
        <h-text-input-secondary
            id="first_name"
            v-model="shipping_info.first_name"
            name="first_name"
            label="First Name"
            :vuelidate="$v.shipping_info.first_name"
            :errors="[$v.shipping_info.first_name.$invalid && $v.shipping_info.first_name.$dirty && errorMessages.FIRST_NAME_EMPTY]"
        />
        <h-text-input-secondary
            id="last_name"
            v-model="shipping_info.last_name"
            name="last_name"
            label="Last Name"
            :vuelidate="$v.shipping_info.last_name"
            :errors="[$v.shipping_info.last_name.$invalid && $v.shipping_info.last_name.$dirty && errorMessages.LAST_NAME_EMPTY]"
        />
        <h-text-input-secondary
            id="autocomplete"
            ref="autocompleteInput"
            v-model="shipping_info.street_address"
            name="street_address"
            label="Street Address"
            :vuelidate="$v.shipping_info.street_address"
            :errors="[
                !$v.shipping_info.street_address.required && $v.shipping_info.street_address.$dirty && errorMessages.STREET_ADDRESS_EMPTY,
                !$v.shipping_info.street_address.maxLength && $v.shipping_info.street_address.$dirty && errorMessages.STREET_ADDRESS_LONG,
            ]"
        />
        <h-text-input-secondary
            id="apt_suite"
            v-model="shipping_info.apt_suite"
            name="apt_suite"
            label="Apt, Suite"
            :vuelidate="$v.shipping_info.apt_suite"
            :errors="[!$v.shipping_info.apt_suite.maxLength && $v.shipping_info.apt_suite.$dirty && errorMessages.APT_LONG]"
        />
        <h-text-input-secondary
            id="city"
            v-model="shipping_info.city"
            name="city"
            label="City"
            :vuelidate="$v.shipping_info.city"
            :errors="[
                !$v.shipping_info.city.required && $v.shipping_info.city.$dirty && errorMessages.CITY_EMPTY,
                !$v.shipping_info.city.maxLength && $v.shipping_info.city.$dirty && errorMessages.CITY_LONG,
            ]"
        />
        <div class="settings-shipping__flex">
            <h-dropdown-secondary
                v-model="shipping_info.state"
                label="State"
                :options="new_states"
                placeholder="Select State"
                :selected="shipping_info.state"
                :vuelidate="$v.shipping_info.state"
                :errors="[!$v.shipping_info.state.required && $v.shipping_info.state.$dirty && errorMessages.STATE_REQUIRED]"
            />
            <h-text-input-secondary
                id="zip_code"
                v-model="shipping_info.zip_code"
                name="zip_code"
                label="Zip Code"
                :vuelidate="$v.shipping_info.zip_code"
                :errors="[$v.shipping_info.zip_code.$invalid && $v.shipping_info.zip_code.$dirty && errorMessages.ZIP_INVALID]"
            />
        </div>
        <h-text-input-secondary
            id="country"
            v-model="shipping_info.country"
            name="country"
            label="Country"
            disabled
            :vuelidate="$v.shipping_info.country"
            :errors="[$v.shipping_info.country.$invalid && $v.shipping_info.country.$dirty && errorMessages.COUNTRY_EMPTY]"
        />
        <h-text-input-secondary
            id="phone_number"
            ref="phoneNumberInput"
            v-model="shipping_info.phone_number"
            name="phone_number"
            label="Phone Number"
            :vuelidate="$v.shipping_info.phone_number"
            mask="(###) ###-####"
            :errors="[$v.shipping_info.phone_number.$invalid && $v.shipping_info.phone_number.$dirty && errorMessages.PHONE_EMPTY]"
        />
    </div>
</template>
  
<script>
import { mapActions, mapState } from 'vuex';

import HDropdownSecondary from '@/components/Shared/HDropdownSecondary';
import HTextInputSecondary from '@/components/Shared/HTextInputSecondary';
import { initMap, loadGoogleMaps } from '@/helpers/googlemaps';
import { addressValidation, aptValidation, errorMessages, phoneValidation, requiredValidation, zipValidation } from '@/helpers/validations';

export default {
    name: 'ShippingAddress',
    components: {
        HTextInputSecondary,
        HDropdownSecondary,
    },
    props: {
        shipping: {
            type: Object,
            required: true,
            default: () => ({
                first_name: '',
                last_name: '',
                street_address: '',
                apt_suite: '',
                city: '',
                state: '',
                zip_code: '',
                country: 'us',
                phone_number: '',
            }),
        },
    },
    data() {
        return {
            shipping_info: {
                first_name: '',
                last_name: '',
                street_address: '',
                apt_suite: '',
                city: '',
                state: '',
                zip_code: '',
                country: 'us',
                phone_number: '',
            },
            new_states: [],
            errorMessages,
            api_key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        };
    },
    validations: {
        shipping_info: {
            first_name: requiredValidation,
            last_name: requiredValidation,
            street_address: addressValidation,
            apt_suite: aptValidation,
            city: addressValidation,
            state: requiredValidation,
            zip_code: zipValidation,
            country: requiredValidation,
            phone_number: phoneValidation,
        },
    },
    computed: {
        ...mapState({
            states: (state) => state.states.states,
        }),
    },
    watch: {
        shipping_info: {
            handler() {
                this.onShippingChange();
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        this.initializeGoogleMaps();
    },
    created() {
        this.shipping_info = this.shipping;
        this.populateStates();
        this.onShippingChange();
    },
    methods: {
        async populateStates() {
            const states = await this.retrieveStates();
            
            this.new_states = states.map(item => ({
                ...item,
                value: item.abbreviation,
            }));
        },
        async initializeGoogleMaps() {
            if (!window.google || !window.google.maps) {
                const googleMapsCallback = () => {
                    initMap(this.$refs.autocompleteInput, this.onInputFocus, this);
                };

                await loadGoogleMaps(this.api_key, googleMapsCallback);
            } else {
                initMap(this.$refs.autocompleteInput, this.onInputFocus, this);
            }
        },
        onInputFocus() {
            this.$refs.phoneNumberInput.$refs.input_element.focus();
        },
        onShippingChange() {
            this.$emit('shipping-change', {
                data: this.shipping_info,
                validations: this.$v.shipping_info,
            });
        },
        ...mapActions({
            retrieveStates: 'states/retrieveStates',
        }),
    },
};
</script>
