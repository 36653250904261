<template>
<div class="input-white-container-size">
    <div class="input-white-container">
        <div class="flex-row">
            <h4 class="h4-account">Profile</h4>
            <div class="open-container">
                <button class="transparent-button" @click="openContainer()">
                        <span class="down-arrow-container">
                            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path
                                class="icon-styleable-color"
                                d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path><path d="M0 0h24v24H0V0z"
                                                                                                        fill="none"></path></svg>
                        </span>
                </button>
            </div>
        </div>

        <collapse-transition dimension="height">
            <div v-show="open" class="form-block">
                <div v-if="!open_edit" class="profile_static">
                    <div class="mb2r">
                        <div class="basic-info-container">
                            <div class="basic-info-label">Name</div>
                            <div class="basic-info-value">{{ userData.first_name }} {{ userData.last_name }}</div>
                        </div>
                        <div class="basic-info-container">
                            <div class="basic-info-label">Email</div>
                            <div class="basic-info-value">{{ userData.email }}</div>
                        </div>

                        <div class="basic-info-container">
                                <div class="basic-info-label">
                                    Mobile
                                </div>
                                <div class="basic-info-value">
                                    {{ userData.mobile_number }}
                                </div>
                        </div>

                        <div class="basic-info-container">
                            <div class="basic-info-label">Birthday</div>
                            <div class="basic-info-value">{{ userData.birthday }}</div>
                        </div>
                    </div>
                    <button class="edit-button-dashboard" type="button" @click="openEdit()">
                        <img class="edit-icon" src="../../assets/icons/pencil.png"/>
                        EDIT
                    </button>
                </div>

                <form v-if="open_edit" @submit.prevent="updateUser()">
                    <div class="input-style-block">
                        <div class="input-style-inner">

                            <label>First Name</label>
                            <input v-model="originalData.first_name" @input="$v.originalData.first_name.$touch" class="clear-input"
                                   v-bind:class="{ 'error-border' : $v.originalData.first_name.$invalid && $v.originalData.first_name.$dirty}"/>
                            <div class="text-field-style"></div>
                            <div class="error-msg-field">
                                <span v-if="!$v.originalData.first_name.required && $v.originalData.first_name.$dirty" class="error-msg-field">Please enter your first name.</span>
                                <span v-if="!$v.originalData.first_name.maxLength && $v.originalData.first_name.$dirty"
                                    class="error-msg-field">First name must be less than 35 characters long.</span>
                            </div>
                        </div>
                    </div>
                    <div class="input-style-block">
                        <div class="input-style-inner">
                            <label>Last Name</label>
                            <input v-model="originalData.last_name" @input="$v.originalData.last_name.$touch" class="clear-input"
                                   v-bind:class="{ 'error-border' : $v.originalData.last_name.$invalid && $v.originalData.last_name.$dirty}"/>
                            <div class="text-field-style"></div>
                            <div class="error-msg-field">
                                <span v-if="!$v.originalData.last_name.required && $v.originalData.last_name.$dirty" class="error-msg-field">Please enter your first name.</span>
                                <span v-if="!$v.originalData.last_name.maxLength && $v.originalData.last_name.$dirty"
                                    class="error-msg-field">Last name must be less than 35 characters long.</span>
                            </div>
                        </div>
                    </div>

                    <div class="input-style-block">
                        <div class="input-style-inner">
                                <label>Mobile Number</label>
                                <input
                                    v-model="originalData.mobile_number"
                                    v-mask="'(###) ###-####'"
                                    class="clear-input"
                                    :class="{
                                        'error-border': $v.originalData.mobile_number.$invalid && $v.originalData.mobile_number.$dirty
                                    }"
                                    @input="$v.originalData.mobile_number.$touch"
                                />
                                <div class="text-field-style" />
                                <div class="error-msg-field">
                                    <span v-if="$v.originalData.mobile_number.$invalid && $v.originalData.mobile_number.$dirty">
                                        Please enter a valid contact number.
                                    </span>
                                </div>
                        </div>
                    </div>

                    <div class="input-style-block">
                        <div class="input-style-inner">
                            <label>Email</label>
                            <input v-model="originalData.email" @input="$v.originalData.email.$touch" class="clear-input"
                                   v-bind:class="{ 'error-border' : $v.originalData.email.$invalid && $v.originalData.email.$dirty }"/>
                            <div class="text-field-style"></div>
                            <div class="error-msg-field">
                                <span v-if="$v.originalData.email.$invalid">Please enter a valid email address.</span>
                            </div>
                        </div>
                    </div>


                    <div class="input-style-block">
                        <div class="input-style-inner">
                            <label>Birthday</label>
                            <input v-model="userData.birthday" class="clear-input" disabled/>
                            <div class="text-field-style"></div>
                            <div class="error-msg-field"></div>
                        </div>
                    </div>

                    <div class="submit-button-container">
                        <div class="input-style-block">
                            <button :disabled="$v.$invalid" class="dashboard-button black-bg" type="submit">{{ save_button }}</button>

                        </div>

                        <button class="cancel-button" type="button" @click="cancelEdit()">
                            <img src="@/assets/icons/close-icon.svg"/>Cancel
                        </button>
                    </div>


                </form>
            </div>

        </collapse-transition>
    </div>
</div>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition'
import {mapState, mapMutations, mapActions} from 'vuex';
import {required, minLength, between, email, maxLength} from 'vuelidate/lib/validators';

export default {
    name: 'profile-settings',
    components: {
        CollapseTransition: CollapseTransition
    },
    data() {
        return {
            open: true,
            open_edit: false,
            save_button: 'Save Changes',
            err_status: {
                isErr: false,
                err_msg: null
            },
            //this is a copy of userData.user
            originalData: {}

        }
    },
    validations: {
        originalData: {
            email: {required, email},
            first_name: {required, maxLength: maxLength(35)},
            last_name: {required, maxLength: maxLength(35)},
            mobile_number: { minLength: minLength(14) },
        }
    },
    methods: {

        updateUser() {
            let vm = this;

            //trigger flag that the data has been submitted
            this.submitted = true;

            //if the form is invalid disable button
            this.$v.$touch();
            if (this.$v.$invalid) {

                return;
            }
            //change the text on the button
            this.save_button = 'Saving';

            this.saveUser(this.originalData).then(response => {
                vm.save_button = 'Saved';
                vm.open_edit = false;
            }).catch(err => {
                console.log(err);
            })


        },
        openContainer() {
            this.open = !this.open;
        },
        openEdit() {
            this.open_edit = !this.open_edit;

        },
        cancelEdit() {
            this.open_edit = !this.open_edit;
            let user_object_new = Object.assign({}, this.userData);
            this.originalData = user_object_new;
            this.$v.originalData.$reset()
        },
        ...mapActions({
            saveUser: 'user/updateUser',
        }),

    },
    created() {
        //we need to make a copy of the original data
        //so that when we edit the data7
        let user_object_new = Object.assign({}, this.userData);
        this.originalData = user_object_new;
    },
    computed: {
        ...mapState({
            userData: state => state.user.user,
            duplicate_email: state => state.user.duplicate_email
        }),
    }
}
</script>