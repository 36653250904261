<template>
    <div class="subscription-modal__container">
        <modal-header-icon icon="chatbubbles" :title="'Mobile Verification'" @onClose="on_close"></modal-header-icon>

        <div class="subscription-modal__body">
            <p v-if="inputMobileNumber" class="ppe-small-title">Please verify your mobile number</p>
            <p v-if="inputVerificationCode" class="ppe-small-title">Please enter the 5 digit verification code sent to
                {{ mobileNumber }}</p>
            <div class="subscription-modal__form">
                <div v-if="inputMobileNumber" class="text-input-container">
                    <input id="mobile" v-model="mobileNumber" v-mask="'(###) ###-####'" class="textfield-consult"
                           name="mobile" type="text"/>
                    <label class="label-signup" for="mobile" v-bind:class="{'has-text' : mobileNumber}">Enter Mobile
                        Number</label>
                    <span class="error-msg-field" v-if="validateError">{{ validateErrorText || 'There was an error' }}</span>
                </div>
                <div v-if="inputVerificationCode" class="text-input-container">
                    <input id="verify" v-model="verificationCode" v-mask="'#####'" class="textfield-consult"
                           name="verify" type="text"/>
                    <label class="label-signup" for="verify" v-bind:class="{'has-text' : verificationCode}">Enter
                        Verification Code</label>
                    <span class="error-msg-field" v-if="validateError">{{ validateErrorText || 'There was an error' }}</span>
                </div>
                <button v-if="inputMobileNumber" class="pill-button black-btn" @click="submitNumber">
                    Submit
                </button>
                <button v-if="inputVerificationCode" class="pill-button black-btn"
                        @click="submitVerificationCode">Verify
                </button>
            </div>

        </div>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import parsePhoneNumber from "libphonenumber-js";
import ModalHeaderIcon from "@/components/Dashboard/Modals/Components/ModalHeaderIcon.vue";

export default {
    name: 'MobileVerificationModal',
    components: {
        ModalHeaderIcon: ModalHeaderIcon
    },
    props: [''],
    data() {
        return {
            mobileNumber: null,
            verificationCode: null,
            inputMobileNumber: true,
            inputVerificationCode: false,
            validateError: false,
            validateErrorText: null
        }
    },
    created() {
        this.mobileNumber = this.user.mobile_number ?? null;
    },
    computed: {
        ...mapState({
            organization: state => state.organization.data,
            user: state => state.user.user
        })
    },
    methods: {
        ...mapActions({
            getUser: 'user/retrieveUser',
            sendVerificationCode: 'user/sendVerificationCode',
            verifyMobileNumber: 'user/verifyMobile',
        }),
        on_close() {
          this.$emit('onModalClose', true);
        },
        submitNumber() {
            if (this.mobileNumber && this.mobileNumber.length > 2) {
                this.validateError = false;
                const formattedNumber = parsePhoneNumber(this.mobileNumber, 'US');
                if (formattedNumber.isValid()) {
                    this.sendVerificationCode({number: formattedNumber.number}).then(response => {
                        this.inputMobileNumber = false;
                        this.inputVerificationCode = true;
                    }).catch(err => {
                        this.validateErrorText = 'There was an error, please try again.';
                        this.validateError = true;
                    })
                } else {
                    this.validateErrorText = 'The number you entered is incorrect.  Please try again.';
                    this.validateError = true;
                }

            }
        },
        async submitVerificationCode() {
            this.validateError = false;
            if (this.verificationCode) {
                try {
                    await this.verifyMobileNumber({verification_code: this.verificationCode});
                    await this.getUser({user: this.user._id});
                    this.$emit('onModalClose', true);
                    this.$emit('onSuccess', true);
                    this.$forceUpdate();
                } catch (err) {
                    this.validateErrorText = 'The verification code you entered is incorrect. Please try again.';
                    this.validateError = true;
                }

            }
        }
    }
}
</script>
<style lang="scss"></style>
