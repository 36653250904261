<template>
    <div>
        <div class="sidenav-verification">
            <VerificationBadge sidebar collapse />
        </div>
        <div v-click-outside="handleUserMenuClose" class="sidenav-user" @click="handleUserMenu">
            <div class="sidenav-user__avatar">
                <img :src="profileImage.profile_url ? profileImageSrc : defaultAvatar" :alt="profileImage ? 'profile image' : 'default-avatar'">
            </div>
            <div class="sidenav-profile">
                <div class="sidenav-profile__info">
                    <h4 ref="name" :class="{'scroll': isNameLong}">
                        {{ user?.first_name + ' ' + user?.last_name }}
                    </h4>
                    <span ref="email" class="text-dim-60 email" :class="{'scroll': isEmailLong}">
                        {{ user?.email }}
                    </span>
                </div>
                <ChevronRight />
                <user-menu :open="userMenuOpen" :user-id="user?._id" />
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapState } from 'vuex';

import ChevronRight from '@/assets/icons/ChevronRight.vue';

import VerificationBadge from '../../VerificationBadge.vue';
import UserMenu from './UserMenu.vue';

export default {
    name: 'UserProfile',
    components: {
        ChevronRight,
        UserMenu,
        VerificationBadge,
    },
    directives: { ClickOutside },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            profileImage: null,
            userMenuOpen: false,
            defaultAvatar: require('@/assets/icons/profile.svg'),
            imgSrcWithTimestamp: '',
        };
    },
    computed: {
        ...mapState({
            updatedProfileImage: state => state.user.updatedProfileImage,
        }),
        profileImageSrc() {
            return `${this.profileImage.profile_url}?t=${this.profileImage.date_uploaded}`;
        },
        isNameLong() {
            const { first_name: firstName, last_name: lastName } = this.user || {};
            
            return (firstName?.length + lastName?.length) > 21;
        },
        isEmailLong() {
            const { email } = this.user || {};

            return email?.length > 21;
        },
    },
    watch: {
        updatedProfileImage(newVal) {
            if (newVal) {
                this.profileImage = this.user.image?.profile;
            }
        },
    },
    created() {
        this.profileImage = this.user.image?.profile; 
    },
    methods: {
        handleUserMenu() {
            this.userMenuOpen = !this.userMenuOpen;
        },
        handleUserMenuClose() {
            this.userMenuOpen = false;
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';

.sidenav-verification {
    padding: 1rem 0;

    .verification-container {
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }
}

.sidenav-user {
    position: relative;
    display: flex;
    padding: 0.75rem 0 1.875rem;
    cursor: pointer;

    &__avatar {
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;  
        }
    }
}

.sidenav-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 0;
    overflow: hidden;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    margin-left: 0;

    &__info {
        width: 142px;
        height: 40px;
        overflow: hidden;

        h4 {
            @include caption;
            white-space: nowrap;
            margin: 0;
        }

        .email {
            @include caption-sm;
            white-space: nowrap;
            display: block;
            transition: transform 0.3s ease; 
        }

        .scroll:hover {
            animation: scrollText 5s linear infinite; 

            @keyframes scrollText {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(-100%);
                }
            }
        }
    }
}
</style>
