<template>
    <div>
        <Nav stage="5" />
        <div class="hcheckout">
            <div class="checkout-details">
                <section class="checkout-details__section">
                    <div class="hcheckout__header checkout-details__shipping-action">
                        <div>
                            <h2 data-cypress="checkout-header">
                                Where should we send your medication?
                            </h2>
                            <p v-if="!existing_address">
                                Enter your name and address:
                            </p>
                        </div>
                        <div v-if="hasAddress() && show_address_form" class="checkout-details__action link-button" @click="showNewAddress()">
                            Cancel
                        </div>
                    </div>
                    <div v-if="existing_address" class="checkout-existing">
                        <div class="checkout-existing__address">
                            <img src="@/assets/icons/location.svg" alt="">
                            <div>
                                <div>{{ user.shipping_info.street_address }}</div>
                                <div>{{ user.shipping_info.city }} {{ user.shipping_info.state }} {{ user.shipping_info.zip_code }}</div>
                            </div>
                        </div>
                        <div class="link-button" @click="showNewAddress(existing_address)">
                            <span>{{ show_address_form ? 'Cancel' : 'Edit' }}</span>
                        </div>
                    </div>
                    <div v-if="show_address_form" class="checkout-details__shipping">
                        <AutoComplete
                            :shipping="user.shipping_info"
                            :loading="loading"
                            :show_button="false"
                            @shipping-change="onShippingChange"
                        />
                    </div>
                </section>
                <section class="checkout-details__section">
                    <div class="hcheckout__header">
                        <h2>Payment Information</h2>
                        <p>
                            You will not be charged for or shipped any medication until approved by your doctor.
                        </p>
                    </div>
                    <div>
                        <HFSABadge />
                        <div class="checkout-existing checkout-existing__payment">
                            <payment-option
                                ref="elementRef"
                                :has_card="hasCard"
                                :patient-card="patientCard"
                                :payment-error="paymentError"
                                @token="onSignupSubmit"
                                @event="cardChange"
                                @edit-card="card_form ? cancelCard() : newCard()"
                            />
                        </div>
                    </div>
                </section>
                <div class="mobile-hidden checkout-details__section">
                    <h-button
                        label="Submit"
                        variant="primary"
                        :disabled="buttonDisabled() || disableButton"
                        :loading="is_submitting"
                        :handle-click="onButtonSubmit"
                        cta
                        full_width
                        large
                    />
                </div>
                <div v-if="features" class="protocol__side-benefits mobile-hidden">
                    <Benefits :features="features" />
                </div>
                <div class="checkout-benefits mobile-hidden">
                    <h-Certification />
                </div>
            </div>
            <div class="checkout-summary">
                <div class="hcheckout__header">
                    <h2>Summary</h2>
                </div>
                <div class="checkout-summary__section">
                    <div class="checkout-summary__section-header">
                        Protocol
                    </div>
                    <summary-item
                        v-for="(product) in filteredProtocols"
                        :key="product._id"
                        :item="product"
                        :expand_membership="false"
                    />
                </div>
                <div v-if="filteredAddons && filteredAddons.length" class="checkout-summary__section">
                    <div class="checkout-summary__section-header">
                        Addons
                    </div>
                    <summary-item
                        v-for="(product) in filteredAddons"
                        :key="product._id"
                        :item="product"
                        :expand_membership="false"
                        :addon="true"
                        @onRemoveItem="removeAddon(product)"
                    />
                </div>
                <div v-if="recommendationRequested" class="checkout-summary__section">
                    You have requested {{ recommendationRequested }} we will provide you with a more detailed treatment dosage recommendation based on your feedback and once your provider has reviewed your health history.
                </div>
                <summary-total v-if="!recommendationRequested" :intake_template="intakeTemplate.data" :products="selectedProducts" @onPricingGenerated="updatePricing" />
                <div class="desktop-hidden checkout-details__section">
                    <h-button
                        label="Submit"
                        variant="primary"
                        :disabled="buttonDisabled() || disableButton"
                        :loading="is_submitting"
                        :handle-click="onButtonSubmit"
                        cta
                        full_width
                        large
                    />
                </div>
            </div>
            <div v-if="features" class="protocol__side-benefits desktop-hidden">
                <Benefits :features="features" />
            </div>
            <div class="checkout-benefits desktop-hidden">
                <h-Certification />
            </div>
        </div>
    </div>
</template>

<script>
import LogRocket from 'logrocket';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import Benefits from '@/components/Auth/Benefits.vue';
import PaymentOption from '@/components/Checkout/PaymentOption.vue';
import Nav from '@/components/Consultation/Nav.vue';
import AutoComplete from '@/components/intake/AutoComplete';
import HButton from '@/components/Shared/HButton.vue';
import HCertification from '@/components/Shared/HCertification.vue';
import HFSABadge from '@/components/Shared/HFSABadge.vue';
import SummaryItem from '@/components/Summary/SummaryItem.vue';
import SummaryTotal from '@/components/Summary/SummaryTotal.vue';
import { tags } from '@/constants/mailchimpTags';
import { hasStripeProfile } from '@/domains/user/lib/checkUserVerification';
import { analytics, analyticsActions } from '@/helpers/analytics';
import IntakeHelpers from '@/helpers/intake';

export default {
    components: {
        Nav,
        SummaryItem,
        SummaryTotal,
        HButton,
        HCertification,
        HFSABadge,
        AutoComplete,
        PaymentOption,
        Benefits,
    },
    data() {
        return {
            submitted: false,
            submit_button_status: 'Submit',
            is_submitting: false,
            card_is_valid: false,
            selectedProducts: null,
            quantity: null,
            membershipConfig: null,
            stripe_id: null,
            noProduct: null,
            hasStripeAccount: false,
            hasCard: false,
            valid_new_card: false,
            labPrice: null,
            selectedProductsDetails: null,
            stripe_token: null,
            product_panel: false,
            publishable_key: process.env.VUE_APP_STRIPE_KEY,
            pricing: null,
            loading: false,
            shipping_info: {},
            existing_address: false,
            show_address_form: false,
            new_card: false,
            card_form: false,
            validations: null,
            disableButton: false,
        };
    },
    async created() {
        analytics.track(analyticsActions.payment_opened);
        this.$nextTick(async () => {
            const signupConfig = JSON.parse(window.localStorage.getItem('signup-config'));

            this.selectedProducts = JSON.parse(window.localStorage.getItem('selected_products'));
            this.noProduct = !signupConfig.product.main_products.length;
            this.membershipConfig = JSON.parse(window.localStorage.getItem('membership'));
            this.hasStripeAccount = hasStripeProfile(this.user);
            this.card_is_valid = hasStripeProfile(this.user);
            this.new_card = !hasStripeProfile(this.user);
            this.initializeUser();

            await this.getUser({
                user: this.user._id,
            });

            try {

                if (this.mailchimp_integration && this.user.mailchimp_id) {
                    await this.add_subscriber_tags({
                        tag: {
                            name: tags.status.checkout.incomplete,
                            status: 'active',
                        },
                        patient: this.user._id,
                    });
                }
            } catch (error) {
                console.error('sendNotification error', error);
            }

            if (this.hasStripeAccount) {
                this.getPatientCard({ user_id: this.user._id }).then(() => {
                    if (this.patientCard) {
                        this.hasCard = true;
                    }
                }).catch(() => {
                    this.hasStripeAccount = false;
                });
            }

            if (this.membershipConfig) {
                this.getMembership({ membership_id: this.membershipConfig.membership_id }).then(() => {
                    this.getMembershipPrice({ price_id: this.membershipConfig.price_id });
                });
            }

        });
    },
    beforeDestroy() {
        if (this.$refs.elementRef) {
            this.$refs.elementRef?.destroy?.();
        }
    },
    methods: {
        initializeUser() {
            this.shipping_info.first_name = this.user.first_name;
            this.shipping_info.last_name = this.user.last_name;
            this.shipping_info.mobile_number = this.user.mobile_number || '';

            if (!this.hasAddress()) {
                this.existing_address = false;
                this.show_address_form = true;
            } else {
                this.shipping_info = {
                    ...this.user.shipping_info,
                    mobile_number: this.user.mobile_number || '',
                };
                this.show_address_form = false;
                this.existing_address = true;
            }
        },
        showNewAddress(show) {
            if (show) {
                this.existing_address = false;
                this.show_address_form = true;
            } else {
                this.existing_address = true;
                this.show_address_form = false;
            }

        },
        async removeAddon(product) {
            const products = JSON.parse(window.localStorage.getItem('selected_products')) || [];

            const selectedProducts = products.filter((item) => item._id === product._id);
            const remainingProducts = products.filter((item) => item._id !== product._id);

            await this.removeAddonProducts({
                selectedProducts,
                patient_id: this.user._id,
            });

            window.localStorage.setItem('selected_products', JSON.stringify(remainingProducts));

            this.selectedProducts = remainingProducts;
        },
        newCard() {
            this.new_card = true;
            this.card_form = true;
            this.hasStripeAccount = false;
            this.hasCard = false;
            this.valid_new_card = false;
        },
        cancelCard() {
            this.new_card = false;
            this.hasCard = true;
            this.card_form = false;
            this.hasStripeAccount = true;
            this.paymentError.error = false;
            this.$refs.elementRef.getStripeRef().clear();
        },
        hasAddress() {
            const shipping = this.user.shipping_info;

            return shipping.street_address &&
            shipping.city &&
            shipping.state &&
            shipping.zip_code &&
            shipping.country &&
            shipping.phone_number;
        },
        updatePricing(pricing) {
            this.pricing = pricing;
        },
        hasMembership(membership) {
            if (!membership) {
                return false;
            }

            if (this.user && this.user.membership) {
                return membership._id === this.user.membership.id;
            } else {
                return false;
            }
        },
        is_bundle(productTemplateId) {
            return IntakeHelpers.isBundle(this.intakeTemplate.data, productTemplateId);
        },
        async createStripeAccount(token) {
            if (!this.hasStripeAccount) {
                try {
                    const stripePayload = {
                        organization: this.user.organization,
                        user_id: this.user._id,
                        token,
                    };

                    if (typeof Rewardful !== 'undefined') {
                        // eslint-disable-next-line no-undef
                        stripePayload['referral_id'] = Rewardful.referral;
                        // eslint-disable-next-line no-undef
                        stripePayload['affiliate_token'] = Rewardful.affiliate.token;
                    }

                    if (this.user.stripe_profile && this.user.stripe_profile.stripe_customer.default_source) {
                        const payload = {
                            token,
                            charge: false,
                            new_user: false,
                            charge_amount: null,
                            user: this.user,
                        };

                        await this.updateCreditCard(payload);
                    } else {
                        await this.createBillingProfile(stripePayload);
                    }

                    return await this.getUser({
                        user: this.user._id,
                    });
                } catch (e) {
                    if ('response' in e) {
                        throw {
                            type: 'PAYMENT',
                            error: e.response.data,
                        };
                    } else {
                        throw e;
                    }
                }
            } else {
                return this.user;
            }
        },
        async startMembership() {
            if (this.membership && this.membershipPrice) {
                if (this.user.membership && this.user.membership.id) {
                    return this.user;
                }

                if (this.intakeTemplate.data.disable_payment_collection) {
                    LogRocket.track('SignupMembershipDoNotCharge');
                    window.localStorage.removeItem('membership');

                    return this.user;
                }

                try {
                    await this.startMembershipSubscription({
                        membership_id: this.membership._id,
                        price_id: this.membershipPrice._id,
                        data: {
                            patient_id: this.user._id,
                            stripe_customer_id: this.user.stripe_id,
                        },
                    });

                    if (typeof Rewardful !== 'undefined') {
                        // eslint-disable-next-line no-undef
                        rewardful('convert', { email: this.user.email });
                    }
                    LogRocket.track('SignupMembershipPurchaseCompleteSuccess');
                    window.localStorage.removeItem('membership');

                    return await this.getUser({
                        user: this.user._id,
                    });
                } catch (e) {
                    LogRocket.track('SignupMembershipPurchaseCompleteFailed');
                    throw e;
                }
            }
        },
        async finalizeIntake() {
            try {
                const completed = await this.completeIntakeV2({
                    intake: this.intake_id,
                });

                LogRocket.track('SignupCompletedIntakeSuccess');

                return completed;
            } catch (e) {
                LogRocket.track('SignupCompletedIntakeFailed');
                throw e;
            }
        },
        async sendNotifications() {
            const referrerShop = JSON.parse(window.localStorage.getItem('shop'));

            try {

                if (this.mailchimp_integration && this.user.mailchimp_id) {
                    await this.add_subscriber_tags({
                        tag: {
                            name: tags.status.checkout.complete,
                            status: 'active',
                        },
                        patient: this.user._id,
                    });
                    await this.add_subscriber_tags({
                        tag: {
                            name: tags.status.checkout.incomplete,
                            status: 'inactive',
                        },
                        patient: this.user._id,
                    });
                }
            } catch (error) {
                console.error('sendNotification error', error);
            }

            if (!referrerShop) {
                this.$gtag.event('conversion', {
                    send_to: 'AW-375008541/oC11CL_us_wYEJ3a6LIB',
                    value: this.pricing.total,
                    currency: 'USD',
                    transaction_id: '',
                    email: this.user.email,
                });

                window.fbq('track', 'Purchase', { currency: 'USD', value: this.pricing.total });
            } else {
                return false;
            }
        },

        clearStorage() {
            if (this.noProduct) {
                window.localStorage.removeItem('no-product');
            }

            if (window.localStorage.getItem('intake_template')) {
                window.localStorage.removeItem('intake_template');
            }

            if (window.localStorage.getItem('signup-config')) {
                window.localStorage.removeItem('signup-config');
            }

            if (window.localStorage.getItem('selected_products')) {
                window.localStorage.removeItem('selected_products');
            }

            if (window.localStorage.getItem('default_item')) {
                window.localStorage.removeItem('default_item');
            }

            if (window.localStorage.getItem('shop')) {
                window.localStorage.removeItem('shop');
            }

            window.localStorage.removeItem('zp_product_template');
        },

        addReferral() {
            const email = this.user.email;

            // eslint-disable-next-line no-undef
            if (rewardful && typeof Rewardful !== 'undefined') {
                // eslint-disable-next-line no-undef
                rewardful('convert', { email });
            }
        },

        submit () {
            this.$refs.elementRef.getStripeRef().submit();
        },

        cardChange(event) {
            this.valid_new_card = Boolean(event.complete);

            if (event.error !== undefined) {
                this.paymentError.error = true;
                this.paymentError.error_msg = event.error.message;
            } else {
                this.paymentError.error = false;
                this.submit_button_status = 'Submit';
            }
        },

        async onSignupSubmit(token) {
            const referrerShop = JSON.parse(window.localStorage.getItem('shop'));

            this.paymentError.error = false;
            this.paymentError.error_msg = null;

            if (this.is_submitting) {
                return false;
            }
            this.is_submitting = true;
            this.disableButton = true;

            try {
                await this.getUser({
                    user: this.user._id,
                });

                await this.createStripeAccount(token);
                await this.startMembership();
                await this.finalizeIntake();
                await this.sendNotifications();
                this.addReferral();
                this.clearStorage();
                this.is_submitting = false;

                if (!referrerShop) {
                    await this.$router.push(`/messages/${this.user._id}/new`);
                } else {
                    await this.$router.push(`/dashboard/${this.user._id}/orders`);
                }
            } catch (e) {
                this.is_submitting = false;
                this.submit_button_status = 'Error. Try Again.';
                this.paymentError.error = true;

                if ('type' in e) {
                    if (e.type === 'PAYMENT') {
                        this.paymentError.error_msg = e.error.message;
                    }
                    this.$forceUpdate();
                } else {
                    this.paymentError.error_msg = e.message;
                    this.valid_new_card = false;
                }
            }
        },

        async onButtonSubmit() {
            if (this.show_address_form ) {
                if (this.validations && this.validations.$invalid) {
                    this.submitted = false;
                    this.shipping_error = this.generateShippingError(this.validations);

                    return;
                }

                const { mobile_number: mobileNumber, ...shippingInfo } = this.shipping_info;

                const payload = {
                    _id: this.user._id,
                    shipping_info: shippingInfo,
                    mobile_number: mobileNumber || shippingInfo.phone_number,
                };

                try {
                    await this.updateUser(payload);
                } catch (e) {
                    this.submitted = false;
                    this.order_error = e.message;
                }
            }

            // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
            // eslint-disable-next-line
            if (this.orgId.data === process.env.VUE_APP_ORG_ID) {
                const protocolItems = this.filteredProtocols?.map(protocol => {
                    const { pricing, treatment_properties: treatmentProperties } = protocol.selected_configuration;

                    return {
                        item_id: protocol._id,
                        item_name: protocol.name,
                        price: pricing.total_discount / treatmentProperties.duration,
                        discount: (pricing.total - pricing.total_discount) / treatmentProperties.duration,
                        quantity: treatmentProperties.duration,
                        item_variant: 'protocol',
                    };
                });

                const addonItems = this.filteredAddons?.map(addon => {
                    const { pricing, treatment_properties: treatmentProperties } = addon.selected_configuration;

                    return {
                        item_id: addon._id,
                        item_name: addon.name,
                        price: pricing.total_discount / treatmentProperties.duration,
                        discount: (pricing.total - pricing.total_discount) / treatmentProperties.duration,
                        quantity: treatmentProperties.duration,
                        item_variant: 'addon',
                    };
                });

                analytics.track(analyticsActions.payment_submitted);

                this.$gtag.event('purchase', {
                    transaction_id: this.intake_id,
                    value: this.pricing.total,
                    currency: 'USD',
                    items: [...protocolItems, ...addonItems],
                });
            }

            if (this.hasCard && this.hasStripeAccount) {
                await this.onSignupSubmit();
            } else {
                this.submit();
            }

        },

        onShippingChange(shipping) {
            this.shipping_info = shipping.data;
            this.validations = shipping.validations;
        },

        generateShippingError(validations) {
            const fields = [];

            if (validations.first_name.$invalid) {
                fields.push('First Name');
            }

            if (validations.last_name.$invalid) {
                fields.push('Last Name');
            }

            if (validations.street_address.$invalid) {
                fields.push('Street Address');
            }

            if (validations.city.$invalid) {
                fields.push('City');
            }

            if (validations.state.$invalid) {
                fields.push('State');
            }

            if (validations.zip_code.$invalid) {
                fields.push('Zip Code');
            }

            if (validations.phone_number.$invalid) {
                fields.push('Phone Number');
            }

            return `Please make sure the required fields are filled out. ${fields.join(', ')}`;

        },

        buttonDisabled() {
            const shippingInvalid = this.validations && this.validations.$invalid;
            const paymentInvalid = (this.hasCard && !this.card_is_valid) || (this.new_card && !this.valid_new_card);

            return shippingInvalid || paymentInvalid;
        },

        onLearnMore() {
            this.product_panel = true;
        },

        closeProductPanel() {
            this.product_panel = false;
        },

        ...mapGetters({
            getServicePlanId: 'intake/getServicePlanId',
        }),
        ...mapMutations({
            removePaymentError: 'payment/removePaymentError',
            setServicePlan: 'intake/setServicePlanObject',
            setIntakeMedicalReviewPayment: 'intake/setIntakeMedicalReviewPayment',
        }),
        ...mapActions({
            createBillingProfile: 'user/createBillingProfile',
            completeIntakeV2: 'intake/completeIntakeV2',
            addCompletedIntake: 'intake/addCompletedIntake',
            getProductTemplate: 'products/getProductTemplate',
            getIntakeTemplate: 'intake/getIntakeTemplate',
            addIntakeExpiration: 'intake/addIntakeExpiration',
            updateIntake: 'intake/updateIntake',
            getIntegration: 'integrations/getIntegration',
            getMerchant: 'integrations/getMerchant',
            getMembership: 'memberships/getMembership',
            getMembershipPrice: 'memberships/getMembershipPrice',
            startMembershipSubscription: 'memberships/startMembershipSubscription',
            getUser: 'user/retrieveUser',
            getPatientCard: 'payment/getPatientCard',
            updateCreditCard: 'payment/updateCreditCard',
            add_subscriber_tags: 'user/addSubscriberTags',
            updateUser: 'user/updateUser',
            removeAddonProducts: 'intake/removeAddonProducts',
        }),
    },
    computed: {
        ...mapState({
            intake_id: state => state.intake.intake_id,
            user: state => state.user.user,
            paymentError: state => state.payment.error,
            product_category: state => state.intake.product_category,
            product_template: state => state.products.productTemplate,
            selected_plan: state => state.intake.selected_plan,
            selected_service_plan_object: state => state.intake.selected_service_plan,
            checkoutProduct: state => state.products.checkoutProduct,
            orgId: state => state.orgId,
            intakeTemplate: state => state.intake.selectedIntakeTemplate,
            currentIntakeInstance: state => state.intake.currentIntakeInstance,
            signupIntakeTemplateId: state => state.intake.signupIntakeTemplateId,
            merchantSelected: state => state.integrations.merchantSelected,
            integrationSelected: state => state.integrations.integrationSelected,
            customQuantity: state => state.products.customQuantity,
            membership: state => state.memberships.membership.data,
            membershipPrice: state => state.memberships.price.data,
            patientCard: state => state.payment.patientCard.data,
            intake_order: state => state.orders.intake_order,
            mailchimp_integration: state => state.integrations.mailchimp_exists.data,
            organization_settings: state => state.organizationSettings,
        }),
        filteredProtocols() {
            return this.selectedProducts?.filter(product => {
                return (
                    !this.noProduct &&
                    this.selectedProducts &&
                    this.intakeTemplate.data &&
                    !this.is_bundle(product._id) &&
                    (!product.selected_configuration || !product.selected_configuration.type)
                );
            });
        },
        filteredAddons() {
            return this.selectedProducts?.filter(product => {
                return (
                    !this.noProduct &&
                    this.selectedProducts &&
                    this.intakeTemplate.data &&
                    !this.is_bundle(product._id) &&
                    (product.selected_configuration?.type === 'addon')
                );
            });
        },
        recommendationRequested() {
            let request;

            this.selectedProducts?.forEach(product => {
                if (
                    product.selected_configuration?.treatment_properties?.recommendation_requested &&
                    product.selected_configuration?.treatment_properties?.has_custom_quantity
                ) {
                    request = 'clinical assistance';
                } else if (
                    product.selected_configuration?.treatment_properties?.recommendation_requested &&
                    !product.selected_configuration?.treatment_properties?.has_custom_quantity
                ) {
                    request = 'a custom protocol';
                }
            });

            return request;
        },
        features() {
            return this.organization_settings.data && this.organization_settings.data.signup && this.organization_settings.data.signup.features || null;
        },
    },
};

</script>

<style lang="scss">

.payment-step {
    &__products {
        margin-bottom: 2rem;

        @media screen and (max-width: 760px) {
            padding-bottom: 390px;
        }
    }

    &__form {
        margin-bottom: 2rem;

        @media screen and (max-width: 760px) {

            position: fixed;
            bottom: 0;
            left: 0;
            background: white;
            margin-bottom: 0;
            border-top: 1px solid $stone300;

            .card-block {
                box-shadow: none;
                padding: 1rem 1.5rem;
            }

        }

    }

    &__purchase-helper {
        font-family: "ZP-UI", sans-serif;
        margin-top: 1rem;
        text-align: center;
        line-height: 1.8;
        font-size: 0.875rem;
    }

}

.failed-payment-container {
    padding: 13px 21px;
    background: #ffe6e6;
    color: #b74646;
    font-family: 'Sofia Regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    border: 1px solid #ff6868;
    margin-bottom: 1rem;
}

.credit-card-span-block {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.card-icon-image {
    display: block;
    margin-right: 0.5em;
    width: 1.875rem;
    height: 1.25rem;
}

.card-block {
    width: 100%;
    margin-top: 2rem;
    background: #FFFFFF;
    padding: 2rem 1.5rem;
    border-radius: 12px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0 0.25rem 0.75rem;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.08);
    border: 1px solid transparent !important;
    box-sizing: border-box;
}

.white-container {
    cursor: pointer;
    background: #fff;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-transition: 300ms ease all;
    transition: 300ms ease all;
    border: 1px solid #e3e3e3;
    @media(max-width: 760px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.item-row {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.payment-step-title-padding {
    padding: 1rem;
    margin-top: .5rem;
}

.summary-items-block {
    padding: 1rem 1rem 0;
}

.smblock-border-top {
    border-top: 1px solid #e3e3e3;
}

.smblock-border-bottom {
    border-bottom: 1px solid #e3e3e3;
}

.item-divider {
    border: 0px;
    margin: 0.5rem 0px 0rem;
    width: 100%;
    background-color: rgb(224, 224, 224);
    height: 0.0625rem;
}

.w-limit {
    max-width: 280px;
}

.payment {
    &__card-details {
        list-style: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
    }

    &__new-card {
        font-weight: 500;
        cursor: pointer;
        color: #0D6B80;
        margin-bottom: 1rem;
    }
}

.stripe-checkout-container{
    width: 100%;
    height: 62px;
    padding: 11px 4px;
    background-color: #fff;
    border: 0.0625rem solid #e0e0e0;
    -o-border-image: initial;
    border-image: initial;
    border-radius: 0;

    .StripeElement {
        box-shadow: none !important;
    }

    .StripeElement--invalid {
        border: none !important;
    }

    #stripe-element-errors {
        display: none;
    }
}

.refund {
    font-family: 'Sofia Regular', sans-serif;

    &__title {
        font-family: 'Sofia Bold';
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.04em;
    }

    &__description {
        font-family: 'Sofia Regular', sans-serif;
        font-size: 16px;
        line-height: 120%;
    }

}

.refund-link{
    position: relative;
    text-decoration: underline;
    cursor: pointer;
}

</style>
