<template>
    <div class="quick-notifications-header">
        <div v-click-outside="handleNotificationsClose" class="bell-container" @click="handleNotifications">
            <NotificationIcon />
            <div v-if="unreadCount" class="bell-new" />
            <transition name="widget-transition">
                <QuickNotifications
                    v-if="notificationsOpen"
                    :unread-notifications="displayedUnreadNotifications"
                    @notification-click="handleNotificationClick"
                    @mark-read="handleMarkAllAsRead"
                    @view-all-notifications="goToNotifications"
                    @load-more-notifications="loadMoreNotifications"
                    @manage-notifications="goToNotificationsSettings"
                />
            </transition>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapActions, mapMutations, mapState } from 'vuex';
import { navigateToNotificationLink } from '../utils/navigateToNotificationLink';

import NotificationIcon from '@/assets/icons/NotificationIcon.vue';
import QuickNotifications from '@/components/Dashboard/Notifications/Subcomponents/QuickNotifications.vue';

export default {
    name: 'DashboardHeader',
    components: {
        NotificationIcon,
        QuickNotifications,
    },
    directives: { ClickOutside },
    data() {
        return {
            notificationsOpen: false,
            allUnreadNotifications: [],
            displayedUnreadNotifications: [],
            currentPage: 1,
            notificationsPerPage: 10,
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            notifications: state => state.alerts.notifications,
            unreadCount: state => state.alerts.unreadCount,
        }),
    },
    watch: {
        notifications: {
            handler(newNotifications) {
                this.updateUnreadNotifications(newNotifications);
            },
            deep: true,
        },
        unreadCount(newCount) {
            if (newCount === 0) {
                this.allUnreadNotifications = [];
                this.displayedUnreadNotifications = [];
            }
        },
    },
    mounted() {
        this.fetchNotifications();
    },
    methods: {
        ...mapActions({
            getNotifications: 'alerts/getNotifications',
            markNotificationAsRead: 'alerts/markNotificationAsRead',
            markAllNotificationsAsRead: 'alerts/markAllNotificationsAsRead',
        }),
        ...mapMutations({
            updateUnreadCount: 'alerts/updateUnreadCount',
        }),
        async fetchNotifications(page = this.currentPage) {
            await this.getNotifications({ page });
            this.updateUnreadNotifications(this.notifications);
        },
        updateUnreadNotifications(notifications) {
            const unreadNotifications = notifications.docs.filter(n => !n.read);

            this.allUnreadNotifications = unreadNotifications;
            this.displayedUnreadNotifications = [];
            this.loadMoreNotifications();
        },
        loadMoreNotifications() {
            const start = this.displayedUnreadNotifications.length;
            const end = start + 5;
            const nextBatch = this.allUnreadNotifications.slice(start, end);

            this.displayedUnreadNotifications.push(...nextBatch);

            if (this.displayedUnreadNotifications.length === this.allUnreadNotifications.length && this.currentPage < this.totalPages) {
                this.currentPage += 1;
                this.fetchNotifications();
            }
        },
        handleNotifications() {
            this.notificationsOpen = true;
        },
        handleNotificationsClose() {
            this.notificationsOpen = false;
        },
        async handleNotificationClick(notification) {
            await this.markNotificationAsRead(notification._id);
            this.notificationsOpen = false;
            navigateToNotificationLink(notification, this.user._id, this.$router);
        },
        async handleMarkAllAsRead() {
            await this.markAllNotificationsAsRead();
            this.updateUnreadCount();
            this.notificationsOpen = false;
        },
        goToNotifications() {
            const targetRoute = '/notifications';

            if (this.$route.path !== targetRoute) {
                this.$router.push({ path: targetRoute });
            }

            this.notificationsOpen = false;
        },
        goToNotificationsSettings() {
            this.$router.push({ path: `/dashboard/${this.user._id}/settings/notifications` });
            this.notificationsOpen = false;
        },
    },
};
</script>
