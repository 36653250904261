<template>
    <div class="input-white-container-size">
        <div class="input-white-container">
            <div class="flex-row">
                <h4 class="h4-account">Shipping Address</h4>
                <div class="open-container">
                    <button class="transparent-button" @click="openContainer()">
                        <span class="down-arrow-container">
                            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                <path class="icon-styleable-color" d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                                <path d="M0 0h24v24H0V0z" fill="none"></path>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>

            <collapse-transition dimension="height">
                <div v-show="open" class="form-block">
                    <div v-if="!open_edit" class="profile_static">
                        <div class="mb2r">
                            <shipping-item label="Name" :value="`${userData.shipping_info.first_name} ${userData.shipping_info.last_name}`" />
                            <shipping-item label="Street Address" :value="userData.shipping_info.street_address" id="street_address" />
                            <shipping-item label="Apt, Suite, Unit #" :value="userData.shipping_info.apt_suite" />
                            <shipping-item label="City" :value="userData.shipping_info.city" />
                            <shipping-item label="State" :value="userData.shipping_info.state" />
                            <shipping-item label="Zip Code" :value="userData.shipping_info.zip_code" />
                            <shipping-item label="Country" :value="userData.shipping_info.country" />
                        </div>

                        <button class="edit-button-dashboard" data-test="edit-shipping" type="button" @click="openEdit()">
                            <img class="edit-icon" src="../../assets/icons/pencil.png" />
                            EDIT
                        </button>
                    </div>

                    <div v-if="open_edit">
                        <ProfileAutoComplete :shipping="shipping_info" :button_label="save_button" @shipping-data="handleShippingData" />

                        <div class="submit-button-container">
                            <button class="cancel-button" type="button" @click="cancelEdit()"><img src="@/assets/icons/close-icon.svg" />Cancel</button>
                        </div>
                    </div>
                </div>
            </collapse-transition>
        </div>
    </div>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition';
import { mapState, mapActions } from 'vuex';
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
import ShippingItem from '@/components/Dashboard/Subcomponents/ShippingItem.vue';
import ProfileAutoComplete from './Subcomponents/ProfileAutoComplete.vue';

const isZipcode = helpers.regex('isZipcode', /^\d{5}(-\d{4})?$/);

export default {
    name: 'update-shipping',
    components: {
        CollapseTransition: CollapseTransition,
        ShippingItem,
        ProfileAutoComplete,
    },
    data() {
        return {
            open: true,
            open_edit: false,
            save_button: 'Save Changes',
            err_status: {
                isErr: false,
                err_msg: null,
            },
            //this is a copy of userData.user
            shipping_info: {},
        };
    },
    validations: {
        shipping_info: {
            first_name: { required },
            last_name: { required },
            street_address: { required, maxLength: maxLength(35) },
            apt_suite: { maxLength: maxLength(35) },
            city: { required, maxLength: maxLength(35) },
            state: { required },
            zip_code: { required, minLength: minLength(5), isZipcode },
            country: { required },
        },
    },
    methods: {
        handleShippingData(shipping) {
            this.shipping_info = shipping;
            this.updateUser();
        },
        updateUser() {
            let vm = this;

            let payload = {};

            //change the text on the button
            this.save_button = 'Saving';

            payload.user_id = this.userData._id;

            payload.shipping_info = this.shipping_info;

            this.updateShipping(payload)
                .then((response) => {
                    vm.save_button = 'Saved';
                    vm.open_edit = false;
                })
                .catch((err) => {
                    this.save_button = 'Error. Try Again';
                });
        },
        openContainer() {
            this.open = !this.open;
        },
        openEdit() {
            this.open_edit = !this.open_edit;
        },
        cancelEdit() {
            this.open_edit = !this.open_edit;
            let user_object_new = Object.assign({}, this.userData.shipping_info);
            this.shipping_info = user_object_new;
            this.$v.shipping_info.$reset();
        },
        ...mapActions({
            updateShipping: 'user/updateShippingDashboard',
        }),
    },
    created() {
        //we need to make a copy of the original data
        //so that when we edit the data7
        let user_object_new = Object.assign({}, this.userData.shipping_info);
        this.shipping_info = user_object_new;
    },
    computed: {
        ...mapState({
            userData: (state) => state.user.user,
            duplicate_email: (state) => state.user.duplicate_email,
        }),
    },
};
</script>

<style>
.min-w-9rem {
    min-width: 9rem !important;
}
</style>
