<template>
    <div>
        <div v-if="redirect_product_id && show_alert" class="login-alert">
            <alert-component
                layout="single-line"
                message="Please login again to continue."
                type="error"
            ></alert-component>
        </div>
        <div v-if="expired_token" class="login-alert">
            <alert-component
                layout="single-line"
                message="Please login again to continue."
                type="error"
            ></alert-component>
        </div>
        <form class="login-form" @submit.prevent="onLoginSubmit()">
            <div class="login-form__input">
                <Input
                    id="email"
                    v-model="authentication.email"
                    name="email"
                    label="Email"
                    :vuelidate="$v.authentication.email"
                    :errors="[!$v.authentication.email.email_validator && $v.authentication.email.$dirty && 'Please enter a valid email address']"
                />
                <Input
                    id="password"
                    v-model="authentication.password"
                    name="password"
                    type="password"
                    label="Password"
                    :vuelidate="$v.authentication.password"
                    :errors="[
                        !$v.authentication.password.required && $v.authentication.password.$dirty && 'Please enter your password.',
                        !$v.authentication.password.minlength && $v.authentication.password.$dirty && 'Password must be at least 6 characters long.',
                    ]"
                />
            </div>
            <div v-if="bad_credentials && !$v.authentication.email.$dirty && !$v.authentication.password.$dirty" class="auth-alert__error">
                <span class="error-msg-field">The password or email you've entered was wrong. If you are having trouble logging in, please select Forgot Password below.</span>
            </div>
            <h-button
                label="Continue"
                variant="primary"
                full_width
                large
                :handle-click="onLoginSubmit"
                :loading="submitted"
                :disabled="$v.$invalid"
            />
            <div class="login-form__options h-caption">
                <div><span>Ready to create an account? <a href="/#/get-started" class="auth__link" @click="onRedirect('signup')">Become a member</a></span></div>
                <div><a class="auth__link" href="/#/request-password-reset">Forgot Password</a></div>
            </div>
        </form>
    </div>
</template>
<script>
import LogRocket from 'logrocket';
import { helpers, minLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapState } from 'vuex';

import Input from '@/components/Auth/Input.vue';
import AlertComponent from '@/components/Components/Alert';
import HButton from '@/components/Shared/HButton';
import { analytics, analyticsActions } from '@/helpers/analytics';
import IntakeService from '@/services/IntakeService';
import { PRODUCT_TYPES } from '@/constants/product';

const emailValidator = helpers.regex('isEmailAddress', /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/);

export default {
    name: 'LoginForm',
    components: {
        AlertComponent,
        Input,
        HButton,
    },
    props: [
        'redirect_product_id',
        'redirect_path',
        'show_alert',
        'product_type',
    ],
    data() {
        return {
            product_redirect: false,
            expired_token: false,
            submitted: false,
            authentication: {
                email: '',
                password: '',
            },

        };
    },
    validations: {
        authentication: {
            email: { required, minlength: minLength(4), email_validator: emailValidator },
            password: { required, minlength: minLength(6) },
        },
    },
    computed: {
        ...mapState({
            organizationSettings: state => state.organizationSettings,
            bad_credentials: state => state.authenticate.bad_credentials,
            server_down: state => state.authenticate.server_down,
            intakeTemplate: state => state.intake.selectedIntakeTemplate,
            orgId: state => state.orgId,
            user: state => state.user.user,
        }),
        ...mapGetters({
            isLoggedIn: 'authenticate/isLoggedIn',
        }),

    },
    watch: {
        product_type(to) {
            if (to) {
                this.autologin();
            }
        },
    },
    async created() {
        this.expired_token = !!this.$route.query.expired;
        this.product_redirect = !!this.$route.query.product;

        if (this.$route.query.email) {
            this.authentication.email = decodeURIComponent(this.$route.query.email);
        }

        if (this.$route.query.token) {
            this.authentication.password = decodeURIComponent(this.$route.query.token);

            return await this.onLoginSubmit();
        }

        // if no redirect_product_id, we should proceed to autologin
        // otherwise, the method will be called on #watch.product_type
        if (!this.redirect_product_id) {
            this.autologin();
        }
    },
    methods: {
        ...mapActions({
            loginUser: 'authenticate/loginUser',
            getProductIntake: 'intake/retrieveProductIntake',
            createIntakeInstance: 'intake/createIntakeInstance',
        }),

        async autologin() {
            if (this.user?._id && this.isLoggedIn) {
                if (this.redirect_product_id) {
                    if (this.product_type && this.product_type === 'medication') {
                        return await this.initProductIntake(this.user);
                    } else {
                        return await this.initProductCheckout();
                    }
                } else {
                    if (this.redirect_path) {
                        this.$router.push({ path: this.redirect_path }).catch((err) => {
                            console.error('>>> Error in LoginForm.vue#created', err);
                        });
                    } else {
                        this.$router.push({ path: '/dashboard/' + this.user._id + '/profile' }).catch((err) => {
                            console.error('>>> Error in LoginForm.vue#created', err);
                        });
                    }
                }
            }
        },

        async initProductIntake(user) {
            const productIntakeTemplate = await this.getProductIntake({
                product_template_id: this.redirect_product_id,
            });

            const intakeInstance = await this.createIntakeInstance({
                templateId: productIntakeTemplate._id,
                userId: user._id,
                pharmacy_verification: true,
            });

            window.localStorage.setItem('intake_template', JSON.stringify(productIntakeTemplate));
            IntakeService.initSignupConfiguration(productIntakeTemplate);
            await this.$router.push({
                path: '/consultation/hiw/' + intakeInstance.user + '/' + intakeInstance._id + '/question/none',
                query: { referrer: 'shop' },
            });
        },

        async initProductCheckout() {
            localStorage.setItem('zp_product_template', JSON.stringify({
                id: this.redirect_product_id,
                type: this.product_type,
            }));

            if (this.product_type === PRODUCT_TYPES.lab) {
                await this.$router.push({ path: '/signup/lab-visit' });
            } else if (this.product_type === PRODUCT_TYPES.supplement) {
                await this.$router.push({ path: `/signup/product-plan/${this.redirect_product_id}` });
            }
        },

        async onLoginSubmit() {
            this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.authentication.email = this.authentication.email.toLowerCase();

            try {
                const response = await this.loginUser(this.authentication);

                LogRocket.identify(response._id, {
                    name: `${response.first_name} ${response.last_name}`,
                    email: response.email,
                }); // an immutable ID from your database (preferred)
                analytics.identify(response._id, {
                    birthday: response.birthday,
                    weight: response.weight,
                    height: response.height,
                    email: response.email,
                    sex: response.sex,
                    organization: response.organization,
                    complete_intake: response.complete_intake,
                    first_name: response.first_name,
                    last_name: response.last_name,
                    mobile_verified: response.mobile_verified,
                    requires_intake: response.requires_intake,
                    verified: response.verified,
                });
                analytics.track(analyticsActions.session_started);

                if (this.redirect_product_id) {
                    if (this.product_type && this.product_type === 'medication') {
                        return await this.initProductIntake(response);
                    } else {
                        return await this.initProductCheckout();
                    }
                }

                if (this.redirect_path) {
                    this.$router.push({ path: this.redirect_path }).then(() => {
                    }).catch((error) => {
                        console.error(error);
                    });
                } else {
                    this.$router.push({ path: '/dashboard/' + response._id + '/profile' }).catch((error) => {
                        console.error(error);
                    });
                }
            } catch (err) {
                if (this.server_down) {
                    this.button_text = 'Connection Error';

                    return;
                }
                this.$v.authentication.email.$reset();
                this.$v.authentication.password.$reset();
                this.submitted = false;
            }

        },
        onRedirect(type) {
            this.$emit('changeType', type);
        },
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/_colors.scss";

.login-alert {
    max-width: 500px;
    margin: 0 auto 1.5rem;

    > div {
        justify-content: center;

        p:first-of-type {
            display: none;
        }
    }

    @media (min-width: 1280px) {
        > div {
            justify-content: start;
        }
    }
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 500px;
    border-top: 1px solid $border-faint;
    border-bottom: 1px solid $border-faint;
    padding: 0 0 2rem;
    margin: 0 auto;

    &__input {
        display: flex;
        flex-direction: column;
        gap: 0.8125rem;
        padding: 1.5rem 0 0;
    }

    &__options {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        text-align: center;
    }

    button {
        span {
            font-family: 'Helvetica Now Text', serif;
            font-size: 0.875rem;
            letter-spacing: 0.0875rem;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}
</style>
