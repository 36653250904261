<template>
    <div v-if="isColumn" class="copanel-section">
        <div class="copanel-plans">
            <div
                v-for="plan in planOptions"
                :key="plan.id"
                class="co-plan"
                :class="{'co-plan-active': selectedPlanId === plan.id}"
                @click="onPlanSelect(plan)"
            >
                <div>
                    <div v-if="plan.duration" class="co-plan-duration">
                        {{ plan.duration }} Month Plan
                    </div>
                    <div v-else class="co-plan-duration">
                        One Time Purchase
                    </div>
                    <div class="co-plan-price">
                        {{ formatPrice(plan.price) }}
                    </div>
                </div>
                <div class="co-plan-badge">
                    <div v-if="plan.highlight" class="co-plan-highlight">
                        Best Value
                    </div>
                    <div class="co-plan-savings">
                        {{ formatSavings(plan.savings) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <ul v-if="planOptions?.length > 1" class="protocol-duration">
            <li
                v-for="plan in planOptions"
                :key="plan.id"
                class="protocol-duration__option"
                :class="{ 'protocol-duration__option--active': selectedPlanId === plan.id }"
                @click="onPlanSelect(plan)"
            >
                <div v-if="plan.highlight" class="protocol-duration__option-ribbon--mobile">
                    Best Value
                </div>
                <div v-if="plan.highlight" class="protocol-duration__option-ribbon--desktop">
                    <span>Best Value</span>
                </div>
                <div class="protocol-body480 strong">
                    <div v-if="plan.duration" class="flex gap-25">
                        <span>{{ plan.duration }} Month </span><span class="protocol-duration__desktop">Plan</span>
                    </div>
                    <div v-else class="flex gap-25">
                        <span>One Time Purchase</span>
                    </div>
                </div>
                <div class="divider w-15" :class="{ 'divider--active': plan.active }"></div>
                <div class="protocol-caption480 strong">
                    <span>{{ formatSavings(plan.savings) }}</span>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ProductPlanDurations',
    props: ['product', 'selectedPlanId'],
    data() {
        return {
            durations: null,
        };
    },
    computed: {
        planOptions() {
            return this.getPlanOptions();
        },
        isColumn() {
            return this.planOptions.length > 3;
        },
    },
    methods: {
        ...mapGetters({
            getPlanOptions: 'products/getPlanOptions',
        }),
        onPlanSelect(plan) {
            this.$emit('onPlanSelected', plan);
        },
        formatPrice(price) {
            return `$${price.toFixed(2)}`;
        },
        formatSavings(savings) {
            return  savings > 0 ? `Save $${savings.toFixed(2)}` : 'Pay Full Price';
        },
    },
};
</script>
