import moment from 'moment';
import { helpers, integer, maxLength, maxValue, minLength, minValue, not, numeric, required, sameAs } from 'vuelidate/lib/validators';

export const validateDate = (value) => {
    const dob = moment(value, 'MM-DD-YYYY', true);

    return dob.isValid() && dob.isBefore(moment()) && dob.isAfter(moment().subtract(150, 'years'));
};

export const isValidAge = (value) => {
    const age = moment().diff(moment(value, 'MM-DD-YYYY'), 'year');

    const isValid = (age >= 18);

    return isValid;
};

export const moreThanZero = (value) => {
    const number = Number(value);

    return number > 0;
};

export const requiredValidation = {
    required,
};

export const nameValidation = {
    required,
    minLength: minLength(2),
    maxLength: maxLength(35),
};

export const numericValidation = {
    required,
    numeric,
};

export const birthdayValidation = {
    required,
    minLength: minLength(10),
    maxLength: maxLength(10),
    isValidAge,
    validateDate,
};

export const weightValidation = {
    ...numericValidation,
    min: minValue(-1),
    overweight: maxValue(1500),
    underweight: moreThanZero,
};

export const heightFeetValidation = {
    ...numericValidation,
    integer,
    max: maxValue(10),
    notZero: moreThanZero,
};

export const heightInchesValidation = {
    ...numericValidation,
    integer,
    max: maxValue(12),
};

export const newPasswordValidation = {
    required,
    minlength: minLength(8),
    hasLength (value) {
        if (value) {
            return value.length >= 8;
        } else {
            return false;
        }
    },
    containsUppercase (value) {
        return /[A-Z]/.test(value);
    },
    containsLowercase (value) {
        return /[a-z]/.test(value);

    },
    containsNumber (value) {
        return /[0-9]/.test(value);
    },
    notSame: not(sameAs('currentPassword')),
};

export const confirmPasswordValidation = {
    required,
    minlength: minLength(8),
    sameAsPassword: sameAs('newPassword'),
};

const emailValidator = helpers.regex('isEmailAddress', /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/);

export const emailValidation = {
    required,
    minLength: minLength(4),
    emailValidator,
};

export const addressValidation = {
    required,
    maxLength: maxLength(35),
};

export const aptValidation = {
    maxLength: maxLength(35),
};

export const phoneValidation = {
    required,
    minLength: minLength(14),
};

const isZipcode = helpers.regex('isZipcode', /^\d{5}(-\d{4})?$/);

export const zipValidation = {
    required,
    minLength: minLength(5),
    isZipcode,
};

export const errorMessages = {
    FIRST_NAME_EMPTY: 'Enter your first name.',
    FIRST_NAME_SHORT: 'First name should be at least 2 characters long.',
    LAST_NAME_EMPTY: 'Enter your last name.',
    LAST_NAME_SHORT: 'Last name should be at least 2 characters long.',
    SEX: 'Please select an option.',
    BIRTHDAY_EMPTY: 'Please enter your birthday.',
    BIRTHDAY_AGE: 'You must be at least 18 years of age or older.',
    BIRTHDAY_DATE: 'Please enter a valid date.',
    HEIGHT: 'Please select a valid height.',
    WEIGHT: 'Please enter a valid weight.',
    PASSWORD: 'Please enter your current password.',
    NEW_PASSWORD: 'Password is required.',
    OLD_PASSWORD: 'Your new password cannot be the same as your current password.',
    CONFIRM_PASSWORD: 'New passwords must match each other.',
    EMAIL: 'Please enter a valid email address.',
    STATE: 'Please select a state.',
    STREET_ADDRESS_EMPTY: 'Please enter your street address.',
    STREET_ADDRESS_LONG: 'Street address must be less than 35 characters long.',
    APT_LONG: 'Apt/Suite must be less than 35 characters long.',
    CITY_EMPTY: 'Please enter your city.',
    CITY_LONG: 'City must be less than 35 characters long.',
    STATE_REQUIRED: 'Please select a state.',
    ZIP_INVALID: 'Invalid zip code.',
    COUNTRY_EMPTY: 'Please enter your country.',
    PHONE_EMPTY: 'Please enter your phone number.',
};
