import axios from 'axios';
import Vue from 'vue';

import productService from '@/services/Product';

const baseUrl = process.env.VUE_APP_BASE_END;

export const products = {
    namespaced: true,
    state: {
        productTemplateExists: {
            loading: false,
            data: null,
        },
        productTemplate: {
            loading: false,
            data: null,
        },
        categoryProducts: {
            loading: false,
            data: null,
        },
        checkoutProduct: {
            loading: false,
            data: null,
        },
        latestProductTemplate: {
            loading: false,
            data: null,
        },
        productTemplates: {
            loading: false,
            data: null,
        },
        productService: {
            loading: false,
            data: null,
        },
        customQuantity: null,
        products: {},
        v2products: {},
        public_product: {},
    },
    getters: {
        getPlanOptions: state => {
            const products = state.products.data;
            const productTemplate = state.productTemplate.data;

            if (!products?.length || !productTemplate) return [];

            const sortedProducts = [...products].sort((a, b) => a.pricing.interval_count - b.pricing.interval_count);

            if (!sortedProducts.length) {
                return [];
            }

            const baseProduct = sortedProducts[0];
            const basePrice = baseProduct ? productService.generatePrice(baseProduct, productTemplate.dosage_frequencies[0], null, null).total_discount : 0;

            return sortedProducts.map((product) => {
                const intervalCount = product.pricing.interval_count;

                const pricing = productService.generatePrice(product, productTemplate.dosage_frequencies[0], null, null);
                const totalBasePrice = basePrice * intervalCount;

                return {
                    id: product.id,
                    duration: intervalCount,
                    total: pricing.total,
                    totalDiscount: pricing.total_discount,
                    price: pricing.total_discount,
                    savings: totalBasePrice - pricing.total_discount,
                    highlight: product.pricing.default,
                };
            });
        },
    },
    mutations: {
        setProductTemplateExistsLoading(state, loading) {
            state.productTemplateExists.loading = loading;
        },
        setProductTemplateExistsData(state, data) {
            state.productTemplateExists.data = data;
        },
        setProductTemplateLoading(state, loading) {
            state.productTemplate.loading = loading;
        },
        setProductTemplateData(state, data) {
            state.productTemplate.data = data;
        },
        setCategoryProductsLoading(state, loading) {
            state.categoryProducts.loading = loading;
        },
        setCategoryProductsData(state, data) {
            state.categoryProducts.data = data;
        },
        setCheckoutProductLoading(state, loading) {
            state.checkoutProduct.loading = loading;
        },
        setCheckoutProductData(state, data) {
            state.checkoutProduct.data = data;
        },
        setServiceProductLoading(state, loading) {
            state.productService.loading = loading;
        },
        setServiceProductData(state, data) {
            state.productService.data = data;
        },
        setCustomQuantity(state, data) {
            state.customQuantity = data;
        },
        setProducts(state, payload) {
            Vue.set(state.products, payload.id, payload.value);
        },
        setV2Products(state, payload) {
            Vue.set(state.v2products, payload.id, payload.value);
        },
        setPublicProduct(state, payload) {
            Vue.set(state.public_product, payload.id, payload.value);
        },
    },
    actions: {
        async getProducts({ commit, rootState }, payload) {
            const organization = rootState.orgId.data;
            const { product_template: productTemplate } = payload;

            commit('setProducts', { id: 'loading', value: true });
            commit('setProducts', { id: 'error', value: false });

            try {
                const response = await axios.get(
                    `${baseUrl}/product/${organization}/template/${productTemplate}/products`,
                );

                if ('data' in response) {
                    commit('setProducts', { id: 'loading', value: false });
                    commit('setProducts', { id: 'data', value: response.data });

                    return response.data;
                }
            } catch (error) {
                commit('setProducts', { id: 'loading', value: false });
                commit('setProducts', { id: 'error', value: true });
                commit('setProducts', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setProducts', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },
        async getPublicProduct({ commit, rootState }, payload) {
            const organization = rootState.orgId.data;
            const { product_template: productTemplate } = payload;

            commit('setPublicProduct', { id: 'loading', value: true });
            commit('setPublicProduct', { id: 'error', value: false });

            try {
                const response = await axios.get(
                    `${baseUrl}/v2/products/${productTemplate}/public`,
                    {
                        headers: {
                            'zenpatient-organization': organization,
                        },
                    },
                );

                if ('data' in response) {
                    commit('setPublicProduct', { id: 'data', value: response.data });

                    return response.data;
                }
            } catch (error) {
                commit('setPublicProduct', { id: 'error', value: true });
                commit('setPublicProduct', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setPublicProduct', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            } finally {
                commit('setPublicProduct', { id: 'loading', value: false });
            }
        },
        getProductTemplate({ commit, rootState }, payload) {
            const orgId = rootState.orgId.data;

            commit('setProductTemplateExistsLoading', true);

            return new Promise((resolve, reject) => {
                axios({
                    url:
            baseUrl +
            '/product/' +
            orgId +
            '/template/' +
            payload.product_template_id,
                })
                    .then((response) => {
                        commit('setProductTemplateData', response.data);
                        resolve(response.data);
                        commit('setProductTemplateLoading', false);
                    })
                    .catch((err) => {
                        reject(err);
                        commit('setProductTemplateLoading', false);
                    });
            });
        },
        saveCustomQuantity({ commit }, payload) {
            commit('setCustomQuantity', payload);
        },
        async getV2Products({ commit }, payload) {
            commit('setV2Products', { id: 'loading', value: true });
            commit('setV2Products', { id: 'error', value: false });

            try {
                const response = await axios.get(`${baseUrl}/v2/products`, {
                    params: payload,
                });

                if ('data' in response) {
                    commit('setV2Products', { id: 'loading', value: false });
                    commit('setV2Products', { id: 'data', value: response.data });

                    return response.data;
                }
            } catch (error) {
                commit('setV2Products', { id: 'loading', value: false });
                commit('setV2Products', { id: 'error', value: true });
                commit('setV2Products', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setV2Products', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            }
        },
    },
};
