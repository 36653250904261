<template>
    <div class="intake-wrapper">
        <div class="intake-container">
            <h-progress-bar :total_steps="totalStep" :current_step="progressStep" />
            <transition :name="transitionName" mode="out-in">
                <component
                    :is="currentQuestionComponent"
                    :key="currentStep"
                    :question="currentQuestion"
                    :medical-profile="medicalProfile"
                    @continue="onContinue"
                />
            </transition>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import HProgressBar from '@/components/Shared/HProgressBar.vue';

import { MEDICAL_HISTORY_QUESTIONS } from './constants/pharmacyQuestions';
import PharmacyCheckbox from './question_types/PharmacyCheckbox.vue';
import PharmacyRadio from './question_types/PharmacyRadio.vue';

export default {
    name: 'PharmacyQuestions',
    components: {
        PharmacyRadio,
        PharmacyCheckbox,
        HProgressBar,
    },
    data() {
        return {
            transitionName: 'slide-forward',
            medicalHistoryQuestions: MEDICAL_HISTORY_QUESTIONS,
            medicalProfile: {
                pregnant: false,
                planning_pregnancy: false,
                breastfeeding: false,
                has_allergies: null,
                allergy_text: '',
                has_medical_conditions: null,
                medical_condition_text: '',
                has_current_medications: null,
                current_medications: '',
            },
            currentStep: 1,
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            currentIntakeInstance: state => state.intake.currentIntakeInstance,
        }),
        currentQuestionComponent() {
            return this.currentQuestion.type === 'radio' ? PharmacyRadio : PharmacyCheckbox;
        },
        currentQuestion() {
            return this.medicalHistoryQuestions[this.currentStep] || null;
        },
        totalStep() {
            return this.user?.sex === 'male' ? 3 : 4;
        },
        progressStep() {
            return this.user?.sex === 'female' ? this.currentStep + 1 : this.currentStep;
        },
    },
    watch: {
        '$route.query.step': {
            immediate: true,
            handler(newStep) {
                this.currentStep = Number(newStep) || 0;
            },
        },
        currentStep(to, from) {
            this.transitionName = to > from ? 'slide-forward' : 'slide-backward';
        },
        'user.medical_profile': {
            handler(newProfile) {
                if (newProfile) {
                    this.updateMedicalProfile(newProfile);
                }
            },
        },
    },
    created() {
        this.initializeMedicalProfile();

        if (this.currentStep === 0 || this.currentStep === 1) {
            this.transitionName = 'slide-forward';
        } else if (this.currentStep === 3){
            this.transitionName = 'slide-backward';
        }
    },
    methods: {
        ...mapActions({
            updateUserInfo: 'user/updateUserInfo',
        }),
        updateMedicalProfile(newProfile) {
            this.medicalProfile = {
                ...this.medicalProfile,
                ...newProfile,
            };
        },
        initializeMedicalProfile() {
            if (this.user?.medical_profile) {
                this.updateMedicalProfile(this.user.medical_profile);
            }
        },
        async onContinue(responseData) {
            if (this.currentQuestion.type === 'radio') {
                const { key, textKey } = this.currentQuestion;

                this.medicalProfile[key] = responseData.response === 'yes';
                this.medicalProfile[textKey] = responseData.response === 'yes' ? (responseData.additionalInfo || '') : '';
            }

            if (this.currentQuestion.type === 'checkbox') {
                this.medicalProfile = {
                    ...this.medicalProfile,
                    ...responseData,
                };
            }
            
            this.saveMedicalProfile();
        },
        async saveMedicalProfile() {  
            try {
                await this.updateUserInfo({
                    _id: this.user._id,
                    medical_profile: this.medicalProfile,
                });
                this.handleNext();
            } catch (error) {
                //TODO: Need to create a global alert component to be used here, pharmacy overview and intake questions
                console.error(error);
            } 
        },
        async handleNext() {
            const intakeInstance = this.currentIntakeInstance.data;

            if (this.currentStep < 3) {
                this.currentStep++;
                await this.$router.push({
                    path: `/consultation/pharmacy-questions/${this.user._id}/${intakeInstance._id}/question/current`,
                    query: { step: this.currentStep },
                });
            } else {
                const step = 0;

                const firstIntakeQuestion = intakeInstance.intake_responses[step];

                await this.$router.push({
                    path: `/consultation/intake/${this.user._id}/${intakeInstance._id}/question/${firstIntakeQuestion._id}`,
                    query: { step },
                });
            }
        },
    },
};
</script>

<style lang="scss">

.slide-forward-enter {
    opacity: 0;
    transform: translateX(100%);
}

.slide-forward-leave-to,
.slide-backward-leave-to {
    opacity: 0;
}

.slide-forward-enter-active,
.slide-forward-leave-active,
.slide-backward-enter-active,
.slide-backward-leave-active  {
    transition: 0.2s ease-out;
}

.slide-backward-enter {
    opacity: 0;
    transform: translateX(-100%);
}

</style>
