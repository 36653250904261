<template>
    <div>
        <transition name="modal">
            <div v-if="open" :class="modalClasses">
                <div v-if="!removeHeader" class="hmodal-header">
                    <button @click="handleClose">
                        <img src="@/assets/icons/close-icon.svg" alt="close-icon" width="20px" height="20px">
                    </button>
                </div>
                <div>
                    <slot />
                </div>
            </div>
        </transition>
        <transition name="backdrop">
            <div v-if="open" class="backdrop" @click="handleClose" />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'HModal',
    props: {
        open: {
            type: Boolean,
        },
        removeHeader: {
            type: Boolean,
            default: false,
        },
        fullHeight: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: 'md',
        },
        removePadding: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        modalClasses() {
            return [
                'hmodal',
                { 'full-height': this.fullHeight },
                `width-${this.width}`,
                { 'remove-padding': this.removePadding },
            ];
        },
    },
    watch: {
        open(newVal) {
            if (newVal) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
    },
    beforeDestroy() {
        document.body.style.overflow = '';
    },
    methods: {
        handleClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
@import '@/assets/scss/utility.scss';

.hmodal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 80vh;
    background-color: $white-100;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    padding: 0 1.5rem 2.5rem;
    z-index: $z-modal;
    overflow-y: scroll;

    &.remove-padding {
        padding: 0;
    }

    @media (min-width: 1280px) {
        bottom: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 70vh;
        border-radius: 1rem;
        padding: 0 2.5rem 2.5rem;

        &.full-height {
            max-height: 95vh;
        }

        &.width-sm {
            width: 50vw;
        }

        &.width-md {
            width: 60vw;
        }
    }
}

.hmodal-header {
    position: sticky;
    top: 0;  
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    background-color: $white-100;
    padding: 1.25rem 0;
    z-index: $z-modal;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: transparent;
        border: none;
        transition: all 0.2s ease;
        cursor: pointer;

        &:hover {
            background-color: $bg-medium;
        }
    }

    ion-icon {
        font-size: 3rem;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $backdrop;
    transition: all 0.3s ease;
    z-index: $z-overlay;
}

.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.2s ease;
}

.backdrop-enter,
.backdrop-leave-to {
    opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: transform 0.3s ease;

  @media (min-width: 1280px) {
		transition: none;
	}
}

.modal-enter,
.modal-leave-to {
  transform: translateY(100%);

  @media (min-width: 1280px) {
        transform: translate(-50%, -50%);
	}
}
</style>
