<template>
    <div class="input-white-container-size">
        <div class="input-white-container">
            <div class="flex-row">
                <h4 class="h4-account">Password Reset</h4>

                <div class="open-container">
                    <button class="transparent-button" @click="openContainer()">
                        <span class="down-arrow-container">
                            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path
                                class="icon-styleable-color"
                                d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path><path
                                d="M0 0h24v24H0V0z"
                                fill="none"></path></svg>
                        </span>
                    </button>
                </div>
            </div>

            <collapse-transition dimension="height">
                <div v-if="open" class="form-block">

                    <form @submit.prevent="changePassword()">

                        <div class="input-style-block">
                            <div class="input-style-inner">

                                <label>Current Password</label>
                                <input v-model="$v.password.current_password.$model" class="clear-input"
                                       type="password"
                                       v-bind:class="{ 'error-border' : $v.password.current_password.$error }"/>
                                <div class="text-field-style"></div>
                                <div class="error-msg-field">
                                    <span v-if="$v.password.current_password.$error">Required field</span>
                                    <span v-if="err_status.isErr">{{ err_status.err_msg }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="input-style-block">
                            <div class="input-style-inner">
                                <label>New Password</label>
                                <input v-model="$v.password.new_password.$model" class="clear-input" type="password"
                                       v-bind:class="{ 'error-border' : $v.password.new_password.$error }"/>
                                <div class="text-field-style"></div>
                                <div class="error-msg-field">
                                    <span v-if="$v.password.new_password.$error">Required field</span>
                                </div>
                            </div>
                            <div class="profile__password-reset-validation" v-if="$v.password.new_password.$dirty">
                                <ul>
                                    <li :class="{active: $v.password.new_password.hasLength && $v.password.new_password.$dirty}">
                                        <ion-icon name="checkmark-circle"></ion-icon>
                                        8 character minimum
                                    </li>
                                    <li :class="{active: $v.password.new_password.containsLowercase && $v.password.new_password.$dirty}">
                                        <ion-icon name="checkmark-circle"></ion-icon>
                                        one lowercase character
                                    </li>
                                    <li :class="{active: $v.password.new_password.containsUppercase && $v.password.new_password.$dirty}">
                                        <ion-icon name="checkmark-circle"></ion-icon>
                                        one uppercase character
                                    </li>
                                    <li :class="{active: $v.password.new_password.containsNumber && $v.password.new_password.$dirty}">
                                        <ion-icon name="checkmark-circle"></ion-icon>
                                        one number
                                    </li>
                                    <li :class="{active: $v.password.new_password.notSame && $v.password.new_password.$dirty}">
                                        <ion-icon name="checkmark-circle"></ion-icon>
                                        not current password
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="input-style-block">
                            <div class="input-style-inner">

                                <label>Confirm Password</label>
                                <input v-model="$v.password.confirm_password.$model" class="clear-input"
                                       type="password"
                                       v-bind:class="{ 'error-border' : $v.password.confirm_password.$error }"/>
                                <div class="text-field-style"></div>
                                <div class="error-msg-field">
                                <span
                                    v-if="$v.password.confirm_password.$error">New passwords must match each other.</span>
                                </div>
                            </div>
                        </div>


                        <div class="submit-button-container">
                            <div class="input-style-block">
                                <button class="pill-button black-btn" type="submit" :disabled="$v.password.$invalid">
                                    {{ save_button }}
                                </button>

                            </div>
                        </div>

                    </form>

                </div>

            </collapse-transition>
        </div>
    </div>
</template>

<script>
import CollapseTransition from '@ivanv/vue-collapse-transition'
import {mapState, mapMutations, mapActions} from 'vuex';
import {required, minLength, between, email, sameAsPassword, sameAs, not} from 'vuelidate/lib/validators';


export default {
    name: 'password-settings',
    components: {
        CollapseTransition: CollapseTransition
    },
    data() {
        return {
            open: true,
            open_edit: false,
            save_button: 'Change Password',
            err_status: {
                isErr: false,
                err_msg: null
            },
            password_validators: {
                lowercase: false,
                uppercase: false,
                number: false
            },
            password: {
                current_password: null,
                new_password: null,
                confirm_password: null
            }


        }
    },
    validations: {
        password: {
            current_password: {required},
            new_password: {
                required,
                minlength: minLength(8),
                hasLength: function (value) {
                    if (value) {
                        return value.length >= 8;
                    } else {
                        return false
                    }
                },
                containsUppercase: function (value) {
                    return /[A-Z]/.test(value);
                },
                containsLowercase: function (value) {
                    return /[a-z]/.test(value);

                },
                containsNumber: function (value) {
                    return /[0-9]/.test(value);
                },
                notSame: not(sameAs('current_password'))
            },
            confirm_password: {required, minlength: minLength(8), sameAsPassword: sameAs("new_password")},
        }
    },
    methods: {
        openContainer() {

            let vm = this;

            this.open = !this.open;
        },

        changePassword() {

            let vm = this;


            //if the form is invalid disable the button
            if (this.$v.$invalid) {
                let vm = this;
                return;
            }

            this.save_button = 'Resetting Password';


            //pass the password payload data into the resetPassword Vuex Action
            vm.resetPassword(this.password).then(response => {

                vm.save_button = 'Password Saved';

                //reset the err status
                vm.err_status = {
                    isErr: false,
                    err_msg: null
                };

                //reset the password fields
                vm.password = {
                    current_password: null,
                    new_password: null,
                    confirm_password: null
                }

                //reset the validation
                this.$nextTick(() => {
                    this.$v.$reset();
                })


            }).catch(err => {
                this.err_status.isErr = true;
                if ('text' in err && err.text === 'InvalidPassword') {
                    this.err_status.err_msg = err.message;
                }
                vm.save_button = 'Change Password';








            })

        },

        ...mapActions({
            resetPassword: 'user/resetPassword',
        }),
    }
}

</script>
<style lang="scss">
.profile {
    &__password-reset-validation {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            font-family: 'ZP-UI', sans-serif;
            font-size: 0.875rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-bottom: 2rem;


            li {
                vertical-align: middle;

                &.active {
                  ion-icon {
                      color: $nature400;
                  }
                }

                ion-icon {
                    display: inline-block;
                    vertical-align: middle;
                    color: $stone400;
                }

            }

        }
    }
}
</style>
