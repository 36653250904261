<template>
	<div class="h-input__container">
		<input
			:type="type"
			:value="value"
			:id="id"
			:name="name"
			:placeholder="placeholder" 
			v-mask="mask"
			@input="$emit('input', $event.target.value) && vuelidate.$touch()"
			@keydown.enter="$emit('keydown.enter')"
			class="h-input__input"
			:class="{ 'h-input__error-border': error_border }"
			ref="input_element"
		/>
		<label class="h-input__label" :class="{ 'h-input__has-value': value }" :for="id">{{ label }}</label>
		<span class="h-input__error" v-if="errors && errors.length" v-for="error in errors">{{ error ? error : null }}</span>
	</div>
</template>

<script>
export default {
	name: 'TextInput',
	props: {
		type: {
			type: String,
			default: 'text',
		},
		value: {
			type: String,
		},
		id: {
			type: String,
		},
		name: {
			type: String,
		},
		label: {
			type: String,
			required: true,
		},
		placeholder: {
			type: String,
		},
		vuelidate: {
			type: Object,
			required: true,
		},
		mask: {
			type: String,
		},
		errors: {
			type: Array,
		},
		error_border: {
			Boolean,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';

.h-input {
	&__container {
		position: relative;
		font-family: 'ZP-UI', sans-serif;
	}

	&__input {
		width: 100%;
		height: 62px;
		font-family: inherit;
		font-size: 1rem;
		background-color: $white-100;
		border: 0.0625rem solid $border-default;
		padding: 1.5rem 1rem 0.5rem;
		transition: all 200ms cubic-bezier(0.33, 0, 0, 1) 0s;
	}

	&__label {
		position: absolute;
		left: 1rem;
		top: 1.5rem;
		color: $gray-700;
		font-size: 0.875rem;
		transform-origin: 0% 0%;
		transition: all 200ms cubic-bezier(0.33, 0, 0, 1) 0s;
		cursor: text;
		pointer-events: none;
	}

	&__has-value {
		top: 0.5rem !important;
		left: 1rem !important;
		color: $gray-700;
		transform: scale(0.75) !important;
	}

	&__error {
		display: inline-block;
		color: $errors;
		font-family: 'Basel Grotesk', sans-serif;
		font-size: 0.75rem;
		font-weight: 500;
		padding-top: 0.125rem;
	}

	&__error-border {
		border-color: $errors;
	}

	&__input:focus + &__label {
		top: 0.5rem;
		left: 1rem;
		color: #757575;
		transform: scale(0.75) !important;
	}
}
</style>
