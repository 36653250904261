<template>
    <div class="protocol-quantity">
        <label class="protocol-body480 strong" for="custom-quantity">Quantity per month</label>
        <div v-if="quantity_info"
             :class="{'text-input-container-transparent': !!medication_type}"
             class="protocol-quantity__input">
            <select id="custom-quantity" v-model="product_units"
                    class="protocol__select" name="custom-quantity"
                    @change="on_quantity(product_units)">
                <option v-for="(value, index) in custom_quantities"
                        :value="value">
                    {{ value }} {{ dosage_strength.suffix ? dosage_strength.suffix : '' }}
                </option>
            </select>
            <span v-if="medication_type"
                  class="treatment-checkout__medication-type">{{ medication_type }}</span>
        </div>
        <div v-if="selected_duration && selected_duration.months > 1">
            <p class="protocol-caption">
                <span>Total Quantity: {{ (product_units * selected_duration.months) }}</span>
                for {{ selected_duration.months }} months</p>
        </div>
    </div>
</template>
<script>

export default {
    name: 'TreatmentQuantitySelection',
    components: {},
    props: ['quantity_info', 'medication_type', 'dosage_strength', 'custom_quantities', 'selected_duration', 'units'],
    data() {
        return {
            product_units: null
        }
    },
    created() {
        this.product_units = this.units;
    },
    methods: {
        on_quantity(product_units) {
            this.$emit('onQuantityChange', product_units);
        }
    }
}
</script>
<style lang="scss"></style>
