<template>
    <div>
        <ul class="protocol-recommended">
            <li :class="{'active': preferences_option === 'recommended'}"
                class="protocol-recommended__option"
                data-content="Powered by Healthspan"
                @click="onChange('recommended')">
                <div class="protocol-recommended__input">
                    <input id="radio-1"
                            v-model="preferences_option"
                            checked
                            name="radio"
                            type="radio"
                            value="recommended">
                    <label for="radio-1"></label>
                </div>
                <div class="protocol-recommended__option-header" :class="{'active': preferences_option === 'recommended'}">
                    <p class="protocol-body480 strong">Recommended</p>
                    <blockquote v-if="preferences_instructions.recommended"
                                class="protocol-body480">
                        {{ preferences_instructions.recommended }}
                    </blockquote>
                </div>
                
                <div v-if="dose_selection_text.recommended"
                     class="protocol-caption" :class="{'active': preferences_option === 'recommended'}">
                    {{ dose_selection_text.recommended }}
                </div>
                <div class="protocol-recommended__badge--mobile">
                    <h-badge label="Optimal" color="#55D291" dark small no_border/>
                </div>
                <div class="protocol-recommended__badge--desktop">
                    <h-badge label="Powered By Healthspan" color="#55D291" dark small no_border/>
                </div>
            </li>
            <li :class="{'active': preferences_option === 'starter'}"
                class="protocol-recommended__option"
                @click="onChange('starter')">
                <div class="protocol-recommended__input">
                    <input id="radio-2"
                            v-model="preferences_option"
                            name="radio"
                            type="radio"
                            value="starter">
                    <label class="hs-radio-label" for="radio-2"></label>
                </div>
                    <div class="protocol-recommended__option-header" :class="{'active': preferences_option === 'starter'}">
                        <p class="protocol-body480 strong">Starter</p>
                        <blockquote v-if="preferences_instructions.starter"
                                    class="protocol-body480">
                            {{ preferences_instructions.starter }}
                        </blockquote>
                    </div>
                
                <div v-if="dose_selection_text.starter"
                     class="protocol-caption" :class="{'active': preferences_option === 'starter'}">
                    {{ dose_selection_text.starter }}
                </div>
            </li>
            <li :class="{'active': preferences_option === 'request'}"
                class="protocol-recommended__option"
                v-if="recommendation_request"
                @click="onChange('request')">
                <div class="protocol-recommended__input">
                    <input id="radio-3"
                            v-model="preferences_option"
                            name="radio"
                            type="radio"
                            value="request">
                    <label class="hs-radio-label" for="radio-3"></label>
                </div>
                    <div class="protocol-recommended__option-header" :class="{'active': preferences_option === 'request'}">
                        <p class="protocol-body480 strong">Assistance</p>
                        <blockquote class="protocol-body480">Have your clinical team provide a dosing protocol based on your biomarkers and health goals.</blockquote>
                    </div>
                
                <div class="protocol-caption" :class="{'active': preferences_option === 'request'}">
                    Our clinical team can recommend a treatment protocol for you based on your biomarkers and health goals. For now, we will select the Starter Plan for you and provide you with a more detailed treatment dosage recommendation once your provider has reviewed your health history.
                </div>
            </li>
            <li :class="{'active': preferences_option === 'custom'}"
                class="protocol-recommended__option"
                @click="onChange('custom')">
                <div class="protocol-recommended__input">
                    <input id="radio-3"
                            v-model="preferences_option"
                            name="radio"
                            type="radio"
                            value="custom">
                    <label class="hs-radio-label" for="radio-3"></label>
                </div>
                    <div class="protocol-recommended__option-header" :class="{'active': preferences_option === 'custom'}">
                        <p class="protocol-body480 strong">Custom</p>
                        <blockquote class="protocol-body480">Select a custom
                            treatment
                        </blockquote>
                    </div>
                
                <div v-if="dose_selection_text.custom"
                     class="protocol-caption" :class="{'active': preferences_option === 'custom'}">
                    {{ dose_selection_text.custom }}
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import HBadge from "@/components/Shared/HBadge.vue"

export default {
    name: 'TreatmentRecommendedOptions',
    components: {HBadge},
    props: ['dose_selection_text', 'preferences_instructions', 'recommendation_request'],
    data() {
        return {
            preferences_option: 'recommended'
        }
    },
    methods: {
        onChange(option) {
            this.preferences_option = option;
            this.$emit('onRecommendedSelected', option);

            if (option === 'custom') this.scrollToBottom();
        },
        scrollToBottom() {
            setTimeout(() => {
                window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth'
                });
            }, 100);
        }
    }
}
</script>
<style lang="scss"></style>
