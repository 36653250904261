<template>
    <div v-if="all_pending_intakes.length" class="profile__alert">
        <div class="pending-visits__container">
            <div class="pending-visits__header">
                <h2 class="headline-3-slim">
                    Visits in Review
                </h2>
                <p class="cereal-text-18">
                    A physician is reviewing your visit!
                </p>
            </div>
            <div class="pending-visits__items">
                <div v-for="(visit, index) in all_pending_intakes" :key="index" class="pending-visits__item">
                    <div v-for="product in getProducts(visit)" :key="product.product._id" class="pending-visits__item-header">
                        <div class="pending-visits__item-product">
                            <div
                                class="pending-visits__item-image"
                                :style="{'background-image': `url(${product.product_template.product_images[0]})`}"
                            ></div>
                            <div class="pending-visits__item-description">
                                <div v-if="product.recommendation_requested" class="treatment-total__review treatment-total__review-alt">
                                    Clinical Team Assistance
                                </div>
                                <div class="pending-visits__item-title">
                                    {{ product.product_template.product_name }}
                                </div>
                                <div class="pending-visits__item-desc">
                                    <div class="pending-visits__product-desc">
                                        <ul class="pending-visits__product-duration">
                                            <li>Billing every {{ product.duration }} month{{ product.duration > 1 ? 's' : '' }}</li>
                                            <li> {{ product.prescriptions[0].medication.patient_instructions }}</li>
                                            <li>${{ generatePrice(product) }}</li>
                                        </ul>
                                    </div>
                                    <span v-if="product.one_time" class="subscription-item__status pending">One Time</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pending-visits__status">
                        <div class="pending-visits__status-title">
                            Status
                        </div>
                        <div class="pending-visits__status-item">
                            <div class="subscription-item__status">
                                Awaiting Physician Review
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import Product from '../../services/Product';

export default {
    name: 'PendingVisits',
    data() {
        return {
            options: {
                currentPage: 0,
                infinite: 4,
                slidesToScroll: 4,
                loop: true,
                thresholdDistance: 0,
                thresholdTime: 300,
            },
            configured_product: null,
        };
    },
    async created() {
        try {
            await this.getIntakes({ user: this.user._id });
        } catch (error) {
            console.error(error);
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            intake_instance: state => state.intake.currentIntakeInstance.data,
            pending_intakes: state => state.intake.patient_intakes,
        }),
        all_pending_intakes() {
            if (this.pending_intakes.data) {
                return this.pending_intakes.data.filter(intake => {
                    return !intake.physician_review && intake.patient_complete && !intake.closed;
                });
            }

            return [];
        },
    },
    methods: {
        ...mapActions({
            get_products: 'productTemplates/getManyProductTemplatesByIds',
            retrieve_incomplete_intakes: 'intake/retrieveIncompleteIntakes',
            close_intake: 'intake/patientClosesIntake',
            getIntakes: 'intake/retrievePatientIntakes',

        }),
        ...mapMutations({
            set_intake: 'intake/setIntakeId',

        }),
        generatePrice(intakeProduct) {
            const price = Product.generatePrice(intakeProduct.product,
                { has_custom_quantity: intakeProduct.has_custom_quantity },
                intakeProduct.membership_price,
                (intakeProduct.quantity / intakeProduct.duration),
            );

            return price.total_discount.toFixed(2);

        },
        sorted(products) {
            return [...products].sort((a, b) => {
                if (a.one_time && !b.one_time) {
                    return -1;
                } else if (!a.one_time && b.one_time) {
                    return 1;
                } else {
                    return 0;
                }
            });
        },
        getProducts(visit) {
            return this.sorted(visit.products.filter(product => !product.one_time));
        },
    },
};
</script>
<style lang="scss">
.pending-visits {
    &__container {
        border-radius: 12px;
        border: 1px solid #EBEBEB;
        background-color: #f7f7f7;
        overflow: hidden;
        padding: 2rem 1.5rem;

        @media (min-width: 760px) {
            padding: 2rem;
        }
    }

    &__items {
        display: flex;
        gap: 1.5rem;
        margin-top: 2rem;
        justify-content: start;
        flex-wrap: wrap;
        width: 100%;

        @media screen and (max-width: 1090px) {
            flex-direction: column;
        }
    }

    &__item {
        background-color: #FFFFFF;
        border-radius: 12px;
        box-shadow: rgb(0 0 0 / 8%) 0px 0.25rem 0.75rem;
        padding: 2rem 1.5rem;

        @media (min-width: 760px) {
            max-width: 340px;
        }
    }

    &__item-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-family: "ZP-UI", sans-serif;
        align-items: center;
        border-bottom: 1px solid #EBEBEB;
        padding-bottom: 2rem;
        padding-top: 1rem;

        @media screen and (max-width: 1090px) {
            justify-content: start;
        }
    }

    &__item-product {
        display: flex;
        width: 100%;

        @media screen and (max-width: 530px) {
            flex-direction: column;
        }

        @media screen and (max-width: 920px) {
            flex-direction: column;
        }
    }

    &__item-image {
        width: 120px;
        height: 120px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #f9f9f9;
        border-radius: 12px;
        margin-right: 1rem;

        @media screen and (max-width: 1140px) {
            width: 80px;
            height: 80px;
        }

        @media screen and (max-width: 1090px) {
            width: 150px;
            height: 150px;
        }

        @media screen and (max-width: 400px) {
            width: 50px;
            height: 50px;
        }
    }

    &__item-description {
        width: 250px;
        min-height: 115px;
        font-size: 0.875rem;

        @media screen and (max-width: 1090px) {
            width: 80%;
        }
    }

    &__item-title {

        font-weight: 500;
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    &__status {
        margin-top: 1rem;
        font-family: "ZP-UI", sans-serif;
        padding-bottom: 1rem;
    }

    &__status-title {
        font-weight: 500;
        margin-bottom: 1rem;
    }

    &__status-item {
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__product-desc {

        ul {
            list-style: none;
            padding: 0;
            margin: 0.5rem 0;
            display: flex;
            flex-direction: column;
            li {
                padding: 0.25rem 0;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    &__status-type {
        font-weight: 500;
        color: $neutral500;
    }

    &__status-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 1140px) {
            min-width: inherit;
        }

        @media screen and (max-width: 1090px) {
            min-width: 190px;
        }

        @media screen and (max-width: 460px) {
            min-width: inherit;
        }

        &.static {
            justify-content: start;
        }
    }

    &__status-data {
        font-weight: 500;
        margin-right: 1rem;
    }

    &__status-desc {
        font-weight: 500;
        font-size: 0.875rem;
        color: #a7a7a7;
        margin-left: 1.5rem;
    }

    &__status-checkmark {
        width: 20px;
        height: 20px;
        background-color: #a7a7a7;
        border-radius: 100%;
        display: block;

        &.complete {
            background-color: $nature400;
        }

        .arbor-list__checkmark {
            border-left: 1.5px solid #fff;
            border-bottom: 1.5px solid #fff;
            position: relative;
            left: 6px;
            top: -6px;
        }
    }

    &__status-bar {
        width: 150px;
        height: 5px;
        background-color: $nature100;
        border-radius: 12px;

        @media screen and (max-width: 1140px) {
            display: none;
        }

        @media screen and (max-width: 1090px) {
            display: block;
        }

        @media screen and (max-width: 460px) {
            display: none;
        }

    }

    &__status-bar-inner {
        height: 100%;
        background-color: $nature400;
        border-radius: 12px;
    }

    &__footer {
        display: flex;
        margin-top: 1rem;
        justify-content: space-between;

        @media screen and (max-width: 1140px) {
            flex-direction: column;
        }
        button {
            margin-bottom: 1rem;
        }

        button, a {
            width: 48%;

            @media screen and (max-width: 1140px) {
                width: 100%;
            }
        }
    }
}
</style>
