<template>
    <div class="confirm-verify">
        <div class="confirm-verify__image">
            <img src="../assets/multitasking.png" alt="verify-illustration">
        </div>
        <div class="confirm-verify__header typography-header">
            <h1>
                Verify to receive<br>
                real time updates
            </h1>
            <p>Verifying your mobile number ensures you receive real time updates from the support and clinical team. As well as your physician on updates to your protocol.</p>
        </div>
        <section class="confirm-verify__section">
            <div v-if="!codeReceived">
                <label class="confirm-verify__label">Enter Mobile Number</label>
                <div                    
                    class="confirm-verify__input-wrapper"
                >
                    <input 
                        ref="phoneNumber"
                        v-model="mobileNumber"
                        v-mask="'(###) ###-####'"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        class="confirm-verify__input-full"
                        :disabled="!newNumber && !numberMissing"
                    >
                    <div class="confirm-verify__inputBtnWrapper">
                        <HButtonV2 v-if="!newNumber && !numberMissing" variant="tertiary" @click="enterNewNumber">
                            New Number
                        </HButtonV2>
                    </div>
                    <div class="confirm-verify__inputBtnWrapper">
                        <HButtonV2
                            v-if="newNumber"
                            variant="text"
                            @click="cancelNewNumber"
                        >
                            Cancel
                        </HButtonV2>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="confirm-verify__code-header">
                    <label class="confirm-verify__label">Enter Code You Received</label>
                    <HButtonV2 variant="text" @click="getCode">
                        Resend Code
                    </HButtonV2>
                </div>
                <div class="confirm-verify__code">
                    <!-- TODO: simplify with v-for. Look into issues with focusing into input created with v-for. -->
                    <input
                        ref="digit1"
                        v-model="verificationCode[0]" 
                        type="number" 
                        maxlength="1" 
                        pattern="[0-9]*"
                        inputmode="numeric"
                        class="confirm-verify__input-single"
                        @input="handleInput($event, 1)"
                        @keydown="handleInput($event, 1)"
                        @paste="handlePaste"
                    >
                    <input
                        ref="digit2"
                        v-model="verificationCode[1]" 
                        type="number" 
                        maxlength="1" 
                        pattern="[0-9]*"
                        inputmode="numeric"
                        class="confirm-verify__input-single"
                        @input="handleInput($event, 2)"
                        @keydown="handleInput($event, 2)"
                    >
                    <input
                        ref="digit3"
                        v-model="verificationCode[2]" 
                        type="number" 
                        maxlength="1" 
                        pattern="[0-9]*"
                        inputmode="numeric"
                        class="confirm-verify__input-single"
                        @input="handleInput($event, 3)"
                        @keydown="handleInput($event, 3)"
                    >
                    <input
                        ref="digit4"
                        v-model="verificationCode[3]" 
                        type="number" 
                        maxlength="1" 
                        pattern="[0-9]*"
                        inputmode="numeric"
                        class="confirm-verify__input-single"
                        @input="handleInput($event, 4)"
                        @keydown="handleInput($event, 4)"
                    >
                    <input
                        ref="digit5"
                        v-model="verificationCode[4]" 
                        type="number" 
                        maxlength="1" 
                        pattern="[0-9]*"
                        inputmode="numeric"
                        class="confirm-verify__input-single"
                        @input="handleInput($event, 5)"
                        @keydown="handleInput($event, 5)"
                    >
                </div>
            </div>
        </section>
        <transition name="alert-fade" mode="out-in">
            <div v-if="validateError" class="confirm-alert">
                <HAlert :message="validateErrorText" status="critical" @dismiss="dismissAlert" />
            </div> 
        </transition>
        <div class="confirm-verify__btn-wrapper">
            <HButtonV2 full-width :loading="loading" @click="codeReceived ? submitCode() : submitPhoneNumber()">
                {{ buttonLabel }}
            </HButtonV2>
        </div>
    </div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';
import { mapActions, mapState } from 'vuex';

import HAlert from '@/components/Shared/HAlert';
import HButtonV2 from '@/components/Shared/HButtonV2';

export default {
    name: 'MobileVerification',
    components: {
        HButtonV2,
        HAlert,
    },
    data() {
        return {
            loading: false,
            codeVerified: false,
            codeReceived: false,
            mobileNumber: null,
            numberMissing: false,
            code: null,
            error: '',
            newNumber: false,
            buttonLabel: 'Get Code',
            verificationCode: ['', '', '', '', ''],
            validateError: false,
            validateErrorText: null,
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
        codeFilled() {
            return this.verificationCode.every(element => typeof element === 'string' && element.trim() !== '');
        },
    },
    watch: {
        codeReceived(newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.$refs.digit1.focus();
                });
            }
        },
        codeFilled(newValue) {
            if (newValue) {
                this.submitCode();
            }
        },
    },
    created() {
        this.mobileNumber = parsePhoneNumber(this.user.mobile_number, 'US').nationalNumber;
        this.numberMissing = !this.user.mobile_number;
    },
    methods: {
        enterNewNumber() {
            this.newNumber = true;
            this.mobileNumber = '';
            this.$nextTick(() => {
                this.$refs.phoneNumber.focus();
            });
        },
        cancelNewNumber() {
            this.newNumber = false;
            this.mobileNumber = this.user.mobile_number;
            this.validateError = false;
        },
        async verifyCode() {
            this.loading = true;

            try {
                await this.$store.dispatch('confirmMobile', this.code);
                this.$router.push({ name: 'lab-success' });
            } catch (error) {
                this.error = error.message;
            } finally {
                this.loading = false;
            }
        },
        handleInput(event, index) {
            const value = event.target.value;

            if (event.type === 'input') {
                if (value.length === 1 && index < 5) {
                    this.$refs[`digit${index + 1}`].focus();
                }
            } else if (event.type === 'keydown') {
                if (event.key === 'Backspace' && value === '' && index > 1) {
                    this.$refs[`digit${index - 1}`].focus();
                }
            }
        },
        handlePaste(event) {
            event.preventDefault();
            const pastedData = event.clipboardData.getData('text');
            
            if (/^\d{5}$/.test(pastedData)) {
                const digits = pastedData.split('');

                this.verificationCode = digits;
                this.$nextTick(() => {
                    this.$refs.digit5.focus();
                });
            }
        },
        async submitCode() {
            const codeFilled = this.verificationCode.every(element => typeof element === 'string' && element.trim() !== '');

            if (!codeFilled) {
                this.validateErrorText = 'Please enter verification code.';
                this.validateError = true;
            } 

            this.loading = true;

            try {
                const verificationCodeString = this.verificationCode.join('');
                
                await this.verifyMobileNumber({ verification_code: verificationCodeString });

                await this.getUser({ user: this.user._id });
                this.loading = false;
                this.$emit('submit-success');
                document.body.style.overflow = 'unset';
                this.$forceUpdate();
            } catch (error) {
                this.validateErrorText = 'The code you entered is incorrect. Please try again.';
                this.validateError = true;
                this.loading = false;
            }
        },
        dismissAlert() {
            this.validateError = false;
            this.mobileNumber = '';
            this.$nextTick(() => {
                this.$refs.phoneNumber.focus();
            });
        },
        async submitPhoneNumber() {
            if (this.mobileNumber && this.mobileNumber.length > 2) {
                this.getCode();
            } else {
                this.validateErrorText = 'Please enter a valid phone number.';
                this.validateError = true;
            }
        },
        async getCode() {
            this.loading = true;
            const formattedNumber = parsePhoneNumber(this.mobileNumber, 'US');

            if (formattedNumber.isValid()) {
                try {
                    await this.sendVerificationCode({ number: formattedNumber.number });
                    this.codeReceived = true;
                    this.buttonLabel = 'Submit';
                } catch (err) {
                    this.validateErrorText = 'There was an error, please try again.';
                    this.validateError = true;
                }
            } else {
                this.validateErrorText = 'The number you entered is incorrect. Please try again.';
                this.validateError = true;
            }
            
            this.loading = false;
        },
        ...mapActions({
            getUser: 'user/retrieveUser',
            sendVerificationCode: 'user/sendVerificationCode',
            verifyMobileNumber: 'user/verifyMobile',
        }),
    },
};
</script>

<style lang="scss" scoped>

</style>
