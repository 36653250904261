<template>
    <div>
        <checkout-nav v-if="section === 'profile'"></checkout-nav>
        <consultation-hiw v-if="section === 'lab-visit'"></consultation-hiw>
        <patient-profile-step
            v-if="section === 'profile'"
            @onContinue="onProfileContinue"
        ></patient-profile-step>
        <ProductOnboarding v-if="section === 'product-plan'" />
        <ProductCheckout v-if="section === 'checkout'" />
    </div>
</template>
<script>
import { mapState } from 'vuex';

import CheckoutNav from '@/components/Checkout/CheckoutNav';
import ConsultationHiw from '@/components/intake/ConsultationHiw.vue';
import PatientProfileStep from '@/components/intake/PatientProfileStep';
import ProductCheckout from '@/components/ProductOnboarding/ProductCheckout';
import ProductOnboarding from '@/components/ProductOnboarding/ProductOnboarding';

export default {
    name: 'SignupWrapper',
    components: {
        ConsultationHiw,
        checkoutNav: CheckoutNav,
        PatientProfileStep,
        ProductOnboarding,
        ProductCheckout,
    },
    data() {
        return {
            section: this.$route.params.section,
        };
    },
    watch: {
        '$route.params.section': {
            async handler(section) {
                this.section = section;
                this.$forceUpdate();
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            selectedProduct: (state) => state.productTemplates.selectedProduct,
        }),
    },
    methods: {
        async onProfileContinue() {
            const product = this.selectedProduct || JSON.parse(window.localStorage.getItem('zp_product_template'));

            if (product) {
                await this.$router.push({ path: `/checkout/${product.type}/${product.id}` });
            } else {
                await this.$router.push({ path: `/dashboard/${this.user._id}/profile` });
            }
        },
    },
};
</script>
