<template>
    <div class="lab-results">
        <!--if no results (empty state)-->
        <h-empty-page
            v-if="!labResults"
            graphic_type="labs"
            title="Labs"
            descriptions="We offer labs in a few of our different protocols. Please message us for more information."
            button_label="Message Us"
            :handle-button-click="handleEmptyButton"
        />

        <!--if there are results-->
        <div v-else>
            <header class="lab-results__header">
                <div>
                    <h1>Labs</h1>
                    <p>
                        Healthspan provides personalized strategies to optimize your health and longevity by monitoring key health indicators through regular check-ins and lab result reviews with your
                        provider.
                    </p>
                </div>
                <HButtonLink variant="tertiary" href="/#/lab-instructions">
                    Lab Instructions
                </HButtonLink>
            </header>
            <h-category-select :options="options" :status="option_status" @handleselectedOption="handleselectedOption" />

            <section class="lab-results__list">
                <div v-if="!lab_list.length">
                    <div class="lab-results__empty-item">
                        <div class="lab-results__empty-item__icon">
                            <ion-icon name="flask-outline" />
                        </div>
                        <div class="lab-results__empty-item__text">
                            <p>
                                Currently, there are no lab tests
                                {{
                                    option_status === 'received'
                                        ? 'that are awaiting review'
                                        : option_status === 'ordered'
                                            ? 'that have been ordered'
                                            : option_status === 'reviewed'
                                                ? 'that are ready for results'
                                                : null
                                }}.
                            </p>
                            <!-- <p>To order a new lab test, please visit our <a href="#/shop">shop</a> section.</p> -->
                        </div>
                    </div>
                </div>
                <Alert
                    v-if="labOrderAlertMessage"
                    :type="alertType"
                    :title="labOrderAlertMessage"
                    icon="information-circle"
                    :action-text="labOrderActionText"
                    @onAction="onMessage"
                />
                <list-item
                    v-for="item in labList"
                    :id="item.id"
                    :key="item.id"
                    :img_url="item.imgUrl"
                    :type="'labs'"
                    :primary_button="item.status === 'created' && item.order.status.fulfilled ? false : {label: item.label, method: async () => onPrimaryAction(item) }"
                    :secondary_button="{ label: 'Message Us', method: () => onSecondaryAction() }"
                    @primaryError="onPrimaryError"
                    @primarySuccess="onPrimarySuccess"
                >
                    <template #descriptions>
                        <div class="descriptions__title">
                            <p>{{ item.product.name ? item.product.name : '' }}</p>
                            <HInfoIcon :content="item.product.description" />
                        </div>
                        <div v-if="item.order.status.fulfilled" class="descriptions__caption">
                            Last updated on: {{ formattedDate(item.updated_at) }}
                        </div>
                        <div v-if="item.order.status.fulfilled" class="labs-status">
                            <status-bar :status="getStatus(item.status)" :reviewed-date="formattedDate(item.created_at)" />
                        </div>
                        <div v-if="!item.order.status.fulfilled" class="message">
                            You have a pending lab order that needs to be completed for us to provide you with an in-depth analysis. Please select the <strong>Order Lab</strong> option to get started.
                        </div>
                    </template>
                    <template v-if="item.order.status.fulfilled" #status>
                        <div class="status labs-status-mobile">
                            <status-bar :status="getStatus(item.status)" :reviewed-date="formattedDate(item.created_at)" />
                        </div> 
                    </template>
                </list-item>
            </section>
            <div v-if="lab_list.length">
                <h-pagination
                    :total-pages="total_pages"
                    :current-page="current_page"
                    :has-prev-page="has_prev_page"
                    :has-next-page="has_next_page"
                    :page-number-limit="5"
                    :paging-counter="1"
                    @handlePageChange="handlePageChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Alert from '@/components/Components/Alert.vue';
import HButtonLink from '@/components/Shared/HButtonLink.vue';
import HCategorySelect from '@/components/Shared/HCategorySelect.vue';
import HEmptyPage from '@/components/Shared/HEmptyPage.vue';
import ListItem from '@/components/Shared/HListItem.vue';
import HPagination from '@/components/Shared/HPagination.vue';
import { createFormatDate, downloadPdfToDevice } from '@/helpers/labs';
import HInfoIcon from '@/components/Shared/HInfoIcon.vue';
import StatusBar from './Subcomponents/StatusBar.vue';

export default {
    name: 'LabsPage',
    components: {
        HEmptyPage,
        HCategorySelect,
        HButtonLink,
        ListItem,
        HPagination,
        Alert,
        HInfoIcon,
        StatusBar,
    },
    data() {
        return {
            labOrderAlertMessage: null,
            labOrderActionText: null,
            labResults: false,
            lab_list: [],
            alertType: 'error',
            options: [
                {
                    id: 0,
                    label: 'Show All',
                    status: 'all',
                },
                {
                    id: 1,
                    label: 'Results Ready',
                    status: 'reviewed',
                },
                {
                    id: 2,
                    label: 'Awaiting Results',
                    status: 'received',
                },
                {
                    id: 3,
                    label: 'Ordered',
                    status: 'ordered',
                },
            ],
            option_status: 'all',
            current_page: 1,
            total_pages: 0,
            has_prev_page: false,
            has_next_page: false,
        };
    },
    computed: {
        ...mapState({
            lab_results: (state) => state.labResults.lab_results,
            requisition_pdf: (state) => state.labResults.requisition_pdf,
            user: (state) => state.user.user,
        }),
        labList() {
            return this.lab_list.map(item => {
                const imgUrl = item.product.images ? item.product.images[0] : '';
                const label = this.set_primary_label(item);

                return {
                    ...item,
                    imgUrl,
                    label,
                };
            });
        },
    },
    async created() {
        await this.fetchLabResults();
    },
    methods: {
        ...mapActions({
            getLabsResults: 'labResults/getLabsResults',
            getRequisitionPDF: 'labResults/getRequisitionPDF',
            submitOrder: 'orders/submitOrder',
        }),
        set_primary_label(item) {
            let label;

            if (item.status === 'created') {
                label = item.order.status.fulfilled ? null : 'Order Lab';
            } else if (item.status === 'reviewed') {
                label = 'View Analysis';
            } else {
                label = 'Download Form';
            }

            return label;
        },
        formattedDate: createFormatDate('MMMM Do YYYY'),
        getStatus(status) {
            switch (status) {
            case 'created':
                return 'pending';
            case 'ordered':
                return 'prepared';
            case 'received':
                return 'awaiting';
            case 'reviewed':
                return 'reviewed';
            default:
                return 'pending';
            }
        },
        async fetchLabResults() {
            let queries = {};

            this.option_status === 'all'
                ? (queries = { page: this.current_page })
                : this.options_status === 'created' || this.option_status === 'ordered'
                    ? (queries = { page: this.current_page, status: 'ordered,created' })
                    : (queries = { page: this.current_page, status: this.option_status });

            try {
                const { docs, page, totalPages, hasNextPage, hasPrevPage } = await this.getLabsResults({ queries });

                this.total_pages = totalPages;
                this.has_next_page = hasNextPage;
                this.has_prev_page = hasPrevPage;

                if (this.ordered_status === 'all' && docs && docs.length === 0) {
                    this.labResults = false;
                } else {
                    this.labResults = true;
                    this.lab_list = docs;
                    this.current_page = page;
                }
            } catch (e) {
                /* eslint-disable no-console */
                console.log('getLabsResults', e);
            }
        },
        handleShop() {
            this.$router.push({ path: '/shop' });
        },
        async onMessage() {
            await this.$router.push({ path: `/dashboard/${this.user._id}/messages` });
        },
        async handleselectedOption(option) {
            this.option_status = option.status;
            this.fetchLabResults();
            this.current_page = 1;
        },

        async onPrimaryAction(option) {

            this.labOrderAlertMessage = null;

            if (option.label === 'View Analysis') {
                await this.$router.push(`/dashboard/${this.user._id}/lab-analysis/${option._id}`);
            }

            // new label Order Lab
            if (option.label === 'Order Lab') {
                try {
                    await this.submitOrder({
                        organization: option.organization,
                        order_id: option.order._id,
                    });

                    this.$router.push(`/lab-instructions/${option.order._id}`);
                } catch (error) {
                    this.alertType = 'error';
                    this.labOrderActionText = 'Message Us';
                    this.labOrderAlertMessage = 'There was an error ordering your lab.';
                }
            }

            if (option.label === 'Download Form') {
                const response = await this.getRequisitionPDF({ reportId: option._id });
                const file = new Blob([response.data], { type: 'application/pdf' });

                downloadPdfToDevice(file, 'requisition_form.pdf');
            }
        },

        onSecondaryAction() {
            this.onMessage();
        },

        async onPrimarySuccess(event) {
            this.alertType = 'success';
            this.labOrderActionText = null;

            let success_message = 'Success!';

            if (event.variant.label === 'Order Lab') {
                await this.fetchLabResults();
                this.labOrderAlertMessage = 'Lab Order Successful!';
            } else {
                this.labOrderAlertMessage = success_message;
            }
            setTimeout(() => {
                this.labOrderAlertMessage = null;
            }, 5000);
        },

        onPrimaryError(error) {
            this.alertType = 'error';
            this.labOrderActionText = 'Message Us';
            this.labOrderAlertMessage = 'There was an error ordering your lab.';

            if (error.error?.status === 500) {
                this.labOrderAlertMessage = 'There was an error, please contact us.';
            }
        },

        handleEmptyButton() {
            this.$router.push(`/dashboard/${this.user._id}/messages`);
        },
        handlePageChange(page) {
            this.current_page = page;
            this.fetchLabResults();
        },
    },
};
</script>

<style lang="scss" scoped>
.lab-results {
	&__header {
		margin-bottom: 1.5rem;

		h1 {
			font-family: 'ZP-UI', sans-serif;
			font-size: 1.25rem;
			line-height: 1.33;
			font-weight: 500;
			text-transform: capitalize;
		}

		p {
			font-family: 'ZP-UI', sans-serif;
			font-size: 1rem;
			line-height: 1.5rem;
		}

		a {
			display: inline-block;
			margin-top: 0.5rem;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		gap: 1.25rem;
	}

	&__empty-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		background-color: #ffffff;
		border: 1px solid #f1f1f1;
		border-radius: 12px;
		padding: 2rem;
		transition: all 0.3s ease;

		&__icon {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__text {
			display: flex;
			flex: 1;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;

			p {
				font-family: 'ZP-UI', sans-serif;
				font-size: 0.875rem;
				line-height: 1.5rem;
				padding: 0;
				margin: 0;

				&:first-child {
					font-size: 0.9375rem;
					font-weight: 500;
				}
			}

			a {
				font-weight: 500;
				color: #000000;
				text-decoration: underline;
			}
		}
	}

	&__status {
		display: flex;
	}

	ion-icon {
		font-size: 1.5rem;
		color: #545454;
		--ionicon-stroke-width: 1.5rem;
	}

	@media (min-width: 760px) {
		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 1.5rem;
		}

		h1 {
			font-size: 1.5rem;
		}
	}
}
</style>
