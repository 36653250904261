<template>
    <div class="settings-section">
        <div class="settings-alert">
            <transition name="alert-fade" mode="out-in">
                <HAlert v-if="alert.status" :message="alert.message" :status="alert.status" @dismiss="closeAlert" />
            </transition>
        </div>
        <div class="settings-section__return settings-mobile" @click="handleReturn">
            Return to Settings
        </div>
        <div class="settings-section__header">
            <h2>Shipping Address</h2>
            <p>Manage your shipping address used to ship your treatments.</p>
        </div>
        <div>
            <div v-if="existingAddress" class="settings-shipping__existing">
                <p class="settings-shipping__subheader">
                    Ship To
                </p>
                <div class="settings-shipping__container">
                    <div class="checkout-existing__address">
                        <img src="@/assets/icons/location.svg" alt="">
                        <div>
                            <div>{{ user.shipping_info.street_address }}</div>
                            <div>{{ user.shipping_info.city }} {{ user.shipping_info.state }} {{ user.shipping_info.zip_code }}</div>
                        </div>
                    </div>
                    <div class="link-button" @click="handleNewAddress()">
                        <span>Edit</span>
                    </div>
                </div>
            </div>
            <div v-if="editAddress">
                <Autocomplete 
                    :shipping="user.shipping_info"
                    @shipping-change="onShippingChange"
                />
                <h-button 
                    label="Submit" 
                    variant="primary" 
                    :loading="submitting" 
                    :handle-click="onSubmit" 
                    class="settings-section__btn"
                />
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapActions, mapState } from 'vuex';

import HAlert from '@/components/Shared/HAlert.vue';
import HButton from '@/components/Shared/HButton.vue';
import Alert from '@/helpers/alert';

import Autocomplete from './Subcomponents/Autocomplete.vue';

export default {
    name: 'ShippingSection',
    components: {
        Autocomplete,
        HButton,
        HAlert,
    },
    data() {
        return {
            submitting: false,
            existingAddress: false,
            editAddress: false,
            shippingInfo: {
                first_name: '',
                last_name: '',
                street_address: '',
                apt_suite: '',
                city: '',
                state: '',
                zip_code: '',
                country: 'us',
                phone_number: '',
            },
            validations: null,
            alert: new Alert(),
        };
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
        hasAddress() {
            const shipping = this.user.shipping_info;

            return !['street_address', 'city', 'state', 'zip_code', 'country', 'phone_number'].some(key => !shipping[key]);
        },
    },
    created() {
        this.initializeAddress();
    },
    methods: {
        initializeAddress() {
            if (!this.hasAddress) {
                this.existingAddress = false;
                this.editAddress = true;
            } else {
                this.shippingInfo = this.user.shipping_info;
                this.editAddress = false;
                this.existingAddress = true;
            }
        },
        handleReturn() {
            this.$router.push(`/dashboard/${this.user._id}/settings`);
        },
        closeAlert() {
            this.alert.dismiss();
        },
        handleNewAddress() {
            this.existingAddress = false;
            this.editAddress = true;
        },
        onShippingChange(shipping) {
            this.shippingInfo = shipping.data;
            this.validations = shipping.validations;
        },
        async onSubmit() {
            this.submitting = true;

            const payload = {
                _id: this.user._id,
                shipping_info: this.shippingInfo,
                phone_number: this.shippingInfo.phone_number,
            };

            try {
                await this.updateUser(payload);
                this.existingAddress = true;
                this.editAddress = false;
                this.alert.showWithTimeout('Shipping address updated successfully', 'success');
            } catch (error) {
                this.alert.showWithTimeout(error.message, 'critical');
            } finally {
                this.submitting = false;
            }
        },
        ...mapActions({
            updateUser: 'user/updateUser',
        }),
    },
};
</script>
