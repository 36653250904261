<template>
    <div class="consultation-wrapper">
        <div class="consultation-container profile-container">
            <div v-if="alert_modal" v-click-outside="closeModal" class="modal-bg">
                <HSidebar :open="alert_modal" badge_label="Coming Soon" @handleClose="closeModal">
                    <h2 class="profile-modal__title">
                        We are sorry :(
                    </h2>
                    <div v-if="productTemplateStorage && productTemplateStorage.type === 'lab'" class="profile-modal__body">
                        <p>We are sorry, due to regulatory restrictions prohibiting the online prescribing of lab testing, we cannot provide lab testing in <strong class="strong">{{ unavailableLabStates() }}</strong>.</p>
                        <p>In the meantime, connect with us in your patient portal to chat with a member team to get availability updates or see other treatment options.</p>
                    </div>
                    <div v-else class="profile-modal__body">
                        <p>We are sorry, due to regulatory restrictions we cannot see patients in <strong class="strong">{{ userState.name }}</strong>. However, we are moving as quickly as possible to expand into <strong class="strong">{{ userState.name }}</strong>.</p>
                        <p>In the meantime, connect with us in your patient portal to chat with a care team member to get availability updates or see other treatment options.</p>
                    </div>
                    <div class="profile-modal__divider" />
                    <div class="profile-modal__buttons">
                        <HButton
                            v-if="productTemplateStorage && productTemplateStorage.type === 'medication'"
                            label="Continue Visit"
                            variant="primary"
                            :handle-click="onContinue"
                            cta
                            large
                            full_width
                        />
                        <HButton
                            v-if="productTemplateStorage && productTemplateStorage.type === 'lab'"
                            label="View Our Protocols"
                            :handle-click="onContinue"
                            variant="primary"
                            cta
                            large
                            full_width
                        />
                        <HButton
                            label="Enter Dashboard"
                            :handle-click="onDashboard"
                            variant="secondary"
                            cta
                            large
                            full_width
                        />
                    </div>
                </HSidebar>
            </div>
            <form id="profile-step" class="profile__form" @submit.prevent="checkForm()">
                <div>
                    <h2 class="new-consultation-header">
                        Your Profile
                    </h2>

                    <p class="new-consultation-subheader profile__subheader">
                        Your doctor is going to review your health information to
                        see how to best to treat you.
                    </p>
                </div>

                <div class="question-container">
                    <label class="profile__section-subheader">Biological Sex</label>
                    <div class="profile__sex-container">
                        <div class="profile__sex-button__container">
                            <input
                                id="male"
                                v-model="user.sex"
                                class="hidden-radio"
                                type="radio"
                                value="male"
                            >
                            <label
                                class="profile__sex-button"
                                for="male"
                                :class="{ 'profile__sex-button--active' : user.sex === 'male'}"
                            >
                                <div class="radio-text centered">Male</div>
                            </label>
                        </div>
                        <div class="profile__sex-button__container">
                            <input
                                id="female"
                                v-model="user.sex"
                                class="hidden-radio"
                                type="radio"
                                value="female"
                            >
                            <label
                                class="profile__sex-button"
                                for="female"
                                :class="{ 'profile__sex-button--active' : user.sex === 'female'}"
                            >
                                <div class="radio-text centered">Female</div>
                            </label>
                        </div>
                    </div>
                    <span v-if="$v.user.sex.$invalid && $v.user.sex.$dirty" class="profile__error-message">Please select your biological sex.</span>
                </div>

                <div class="question-container">
                    <label class="profile__section-subheader">Date of Birth</label>
                    <div class="profile__input-date__container">
                        <input
                            id="profileDob"
                            v-model="user.birthday"
                            v-mask="'##-##-####'"
                            class="profile__input-date"
                            name="dob"
                            :class="{'profile__input-date--invalid' : ($v.user.birthday.$invalid || !$v.user.birthday.isValidAge) && $v.user.birthday.$dirty}"
                            placeholder="MM-DD-YYYY"
                        />
                        <img class="profile__input-date__icon" src="@/assets/icons/calendar.svg" />
                    </div>
                    <span v-if="$v.user.birthday.$invalid && $v.user.birthday.$dirty" class="profile__error-message">Please provide a valid date of birth. </span>
                    <span v-if="$v.user.birthday.validateDate && !$v.user.birthday.isValidAge && $v.user.birthday.$dirty" class="profile__error-message">You must be 18 or older to see a Health Span physician. </span>
                </div>

                <div>
                    <div class="profile__body">
                        <div class="profile__body-column">
                            <label class="profile__section-subheader" for="profile-lbs">Weight</label>
                            <label for="profile-lbs">
                                <div
                                    class="profile__input-wrapper"
                                    :class="{ 'profile__input-wrapper--focused': ui.weightInputFocused, 'profile__input-wrapper--invalid': $v.body.weight.$invalid && $v.body.weight.$dirty }"
                                >
                                    <label for="profile-lbs">
                                        <img class="profile__input-icon" src="@/assets/icons/scale.svg" />
                                    </label>
                                    <div class="profile__input-group">
                                        <div class="profile__input-container">
                                            <input
                                                id="profile-lbs"
                                                v-model="body.weight"
                                                class="profile__input"
                                                type="number"
                                                @focus="ui.weightInputFocused = true"
                                                @blur="ui.weightInputFocused = false"
                                            />
                                            <span class="profile__input-unit">lbs</span>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="profile__body-column">
                            <label class="profile__section-subheader" for="profile-ft">Height</label>
                            <label for="profile-ft">
                                <div
                                    class="profile__input-wrapper"
                                    :class="{ 'profile__input-wrapper--focused': ui.heightInputFocused, 'profile__input-wrapper--invalid': ($v.body.height_feet.$invalid && $v.body.height_feet.$dirty) || ($v.body.height_inches.$invalid && $v.body.height_inches.$dirty) }"
                                >
                                    <label for="profile-ft">
                                        <img class="profile__input-icon" src="@/assets/icons/ruler.svg" />
                                    </label>
                                    <div class="profile__input-group">
                                        <div class="profile__input-container">
                                            <select
                                                id="profile-ft"
                                                v-model="body.height_feet"
                                                class="profile__body-select"
                                                name="height_feet"
                                                @change="$v.body.height_feet.$touch()"
                                            >
                                                <option></option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                            <label for="profile-ft" class="profile__input-unit">ft</label>
                                        </div>
                                        <div class="profile__input-container">
                                            <select
                                                id="profile-inches"
                                                v-model="body.height_inches"
                                                class="profile__body-select"
                                                name="profile-inches"
                                                @change="$v.body.height_inches.$touch()"
                                            >
                                                <option></option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                            </select>
                                            <label for="profile-inches" class="profile__input-unit">inches</label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <span v-if="$v.body.weight.$invalid && $v.body.weight.$dirty" class="profile__error-message">
                        Please enter your weight in pounds (digits only).
                    </span>
                    <span
                        v-if="($v.body.height_feet.$invalid && $v.body.height_feet.$dirty) || ($v.body.height_inches.$invalid && $v.body.height_inches.$dirty)"
                        class="profile__error-message"
                    >
                        Please enter the correct height.
                    </span>
                </div>

                <div class="question-container">
                    <label class="profile__section-subheader">State</label>
                    <div>
                        <div class="profile__input-select__container">
                            <select
                                id="profile-state"
                                v-model="userState"
                                class="profile__input-wrapper profile__input-select"
                                name="state"
                                :class="{ 'profile__input-wrapper--invalid': $v.userState.$invalid && $v.userState.$dirty }"
                            >
                                <option v-for="(state, index) in states" :key="index" :value="state">
                                    {{ state.name }}
                                </option>
                            </select>
                            <span v-if="!userState" class="profile__input-select__placeholder">Select a state</span>
                            <img class="profile__input-select__icon" src="@/assets/icons/marker-pin.svg" />
                            <img class="profile__input-select__icon profile__input-select__icon--right" src="@/assets/icons/chevron-down.svg" />
                        </div>
                    </div>
                    <span
                        v-if="$v.userState.$invalid && $v.userState.$dirty"
                        class="profile__error-message"
                    >
                        Please select the state that you are located.
                    </span>
                </div>

                <HButton
                    :loading="button_submitting"
                    :label="button_text"
                    variant="cta"
                    large
                    full_width
                    submit
                />
            </form>
            <div class="profile-certification">
                <HCertification />
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import Datepicker from 'vuejs-datepicker';
import IntakeButton from '@/components/Consultation/SubmitButton.vue';
import HButton from '@/components/Shared/HButton.vue';
import HCertification from '@/components/Shared/HCertification.vue';
import HSidebar from '@/components/Shared/HSidebar.vue';
import moment from 'moment';
import { numeric, required, minLength, maxValue, minValue, integer } from 'vuelidate/lib/validators';
import { mapMutations, mapActions } from 'vuex';
import { mapState } from 'vuex';
import { analytics, analyticsActions } from '@/helpers/analytics';
import { unavailableLabStates } from '@/constants/labUnavailableStates';
import { convertToInches, getHeight } from '@/helpers/conversions';

const validateDate = (value) => {
    const dob = moment(value, 'MM-DD-YYYY', true);

    return dob.isValid() && dob.isBefore(moment()) && dob.isAfter(moment().subtract(150, 'years'));
}

const isValidAge = (value) => {
    const age = moment().diff(moment(value, 'MM-DD-YYYY'), 'year');

    return age >= 18;
}

const moreThanZero = (value) => {
    const number = Number(value);

    return number === number && number > 0;
}

export default {
    name: 'ProfileStepOne',
    data() {
        return {
            submitted: false,
            alert_modal: false,
            button_text: 'Continue',
            button_submitting: false,
            body: {
                weight: null,
                height_feet: null,
                height_inches: null
            },
            userState: null,
            ui: {
                heightInputFocused: false,
                weightInputFocused: false,
            }
        }
    },
    components: {
        IntakeButton: IntakeButton,
        HButton,
        Datepicker,
        HCertification,
        HSidebar,
    },
    directives: {ClickOutside},
    validations: {
        user: {
            sex: {required},
            birthday: {required, minLength: minLength(10), validateDate, isValidAge},
        },
        body: {
            weight: {required, numeric, min: minValue(-1), overweight: maxValue(1500), underweight: moreThanZero},
            height_feet: {required, numeric, integer, max: maxValue(10), notZero: moreThanZero},
            height_inches: {required, numeric, integer, max: maxValue(12)}
        },
        userState: {
            required
        }
    },
    methods: {
        closeModal() {
            if (this.alert_modal) {
                this.alertModal(false);
            }
        },
        alertModal(is_set) {
            this.alert_modal = is_set;
        },
        async updateProfile() {
            const inches = convertToInches(this.body.height_feet, this.body.height_inches);

            const payload = {
                _id: this.user._id,
                sex: this.user.sex,
                birthday: this.user.birthday,
                state: this.userState.abbreviation,
                weight: parseInt(this.body.weight),
                height: inches
            }

            if (this.intake_id) {
                payload['intake_id'] = this.intake_id
            }

            analytics.track(analyticsActions.questionnaire_profile_success, payload);
            await this.updateUser(payload);
        },
        changeBirthday(e) {
            this.user.birthday = moment(e).format('MM-DD-YYYY');
        },
        async onContinue() {
            await this.updateProfile();
            this.$emit('onContinue', this.user);
            this.button_submitting = false;
        },
        async onProtocols() {
            await this.updateProfile();
            await this.$router.push(`/shop`);
        },
        async onDashboard() {
            await this.updateProfile();
            await this.$router.push(`/dashboard/${this.user._id}/messages`);
        },
        unavailableLabStates() {
            const last = unavailableLabStates[unavailableLabStates.length - 1];

            return unavailableLabStates.slice(0, (unavailableLabStates.length - 1)).map(state => state.name).join(', ') + ' and ' + last.name;
        },
        async checkForm(e) {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.button_text = 'Submitting';
            this.button_submitting = true;
            let analytics_payload = {};
            const signup_config = JSON.parse(window.localStorage.getItem('signup-config'));

            try {
                if (signup_config && signup_config.intake.hasIntake) {
                    const intake_template = JSON.parse(window.localStorage.getItem('intake_template'));
                    const payload = {
                        state_id: this.userState && this.userState._id,
                        intake_template_id: intake_template._id
                    }
                    let states = await this.checkAvailability(payload);
                    if (!states.approved) {
                        this.alert_modal = true;
                        this.button_text = 'Continue';
                        this.button_submitting = false;

                        return false;
                    }
                    analytics_payload['intake_id'] = this.intake_id;
                } else if (this.productTemplateStorage?.type === 'lab') {
                    const unavailableState = await this.checkLabAvailability(this.userState);

                    if (unavailableState) {
                        this.alert_modal = true;
                        this.button_text = 'Continue';
                        this.button_submitting = false;
                        return false;
                    }
                }

                analytics.track(analyticsActions.questionnaire_profile_submitted, analytics_payload);

                await this.onContinue();
            } catch (e) {
                console.error(e);
                this.button_submitting = false;
                this.button_text = 'Error. Try Again';
            }
        },
        ...mapMutations({
            setStep: 'questionnaire/setQuestionnaire',
            setNavTitle: 'navigation/setNavTitle',
        }),
        ...mapActions({
            retrieveStates: 'states/retrieveStates',
            updateUser: 'user/updateUser',
            updateResponse: 'intake/updateIntakeResponse',
            checkAvailability: 'states/checkStateAvailability',
            checkLabAvailability: 'states/checkLabStateAvailability',
        })
    },
    computed: {
        ...mapState({
            intake_id: state => state.intake.intake_id,
            states: state => state.states.states,
            user: state => state.user.user,
            signupIntakeTemplateId: state => state.intake.signupIntakeTemplateId,
            selectedIntakeTemplate: state => state.intake.selectedIntakeTemplate,
            currentIntakeInstance: state => state.intake.currentIntakeInstance,
            selectedProduct: state => state.productTemplates.selectedProduct,
        }),
        step() {
            return this.$route.query.step;
        },
        productTemplateStorage() {
            return this.selectedProduct || JSON.parse(window.localStorage.getItem('zp_product_template'));
        },
    },
    watch: {
        'states': {
            async handler(states) {
                if (!this.userState && this.user.state) {
                    this.userState = states.find(state => state.abbreviation === this.user.state) || null;
                }
            },
            immediate: true,
        }
    },
    created: function () {
        this.body.weight = this.user.weight;
        this.body.height_inches = getHeight(this.user.height).in;
        this.body.height_feet = getHeight(this.user.height).ft;

        analytics.track(analyticsActions.questionnaire_profile_opened, {
            intake_id: this.intake_id,
        });

        this.setNavTitle('Profile');

        this.retrieveStates().then(() => {
        }).catch((err) => {
            console.error('err retrieving states', err);
        })
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_type.scss';
@import "@/assets/scss/_colors.scss";

.profile-container {
    max-width: 500px;
}

.profile__body {
    display: flex;
    flex-direction: row;
    column-gap: 20px;

    @media screen and (max-width: 590px) {
        flex-wrap: wrap;
        row-gap: 30px;

        .profile__input-container,
        .profile__input-group,
        .profile__input-wrapper {
            width: 100%;
        }

        &-column {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 1.25rem;
        }
    }

    label:not(.profile__section-subheader) {
        margin-bottom: 0;
    }
}

.profile-modal {
    &__title {
        font-size: 3rem !important;
        line-height: 3.5rem !important;
        font-weight: 400 !important;
        margin: 0 !important;
        margin-bottom: 1.5rem !important;

            @media screen and (max-width: 680px) {
                font-size: 1.75rem;
            }
    }
    &__body {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;

        p {
            margin: 0;
            @extend %body;
        }
    }
    &__divider {
        width: 100%;
        height: 1px;
        background-color: $border-default;
        margin: 3rem 0;
    }
    &__buttons {
        display: flex;
        gap: 16px;

        @media screen and (max-width: 590px) {
            flex-wrap: wrap;
        }
    }
}

.profile__error-message {
    font-size: 14px;
    color: $errors;
    font-weight: 400;
}

.profile__body-select {
    outline: none;
    border: none;
    box-shadow: none;
    font-size: 1.125rem;
    flex: 1;
    text-align: right;
    border-bottom: 1px solid $charcoal;
    width: 60px;
    background-color: $white-100;
    color: $black-85;
}

.profile__input {
    background-color: transparent;
    border: none;
    text-align: right;
    max-width: 60px;
    border-bottom: 1px solid $black-100;
    padding: 0;
    font-size: 1.125rem;

    @media screen and (max-width: 590px) {
        max-width: none;
        width: 100%;
        font-size: 1rem;
        text-align: left;
    }

    &:focus,
    &:active {
        .profile__input-wrapper {
            border-color: $charcoal;
        }
    }

    &:focus {
        outline: none;
    }

    &-icon {
        margin-right: 15px;
    }
    &-unit {
        color: $gray-400;
        font-size: 1.125rem;
        font-weight: 400;
        margin-bottom: -5px;
    }
    &-wrapper {
        display: flex;
        flex-grow: 1;
        align-items: center;
        background-color: $white-100;
        border: 1px solid $border-input;
        border-radius: 12px;
        padding: 13px 20px;
        transition: border-color 0.3s;

        &--invalid {
            border-color: $errors;

            input {
                border-color: $errors;
            }
        }
        &--focused {
            border-color: $charcoal;

            input {
                border-color: $charcoal;
            }
        }
        label {
            margin-bottom: 0;
        }
    }
    &-group {
        display: flex;
        align-items: center;
        column-gap: 25px;
    }
    &-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: flex-end;
    }

    &-date {
        display: flex;
        flex-grow: 1;
        align-items: center;
        background-color: $white-100;
        border: 1px solid $border-input;
        border-radius: 12px;
        padding: 13px 20px;
        padding-left: 58px;
        transition: border-color 0.3s;

        &:focus,
        &:active {
            border-color: $charcoal;
        }

        &--invalid {
            border-color: $errors;

            input {
                border-color: $errors;
            }
        }
        label {
            margin-bottom: 0;
        }

        &__container {
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        &__icon {
            position: absolute;
            left: 20px;
        }

        &:focus,
        &:active {
            outline: none;
        }

        &::placeholder {
            color: $gray-400;
            font-size: 1.125rem;
            font-weight: 400;
        }
    }

    &-select {
        width: 100%;
        appearance: none;
        padding: 15px 20px;
        padding-left: 58px;
        outline: none;

        &__placeholder {
            pointer-events: none;
            position: absolute;
            left: 58px;
            color: $gray-400;
        }
        &__container {
            position: relative;
            display: flex;
            align-items: center;
        }
        &__icon {
            position: absolute;
            left: 24px;

            &--right {
                left: auto;
                right: 24px;
            }
        }

        &:focus-visible {
            border-color: $charcoal;
        }
    }
}

.sup-headline {
    font-family: 'Sofia Medium';
    font-size: 12px;
    color: #93ba79;
}

.profile__subheader {
    margin-top: 1rem;
    margin-bottom: 0;
}

.profile__form {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}

.profile__section {
    &-subheader {
        @extend %headingSm;
        font-weight: 400;
        margin-bottom: 1.25rem;
    }
}

.profile__sex-container {
    display: flex;
    flex-direction: row;
    column-gap: 14px;
    width: 100%;
}

.profile__sex-button__container {
    display: flex;
    width: 100%;
    flex-shrink: 1;
}

.profile__sex-button {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $white-100;
    color: #000;
    padding: 17px 0;
    @extend %helveticaNowDisplay;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 24px;
    letter-spacing: 0.02rem;
    border-radius: 12px;
    max-width: 260px;
    margin-bottom: 0;
    border: 1px solid $border-medium;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;

    &--active  {
        border-color: $health-orange;
        background-color: $health-orange;
        color: $white-100;
    }
}

.profile-certification {
    margin-top: 50px;
}

</style>
