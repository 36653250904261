import axios from 'axios';
import Vue from 'vue';

const baseUrl = process.env.VUE_APP_BASE_END;

const vm = this;

export const labResults = {
    namespaced: true,
    state: {
        lab_results: {},
        lab_analysis: {},
        lab_pdf: {},
        requisition_pdf: {},
    },
    mutations: {
        setLabsResults(state, payload) {
            Vue.set(state.lab_results, payload.id, payload.value);
        },
        setLabAnalysis(state, payload) {
            Vue.set(state.lab_analysis, payload.id, payload.value);
        },
        setLabPDF(state, payload) {
            Vue.set(state.lab_pdf, payload.id, payload.value);
        },
        setRequisitionPDF(state, payload) {
            Vue.set(state.requisition_pdf, payload.id, payload.value);
        },
    },
    actions: {
        async getLabsResults({ commit }, payload) {
            commit('setLabsResults', { id: 'loading', value: true });
            commit('setLabsResults', { id: 'error', value: false });
            const { queries } = payload;

            try {
                const response = await axios.get(`${baseUrl}/v2/labs/results`, {
                    params: queries,
                });

                if ('data' in response) {
                    commit('setLabsResults', { id: 'data', value: response.data });

                    return response.data;
                }
            } catch (error) {
                commit('setLabsResults', { id: 'error', value: true });
                commit('setLabsResults', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setLabsResults', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            } finally {
                commit('setLabsResults', { id: 'loading', value: false });
            }
        },
        async getLabAnalysis({ commit }, payload) {
            const { reportId, rangeType, categoryName } = payload;

            commit('setLabAnalysis', { id: 'loading', value: true });
            commit('setLabAnalysis', { id: 'error', value: false });

            let url = `${baseUrl}/v2/labs/results/${reportId}?rangeType=${rangeType}`;

            if (categoryName) {
                url += `&categoryName=${categoryName}`;
            }

            try {
                const response = await axios.get(url);

                if ('data' in response) {
                    commit('setLabAnalysis', { id: 'data', value: response.data });

                    return response.data;
                }
            } catch (error) {
                commit('setLabAnalysis', { id: 'error', value: true });
                commit('setLabAnalysis', { id: 'data', value: null });

                if (axios.isAxiosError(error)) {
                    commit('setLabAnalysis', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }

                throw error;
            } finally {
                commit('setLabAnalysis', { id: 'loading', value: false });
            }
        },
        async getLabPDF({ commit }, payload) {
            const { reportId } = payload;

            commit('setLabPDF', { id: 'loading', value: true });
            commit('setLabPDF', { id: 'error', value: false });

            try {
                const response = await axios.get(
                    `${baseUrl}/v2/labs/results/${reportId}/download`,
                    { responseType: 'blob' },
                );

                if ('data' in response) {
                    commit('setLabPDF', { id: 'data', value: response });

                    return response;
                }
            } catch (error) {
                commit('setLabPDF', { id: 'data', value: null });
                commit('setLabPDF', { id: 'error', value: true });

                if (axios.isAxiosError(error)) {
                    commit('setLabPDF', { id: 'error', value: error.response.data });
                    throw error.response.data;
                }
                throw error;
            } finally {
                commit('setLabPDF', { id: 'loading', value: false });
            }
        },
        async getRequisitionPDF({ commit }, payload) {
            const { reportId } = payload;

            commit('setRequisitionPDF', { id: 'loading', value: true });
            commit('setRequisitionPDF', { id: 'error', value: false });

            try {
                const response = await axios.get(
                    `${baseUrl}/v2/labs/results/${reportId}/form/download`,
                    { responseType: 'blob' },
                );

                if ('data' in response) {
                    commit('setRequisitionPDF', { id: 'data', value: response });

                    return response;
                }
            } catch (error) {
                commit('setRequisitionPDF', { id: 'data', value: null });
                commit('setRequisitionPDF', { id: 'error', value: true });

                if (axios.isAxiosError(error)) {
                    commit('setRequisitionPDF', {
                        id: 'error',
                        value: error.response.data,
                    });
                    throw error.response.data;
                }
                throw error;
            } finally {
                commit('setRequisitionPDF', { id: 'loading', value: false });
            }
        },
    },
};
